import axios from "axios";
import {all, call, delay, put, race, select, take, takeEvery, takeLatest} from "redux-saga/effects";
import i18n from "i18next";
import {saveAs} from "file-saver";
import {
	eidMiddlewareAuthenticate,
	eidMiddlewareLoop,
	eidMiddlewareReadAuthenticationCertificate,
	eidMiddlewareReadCitizenCertificate,
	eidMiddlewareReadNonRepudiationCertificate,
	eidMiddlewareReadRawIdentity,
	eidMiddlewareReadVerifiedIdentity,
	eidMiddlewareSign
} from "./middleware";
import {push} from "connected-react-router";

export default function* rootSaga() {
	yield all([
		// application
		takeEvery('APPLICATION_OIDC_INSTANCE_FETCH_LIST', applicationOidcInstanceFetchList),
		takeEvery('APPLICATION_OIDC_INSTANCE_COMPANY_FETCH_LIST', applicationOidcInstanceCompanyFetchOverviewList),
		takeEvery('APPLICATION_OIDC_INSTANCE_FETCH_SETTINGS', applicationOidcInstanceFetchSettings),
		takeEvery('APPLICATION_OIDC_INSTANCE_CREATE', applicationOidcInstanceCreate),
		takeEvery('APPLICATION_OIDC_INSTANCE_UPDATE_SETTINGS', applicationOidcInstanceSettingsUpdate),
		takeEvery('APPLICATION_OIDC_INSTANCE_UPDATE_LOGO', applicationOidcInstanceLogoUpdate),
		takeEvery('APPLICATION_OIDC_INSTANCE_DELETE', applicationOidcInstanceDelete),
		takeEvery('APPLICATION_FETCH_INFO', applicationFetchInfo),
		takeEvery('APPLICATION_FETCH_LOG_SETTINGS', applicationFetchLogSettings),
		takeEvery('APPLICATION_UPDATE_LOG_SETTINGS', applicationUpdateLogSettings),

		// approval
		takeEvery('APPROVAL_FETCH_DATA', approvalFetchData),
		takeEvery('APPROVAL_APPROVE_OR_DECLINE_START', approvalApproveOrDeclineStart),

		// company
		takeEvery('COMPANY_FETCH_GENERAL_SETTINGS', companyFetchGeneralSettings),
		takeEvery('COMPANY_UPDATE_GENERAL_SETTINGS', companyUpdateGeneralSettings),
		takeEvery('COMPANY_FETCH_EMAIL_SETTINGS', companyFetchEmailSettings),
		takeEvery('COMPANY_UPDATE_EMAIL_SETTINGS', companyUpdateEmailSettings),
		takeEvery('COMPANY_EMAIL_AUTHENTICATED_DOMAINS_FETCH', companyEmailAuthenticatedDomainsFetch),
		takeEvery('COMPANY_EMAIL_AUTHENTICATED_DOMAINS_VALIDATE', companyEmailAuthenticatedDomainsValidate),
		takeEvery('COMPANY_EMAIL_SERVER_CONNECTION_VALIDATE', companyEmailServerConnectionValidate),
		takeEvery('COMPANY_FETCH_NOTIFICATION_SETTINGS', companyFetchNotificationSettings),
		takeEvery('COMPANY_UPDATE_NOTIFICATION_SETTINGS', companyUpdateNotificationSettings),
		takeEvery('COMPANY_FETCH_STYLE_SETTINGS', companyFetchStyleSettings),
		takeEvery('COMPANY_UPDATE_STYLE_SETTINGS', companyUpdateStyleSettings),
		takeEvery('COMPANY_FETCH_RELEVANT_USERS', companyFetchRelevantUsers),
		takeEvery('COMPANY_FETCH_DELEGATE_LIST', companyFetchDelegateList),
		takeEvery('COMPANY_FETCH_DELEGATE_LISTS', companyFetchDelegateLists),
		takeEvery('COMPANY_CREATE_UPDATE_DELEGATE_LIST', companyCreateUpdateDelegateList),
		takeEvery('COMPANY_DELETE_DELEGATE_LIST', companyDeleteDelegateList),
		takeEvery('COMPANY_DOWNLOAD_DELEGATE_FILE', companyDelegateDownloadFile),
		takeEvery('COMPANY_DELETE_DELEGATE_FILE', companyDelegateDeleteFile),
		takeEvery('COMPANY_TESTS_SEND_EMAILS', companyTestsSendEmails),
		takeEvery('COMPANY_TEST_SEND_EMAIL', companyTestSendEmail),
		takeEvery('COMPANY_PREVIEW_EMAIL', companyPreviewEmail),
		takeEvery('COMPANY_FETCH_SWITCH_LIST', companyFetchSwitchList),
		takeEvery('COMPANY_FETCH_OVERVIEW_LIST', companyFetchOverviewList),
		takeEvery('COMPANY_FETCH_ELIGIBLE_PARENT_LIST', companyFetchEligibleParents),
		takeEvery('COMPANY_FETCH_ADMIN_SETTINGS', companyFetchAdminSettings),
		takeEvery('COMPANY_UPDATE_ADMIN_SETTINGS', companyUpdateAdminSettings),
		takeEvery('COMPANY_CREATE', companyCreate),
		takeEvery('COMPANY_FETCH_ADMIN_API_SETTINGS', companyFetchAdminApiSettings),
		takeEvery('COMPANY_UPDATE_ADMIN_API_SETTINGS', companyUpdateAdminApiSettings),
		takeEvery('COMPANY_DELETE', companyDelete),
		takeEvery('COMPANY_OIDC_INSTANCE_FETCH_LIST', companyFetchOidcInstanceList),
		takeEvery('COMPANY_OIDC_INSTANCE_FETCH_SETTINGS', companyFetchOidcInstanceSettings),
		takeEvery('COMPANY_OIDC_INSTANCE_CREATE', companyCreateOidcInstance),
		takeEvery('COMPANY_OIDC_INSTANCE_UPDATE_SETTINGS', companyUpdateOidcInstanceSettings),
		takeEvery('COMPANY_OIDC_INSTANCE_UPDATE_LOGO', companyUpdateOidcInstanceLogo),
		takeEvery('COMPANY_OIDC_INSTANCE_DELETE', companyDeleteOidcInstance),
		takeEvery('COMPANY_FETCH_STATS_CONFIG', companyFetchStatsConfiguration),
		takeEvery('COMPANY_FETCH_STATS_APPROVALS', companyFetchStatsApprovals),
		takeEvery('COMPANY_FETCH_STATS_SIGNATURES', companyFetchStatsSignatures),
		takeEvery('COMPANY_FETCH_STATS_SIGNATURES_ITSME', companyFetchStatsSignaturesItsme),
		takeEvery('COMPANY_EXPORT_STATS_SIGNATURES', companyExportStatsSignatures),
		takeEvery('COMPANY_EXPORT_STATS_APPROVALS', companyExportStatsApprovals),
		takeEvery('COMPANY_EXPORT_STATS_ITSME_DETAILS', companyExportStatsItsmeDetails),
		takeEvery('COMPANY_FETCH_ACTION_LOGS', companyFetchActionLogs),
		takeEvery('COMPANY_FETCH_AVAILABLE_CONNECTOR_TYPES', companyFetchAvailableConnectorTypes),
		takeEvery('COMPANY_SEARCH_CONNECTOR_USER', companySearchConnectorUser),
		takeEvery('COMPANY_SEARCH_CONNECTOR_FOLDER', companySearchConnectorFolder),
		takeEvery('COMPANY_FETCH_CONNECTOR_INSTANCE_LIST', companyFetchConnectorInstanceList),
		takeEvery('COMPANY_FETCH_CONNECTOR_ACTIVITY_LIST', companyFetchConnectorActivityList),
		takeEvery('COMPANY_CREATE_CONNECTOR_INSTANCE', companyCreateConnectorInstance),
		takeEvery('COMPANY_UPDATE_CONNECTOR_INSTANCE_CONFIG', companyUpdateConnectorInstanceConfig),
		takeEvery('COMPANY_DELETE_CONNECTOR_INSTANCE', companyDeleteConnectorInstance),
		takeEvery('COMPANY_CREATE_CONNECTOR_ACTIVITY', companyCreateConnectorActivity),
		takeEvery('COMPANY_UPDATE_CONNECTOR_ACTIVITY', companyUpdateConnectorActivity),
		takeEvery('COMPANY_DELETE_CONNECTOR_ACTIVITY', companyDeleteConnectorActivity),
		takeEvery('COMPANY_TEST_CONNECTOR_INSTANCE_CONFIG', companyTestConnectorInstanceConfig),
		takeEvery('COMPANY_FETCH_CONNECTOR_INVENTORY_LIST', companyFetchConnectorInventoryList),
		takeEvery('COMPANY_RESTART_CONNECTOR_INVENTORY_ENTRY', companyRestartConnectorInventoryEntry),
		takeEvery('COMPANY_FETCH_SIGNING_CERTIFICATE_SETTINGS', companyFetchSigningCertificateSettings),
		takeEvery('COMPANY_UPDATE_SIGNING_CERTIFICATE_SETTINGS', companyUpdateSigningCertificateSettings),
		takeLatest('COMPANY_APIKEY_FETCH', companyApiKeysFetch),
		takeEvery('COMPANY_APIKEY_CREATE', companyApiKeyCreate),
		takeEvery('COMPANY_APIKEY_UPDATE', companyApiKeyUpdate),
		takeEvery('COMPANY_APIKEY_REVOKE', companyApiKeyRevoke),
		takeEvery('COMPANY_WEBHOOK_CONFIG_FETCH_OVERVIEW', companyWebhookConfigFetchOverview),
		takeEvery('COMPANY_WEBHOOK_CONFIG_CREATE', companyWebhookConfigCreate),
		takeEvery('COMPANY_WEBHOOK_CONFIG_DELETE', companyWebhookConfigDelete),

		// document
		takeEvery('DOCUMENT_FETCH_OVERVIEW_INFO', documentFetchOverviewInfo),
		takeLatest('DOCUMENT_FETCH_GENERAL_OVERVIEW_LIST', documentFetchGeneralOverviewList),
		takeLatest('DOCUMENT_FETCH_APPROVAL_OVERVIEW_LIST', documentFetchApprovalOverviewList),
		takeLatest('DOCUMENT_FETCH_SIGNING_OVERVIEW_LIST', documentFetchSigningOverviewList),
		takeEvery('DOCUMENT_SINGLE_DOWNLOAD_ARTIFACTS', documentSingleDownloadArtifacts),
		takeEvery('DOCUMENT_DOWNLOAD_ARTIFACTS', documentDownloadArtifacts),
		takeEvery('DOCUMENT_DOWNLOAD_ALL_DOCUMENT_ARTIFACTS', documentDownloadAllDocumentArtifacts),
		takeEvery('DOCUMENT_SEND_REMINDERS', documentSendReminders),
		takeEvery('DOCUMENT_DELETE_DOCUMENTS', documentDeleteDocuments),
		takeEvery('DOCUMENT_DELETE_ALL_DOCUMENTS', documentDeleteAllDocuments),
		takeEvery('DOCUMENT_EXPORT_GENERAL_OVERVIEW', documentExportGeneralOverview),
		takeEvery('DOCUMENT_CREATE_FROM_TEMPLATE', documentCreateFromTemplate),
		takeEvery('DOCUMENT_MAKE_COLLECTION', documentMakeCollection),

		// editor
		takeEvery('EDITOR_FETCH_EDIT_INFO', editorFetchEditInfo),
		takeEvery('EDITOR_SAVE_DOCUMENT', editorSaveDocument),
		takeEvery('EDITOR_SIGN_DOCUMENT', editorSignDocument),
		takeEvery('EDITOR_SEND_DOCUMENT', editorSendDocument),
		takeEvery('EDITOR_CLOSE_DOCUMENT', editorCloseDocument),
		takeEvery('EDITOR_DELETE_DOCUMENT', editorDeleteDocument),
		takeEvery('EDITOR_DELETE_TEMPLATE', editorDeleteTemplate),
		takeEvery('EDITOR_RESTART_DOCUMENT', editorRestartDocument),
		takeEvery('EDITOR_FETCH_POSSIBLE_USERS', editorFetchPossibleUsers),
		takeEvery('EDITOR_FETCH_ACTION_LOGS', editorFetchActionLogs),
		takeEvery('EDITOR_SAVE_TEMPLATE', editorSaveTemplate),
		// eid
		takeEvery('EID_START_MIDDLEWARE', eidStartMiddleware),
		// folder
		takeEvery('FOLDER_FETCH_OVERVIEW_LIST', folderFetchOverviewList),
		takeEvery('FOLDER_CREATE', folderCreate),
		takeEvery('FOLDER_DELETE', folderDelete),
		takeEvery('FOLDER_FETCH_COMPANY_SETTINGS', folderFetchCompanySettings),
		takeEvery('FOLDER_FETCH_RELEVANT_GROUPS', folderFetchRelevantGroups),
		takeEvery('FOLDER_FETCH_RELEVANT_USERS', folderFetchRelevantUsers),
		takeEvery('FOLDER_FETCH_SETTINGS', folderFetchSettings),
		takeEvery('FOLDER_UPDATE_SETTINGS', folderUpdateSettings),
		takeEvery('FOLDER_FETCH_PERMISSIONS', folderFetchPermissions),
		takeEvery('FOLDER_UPDATE_PERMISSIONS', folderUpdatePermissions),
		takeEvery('FOLDER_FETCH_DELEGATE_LISTS', folderFetchDelegateLists),
		takeEvery('FOLDER_CREATE_UPDATE_DELEGATE_LIST', folderCreateUpdateDelegateList),
		takeEvery('FOLDER_DELETE_DELEGATE_LIST', folderDeleteDelegateList),
		takeEvery('FOLDER_FETCH_DELEGATE_LIST', folderFetchDelegateList),
		takeEvery('FOLDER_DOWNLOAD_DELEGATE_FILE', folderDownloadDelegateFile),
		takeEvery('FOLDER_DELETE_DELEGATE_FILE', folderDeleteDelegateFile),
		// session
		takeEvery('SESSION_ACCEPT_TERMS_AND_CONDITIONS', sessionAcceptTermsAndConditions),
		takeEvery('SESSION_FETCH_COMPANY_INFO', sessionFetchCompanyInfo),
		takeEvery('SESSION_CHECK', checkSession),
		takeEvery('SESSION_CREATE_OIDC', sessionCreateOidc),
		takeEvery('SESSION_CREATE_HANDLE_LOGIN_CALLBACK', sessionCreateHandleLoginCallback),
		takeEvery('SESSION_LOGIN_PASSWORD', sessionLoginPassword),
		takeEvery('SESSION_CREATE_GUEST', sessionCreateGuest),
		takeEvery('SESSION_REGISTER_GUEST', sessionRegisterGuest),
		takeEvery('SESSION_REFRESH_INFO', sessionRefreshInfo),
		takeEvery('SESSION_LOGIN_EID', sessionLoginEid),
		takeEvery('SESSION_SWITCH_COMPANY', sessionSwitchCompany),
		takeEvery('SESSION_UPDATE_VISUAL_SIGNATURE', sessionUpdateVisualSignature),
		takeEvery('SESSION_UPDATE_VISUAL_PARAPH', sessionUpdateVisualParaph),
		takeEvery('SESSION_FORGET_ITSME_IDENTITY', sessionForgetItsmeIdentity),
		takeEvery('SESSION_FETCH_USER_PROFILE', sessionFetchUserProfile),
		takeEvery('SESSION_UPDATE_USER_PROFILE', sessionUpdateUserProfile),
		takeEvery('SESSION_UPDATE_USER_PASSWORD', sessionUpdateUserPassword),
		takeEvery('SESSION_RESET_PASSWORD_UPDATE', sessionResetPasswordUpdate),
		takeEvery('SESSION_FORGOT_PASSWORD', sessionForgotPassword),
		takeEvery('SESSION_FETCH_API_ACCESS', sessionFetchApiAccess),
		takeEvery('SESSION_UPDATE_API_ACCESS', sessionUpdateApiAccess),
		takeEvery('SESSION_SWITCH_KIOSK_USER', sessionSwitchKioskUser),
		takeEvery('SESSION_LOG_OUT', sessionLogOut),
		// signer-group
		takeLatest('SIGNERGROUP_FETCH_OVERVIEW_LIST', signerGroupFetchOverviewList),
		takeEvery('SIGNERGROUP_CREATE', signerGroupCreate),
		takeEvery('SIGNERGROUP_DELETE', signerGroupDelete),
		takeEvery('SIGNERGROUP_FETCH_SETTINGS', signerGroupFetchSettings),
		takeEvery('SIGNERGROUP_UPDATE_SETTINGS', signerGroupUpdateSettings),
		takeLatest('SIGNERGROUP_FETCH_USER_LIST', signerGroupFetchUserList),
		takeEvery('SIGNERGROUP_ADD_USERS', signerGroupAddUsers),
		takeEvery('SIGNERGROUP_REMOVE_USER', signerGroupRemoveUser),
		takeLatest('SIGNERGROUP_FETCH_POSSIBLE_USER_LIST', signerGroupFetchPossibleUserList),
		takeEvery('SIGNERGROUP_FETCH_APPROVERS_SIGNERS', signerGroupFetchApproversSigners),
		// signing
		takeLatest('SIGNING_FETCH_DATA', signingFetchData),
		takeEvery('SIGNING_RENEW_GUEST_ACCESS', signingRenewGuestAccess),
		takeEvery('SIGNING_ACQUIRE_LOCKS', signingAcquireLocks),
		takeEvery('SIGNING_EXTEND_LOCKS_START', signingExtendLocksStart),
		takeEvery('SIGNING_SIGN_START', signingSignStart),
		takeEvery('SIGNING_OTP_AUTHENTICATE', signingOtpAuthenticate),
		takeEvery('SIGNING_ITSME_STATUS_LOOP', signingItsmeStatusLoop),
		takeEvery('SIGNING_FORWARD', signingForward),
		// template
		takeEvery('TEMPLATE_FETCH_OVERVIEW_LIST', templateFetchOverviewList),
		takeEvery('TEMPLATE_FETCH', templateFetch),
		takeEvery('TEMPLATE_UPDATE', templateUpdate),
		takeEvery('TEMPLATE_DELETE', templateDelete),
		takeEvery('TEMPLATE_FETCH_CREATION_INFO', templateFetchCreationInfo),
		// user
		takeLatest('USER_FETCH_OVERVIEW_LIST', userFetchOverviewList),
		takeEvery('USER_EXPORT_OVERVIEW', userExportOverview),
		takeEvery('USER_FETCH_SETTINGS', userFetchSettings),
		takeEvery('USER_UPDATE_SETTINGS', userUpdateSettings),
		takeEvery('USER_UPDATE_VISUAL_SIGNATURE', userUpdateVisualSignature),
		takeEvery('USER_CREATE', userCreate),
		takeEvery('USER_DELETE', userDelete),
		takeEvery('USER_REGISTER_GUEST', userRegisterGuest),
		takeEvery('USER_RESEND_INVITATION', userResendInvitation),
		// user-group
		takeLatest('USERGROUP_FETCH_OVERVIEW_LIST', userGroupFetchOverviewList),
		takeEvery('USERGROUP_CREATE', userGroupCreate),
		takeEvery('USERGROUP_DELETE', userGroupDelete),
		takeEvery('USERGROUP_FETCH_SETTINGS', userGroupFetchSettings),
		takeEvery('USERGROUP_UPDATE_SETTINGS', userGroupUpdateSettings),
		takeLatest('USERGROUP_FETCH_USER_LIST', userGroupFetchUserList),
		takeEvery('USERGROUP_ADD_USERS', userGroupAddUsers),
		takeEvery('USERGROUP_USER_UPDATE_SETTINGS', userGroupUserUpdateSettings),
		takeEvery('USERGROUP_REMOVE_USER', userGroupRemoveUser),
		takeLatest('USERGROUP_FETCH_POSSIBLE_USER_LIST', userGroupFetchPossibleUserList),
	]);
}
/*** CONSTANTS ********************************************************************************************************/

const MAX_FILE_SIZE = 1048576; // 1MB; base64 converted files larger > 7Mb produce network issues

/*** MISC/HELPERS *****************************************************************************************************/

function* axiosRequest(url, method, data, responseType, progress) {
	try {
		const config = {
			url: url,
			method: method,
			headers: {
				'X-Requested-With': 'XMLHttpRequest'
			}
		};
		if (!!data) {
			config.data = data;
		}
		if (!!responseType) {
			config.responseType = responseType;
		}
		if (!!progress) {
			config.onUploadProgress = progress;
		}

		const csrfToken = localStorage.getItem('TOKEN_CSRF');
		if (!!csrfToken) {
			config.headers['X-Csrf-Token'] = csrfToken;
		}

		return yield axios(config);
	} catch (e) {
		if (401 === e.response.status) {
			yield put({
				type: 'SESSION_DESTROY'
			});
		} else {
			throw e;
		}
	}
}

function* axiosPost(url, data, progress, responseType) {
	return yield axiosRequest(url, 'post', data, responseType, progress);
}

function* axiosPut(url, data, progress) {
	return yield axiosRequest(url, 'put', data, null, progress);
}

function* axiosGet(url, responseType) {
	return yield axiosRequest(url, 'get', null, responseType);
}

function* axiosDelete(url, data) {
	return yield axiosRequest(url, 'delete', data);
}

function* extractServerError(e) {
	if (!!e.response && !!e.response.status && 412 === e.response.status && !!e.response.data) {
		if (e.response.data instanceof Blob) {
			const data = JSON.parse(yield e.response.data.text());
			if (!!data.error) {
				return data.error;
			}
		} else if (!!e.response.data.error) {
			return e.response.data.error;
		}
	}

	return 'GENERAL';
}

/*** APPLICATION ******************************************************************************************************/

function* applicationOidcInstanceFetchList(action) {
	try {
		const {data} = yield axiosGet('/api/internal/application/oidc-instance-list');
		yield put({
			type: 'APPLICATION_OIDC_INSTANCE_FETCH_LIST_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'OIDC_INSTANCE_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* applicationOidcInstanceCompanyFetchOverviewList(action) {
	try {
		const {
			data: {
				list,
				count
			}
		} = yield axiosPost('/api/internal/application/oidc-instance/company-overview', action.request);
		yield put({
			type: 'APPLICATION_OIDC_FETCH_COMPANY_OVERVIEW_LIST_SUCCESS',
			list,
			count,
		});
	} catch (e) {
		yield put({
			type: 'OIDC_INSTANCE_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* applicationOidcInstanceCreate(action) {
	try {
		const {data: {id}} = yield axiosPost('/api/internal/application/oidc-instance', action.settings);
		yield put({
			type: 'APPLICATION_OIDC_INSTANCE_CREATE_SUCCESS',
			createdOidcInstanceId: id
		});

		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		});
	} catch (e) {
		yield put({
			type: 'OIDC_INSTANCE_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* applicationOidcInstanceFetchSettings(action) {
	try {
		const {data} = yield axiosGet(`/api/internal/application/oidc-instance/${action.oidcInstanceId}/settings`);
		yield put({
			type: 'APPLICATION_OIDC_INSTANCE_FETCH_SETTINGS_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'OIDC_INSTANCE_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* applicationOidcInstanceSettingsUpdate(action) {
	try {
		yield axiosPut(`/api/internal/application/oidc-instance/${action.oidcInstanceId}/settings`, action.settings);
		yield put({
			type: 'APPLICATION_OIDC_INSTANCE_UPDATE_SETTINGS_SUCCESS',
		});
		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		})
	} catch (e) {
		yield put({
			type: 'OIDC_INSTANCE_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* applicationOidcInstanceLogoUpdate(action) {
	try {
		if (!!action.fileSize && action.fileSize > MAX_FILE_SIZE) {
			yield put({
				type: 'OIDC_INSTANCE_ERROR',
				logoUpdateError: 'IMAGE_INVALID_SIZE'
			});
			return;
		}

		yield axiosPost(`/api/internal/application/oidc-instance/${action.oidcInstanceId}/logo`, {
			logoBase64: action.logoBase64,
			mimeType: action.mimeType
		});
		yield put({
			type: 'APPLICATION_OIDC_INSTANCE_UPDATE_LOGO_SUCCESS',
			logoBase64: action.logoBase64,
		});

		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		});
	} catch (e) {
		yield put({
			type: 'OIDC_INSTANCE_ERROR',
			logoUpdateError: yield extractServerError(e)
		});
	}
}

function* applicationOidcInstanceDelete(action) {
	try {
		yield axiosDelete('/api/internal/application/oidc-instance/' + action.oidcInstanceId);
		yield put({
			type: 'APPLICATION_OIDC_INSTANCE_DELETE_SUCCESS'
		});
		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'oidcInstance.deleted'
		})
	} catch (e) {
		yield put({
			type: 'OIDC_INSTANCE_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* applicationFetchInfo(action) {
	try {
		const {data} = yield axiosGet('/api/internal/application/info');
		yield put({
			type: 'APPLICATION_FETCH_INFO_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'APPLICATION_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* applicationFetchLogSettings(action) {
	try {
		const {data} = yield axiosGet('/api/internal/application/log-settings');
		yield put({
			type: 'APPLICATION_FETCH_LOG_SETTINGS_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'APPLICATION_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* applicationUpdateLogSettings(action) {
	try {
		const {data} = yield axiosPut('/api/internal/application/log-settings', action.settings);
		yield put({
			type: 'APPLICATION_UPDATE_LOG_SETTINGS_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'APPLICATION_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}


/*** APPROVAL *********************************************************************************************************/

function* approvalFetchData(action) {
	try {
		const {data} = yield axiosGet('/api/internal/approval/data' + (!!action.ids ? ('?ids=' + action.ids) : ''));
		yield put({
			type: 'APPROVAL_FETCH_DATA_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'APPROVAL_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* approvalApproveOrDeclineStart(action) {
	try {
		for (let index = 0; index < action.ids.length; index++) {
			const documentId = action.ids[index];

			let response;
			if (action.declining) {
				response = yield axiosPost('/api/internal/approval/decline', {
					documentId,
					reason: action.declineReason
				});
			} else {
				response = yield axiosPost('/api/internal/approval/approve', {
					documentId,
				});
			}

			yield put({
				type: 'APPROVAL_APPROVE_OR_DECLINE_UPDATE',
				document: response.data
			});
		}
		yield put({
			type: 'APPROVAL_APPROVE_OR_DECLINE_SUCCESS',
		});
	} catch (e) {
		yield put({
			type: 'APPROVAL_APPROVE_OR_DECLINE_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

/*** COMPANY **********************************************************************************************************/

function* companyFetchGeneralSettings(action) {
	try {
		const {data} = yield axiosGet('/api/internal/company/current/settings/general');
		yield put({
			type: 'COMPANY_FETCH_GENERAL_SETTINGS_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyUpdateGeneralSettings(action) {
	try {
		yield axiosPut('/api/internal/company/current/settings/general', action.settings);
		yield companyFetchGeneralSettings();
		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		})
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyFetchEmailSettings(action) {
	try {
		const {data} = yield axiosGet('/api/internal/company/current/settings/email');
		yield put({
			type: 'COMPANY_FETCH_EMAIL_SETTINGS_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyUpdateEmailSettings(action) {
	try {
		yield axiosPut('/api/internal/company/current/settings/email', action.settings);
		yield companyFetchEmailSettings();

		// immediately update the current session info
		yield put({
			type: 'SESSION_UPDATE_COMPANY_SUPPORT',
			companySupport: action.settings.companySupportSettings
		});

		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		})
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyEmailAuthenticatedDomainsFetch() {
	try {
		const {data} = yield axiosGet(`/api/internal/company/current/settings/email-authenticated-domains`);

		yield put({
			type: 'COMPANY_EMAIL_AUTHENTICATED_DOMAINS_FETCH_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyEmailAuthenticatedDomainsValidate() {
	try {
		const {data} = yield axiosPost(`/api/internal/company/current/settings/email-authenticated-domains-validate`);

		yield put({
			type: 'COMPANY_EMAIL_AUTHENTICATED_DOMAINS_VALIDATE_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyEmailServerConnectionValidate(action) {
	try {
		const {data} = yield axiosPost(`/api/internal/company/current/settings/email-server-connection-validate`, {
			apiKey: action.apiKey
		});

		yield put({
			type: 'COMPANY_EMAIL_SERVER_CONNECTION_VALIDATE_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyFetchNotificationSettings(action) {
	try {
		const {data} = yield axiosGet('/api/internal/company/current/settings/notification');
		yield put({
			type: 'COMPANY_FETCH_NOTIFICATION_SETTINGS_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyUpdateNotificationSettings(action) {
	try {
		yield axiosPut('/api/internal/company/current/settings/notification', action.settings);
		yield companyFetchNotificationSettings();
		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		})
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyFetchStyleSettings(action) {
	try {
		const {data} = yield axiosGet('/api/internal/company/current/settings/style');
		yield put({
			type: 'COMPANY_FETCH_STYLE_SETTINGS_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyUpdateStyleSettings(action) {
	try {
		yield axiosPut('/api/internal/company/current/settings/style', action.settings);
		yield companyFetchStyleSettings();

		yield put({
			type: 'SESSION_UPDATE_COMPANY_STYLE',
			companyStyle: action.settings
		});

		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		})
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyFetchRelevantUsers(action) {
	try {
		const {data} = yield axiosGet('/api/internal/company/current/relevant-users');
		yield put({
			type: 'COMPANY_FETCH_RELEVANT_USERS_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyFetchDelegateLists(action) {
	try {
		const {data} = yield axiosGet('/api/internal/company/current/delegate-list/all');
		yield put({
			type: 'COMPANY_FETCH_DELEGATE_LISTS_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyFetchDelegateList(action) {
	try {
		const {data} = yield axiosGet(`/api/internal/company/current/delegate-list/${action.id}`);
		yield put({
			type: 'COMPANY_FETCH_DELEGATE_LIST_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyCreateUpdateDelegateList(action) {
	try {
		const {data} = yield axiosPost('/api/internal/company/current/delegate-list/', action.delegateList);
		yield put({
			type: 'COMPANY_CREATE_UPDATE_DELEGATE_LIST_SUCCESS',
			data
		});
		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyDeleteDelegateList(action) {
	try {
		yield axiosDelete(`/api/internal/company/current/delegate-list/${action.id}`);
		yield put({
			type: 'COMPANY_DELETE_DELEGATE_LIST_SUCCESS',
		});
		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'delegates.deleted'
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyDelegateDownloadFile(action) {
	try {
		const result = yield axiosGet(`/api/internal/company/current/delegate-list/${action.delegateListId}/delegate/${action.delegateId}/download`, 'blob');
		const fileName = result.headers['content-disposition']?.replace('attachment; filename=', '') || `download.pdf`;

		saveAs(result.data, fileName);

		yield put({
			type: 'COMPANY_DOWNLOAD_DELEGATE_FILE_SUCCESS'
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyDelegateDeleteFile(action) {
	try {
		yield axiosDelete(`/api/internal/company/current/delegate-list/${action.delegateListId}/delegate/${action.delegateId}`);

		yield put({
			type: 'COMPANY_DELETE_DELEGATE_FILE_SUCCESS'
		});

		yield companyFetchDelegateList({id: action.delegateListId});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyTestsSendEmails(action) {
	try {
		yield axiosPost('/api/internal/company/current/test-emails', action.request);
		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'mail.success'
		});
		yield put({
			type: 'COMPANY_SUCCESS',
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyTestSendEmail(action) {
	try {
		yield axiosPost('/api/internal/company/current/test-email', action.request);
		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'mail.success'
		});
		yield put({
			type: 'COMPANY_SUCCESS',
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyPreviewEmail(action) {
	try {
		const {data} = yield axiosPost('/api/internal/company/current/preview-email', action.request);
		yield put({
			type: 'COMPANY_PREVIEW_EMAIL_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyFetchSwitchList(action) {
	try {
		const {data} = yield axiosGet('/api/internal/company/list-companies-for-switching');
		yield put({
			type: 'COMPANY_FETCH_SWITCH_LIST_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyFetchOverviewList(action) {
	try {
		const {data: {list, count}} = yield axiosPost('/api/internal/company/overview', action.request);
		yield put({
			type: 'COMPANY_FETCH_OVERVIEW_LIST_SUCCESS',
			list,
			count,
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyFetchEligibleParents() {
	try {
		const {data} = yield axiosGet('/api/internal/company/eligible-parents');
		yield put({
			type: 'COMPANY_FETCH_ELIGIBLE_PARENT_LIST_SUCCESS',
			data,
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyCreate(action) {
	try {
		const {data: {id, name}} = yield axiosPost('/api/internal/company/', action.request);
		yield put({
			type: 'COMPANY_CREATE_SUCCESS',
		});

		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyFetchAdminSettings(action) {
	try {
		const {data} = yield axiosGet(`/api/internal/company/${action.companyId}/settings/admin`);
		yield put({
			type: 'COMPANY_FETCH_ADMIN_SETTINGS_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyUpdateAdminSettings(action) {
	try {
		yield axiosPut(`/api/internal/company/${action.companyId}/settings/admin`, action.settings);
		yield put({
			type: 'COMPANY_UPDATE_ADMIN_SETTINGS_SUCCESS',
		});
		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyFetchAdminApiSettings(action) {
	try {
		const {data} = yield axiosGet(`/api/internal/company/${action.companyId}/settings/admin/api`);
		yield put({
			type: 'COMPANY_FETCH_ADMIN_API_SETTINGS_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyUpdateAdminApiSettings(action) {
	try {
		const {data} = yield axiosPut(`/api/internal/company/${action.companyId}/settings/admin/api`, {enabled: action.enabled});
		yield put({
			type: 'COMPANY_UPDATE_ADMIN_API_SETTINGS_SUCCESS',
			data
		});
		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyDelete(action) {
	try {
		yield axiosDelete(`/api/internal/company/${action.companyId}`);
		yield put({
			type: 'COMPANY_DELETE_SUCCESS',
		});
		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'company.deleted'
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyFetchOidcInstanceList(action) {
	try {
		const {data} = yield axiosGet('/api/internal/company/current/settings/oidc-instance-list');
		yield put({
			type: 'COMPANY_OIDC_INSTANCE_FETCH_LIST_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'OIDC_INSTANCE_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyCreateOidcInstance(action) {
	try {
		const {data: {id}} = yield axiosPost('/api/internal/company/current/settings/oidc-instance', action.settings);
		yield put({
			type: 'COMPANY_OIDC_INSTANCE_CREATE_SUCCESS',
			createdOidcInstanceId: id
		});

		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		});
	} catch (e) {
		yield put({
			type: 'OIDC_INSTANCE_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyFetchOidcInstanceSettings(action) {
	try {
		const {data} = yield axiosGet(`/api/internal/company/current/settings/oidc-instance/${action.oidcInstanceId}/settings`);
		yield put({
			type: 'COMPANY_OIDC_INSTANCE_FETCH_SETTINGS_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'OIDC_INSTANCE_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyUpdateOidcInstanceSettings(action) {
	try {
		yield axiosPut(`/api/internal/company/current/settings/oidc-instance/${action.oidcInstanceId}/settings`, action.settings);
		yield put({
			type: 'COMPANY_OIDC_INSTANCE_UPDATE_SETTINGS_SUCCESS',
		});
		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		})
	} catch (e) {
		yield put({
			type: 'OIDC_INSTANCE_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyUpdateOidcInstanceLogo(action) {
	try {
		if (!!action.fileSize && action.fileSize > MAX_FILE_SIZE) {
			yield put({
				type: 'OIDC_INSTANCE_ERROR',
				logoUpdateError: 'IMAGE_INVALID_SIZE'
			});
			return;
		}

		yield axiosPost(`/api/internal/company/current/settings/oidc-instance/${action.oidcInstanceId}/logo`, {
			logoBase64: action.logoBase64,
			mimeType: action.mimeType
		});
		yield put({
			type: 'COMPANY_OIDC_INSTANCE_UPDATE_LOGO_SUCCESS',
			logoBase64: action.logoBase64,
		});

		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		});
	} catch (e) {
		yield put({
			type: 'OIDC_INSTANCE_ERROR',
			logoUpdateError: yield extractServerError(e)
		});
	}
}

function* companyDeleteOidcInstance(action) {
	try {
		yield axiosDelete(`/api/internal/company/current/settings/oidc-instance/${action.oidcInstanceId}`);
		yield put({
			type: 'COMPANY_OIDC_INSTANCE_DELETE_SUCCESS'
		});
		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'oidcInstance.deleted'
		})
	} catch (e) {
		yield put({
			type: 'OIDC_INSTANCE_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyFetchAvailableConnectorTypes(action) {
	try {
		const {data} = yield axiosGet('/api/internal/company/current/settings/connector/types');
		yield put({
			type: 'COMPANY_FETCH_AVAILABLE_CONNECTOR_TYPES_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companySearchConnectorUser(action) {
	try {
		const {data} = yield axiosGet(`/api/internal/company/current/settings/connector/user-search?v=${action.value}`);
		yield put({
			type: 'COMPANY_SEARCH_CONNECTOR_USER_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companySearchConnectorFolder(action) {
	try {
		const {data} = yield axiosGet(`/api/internal/company/current/settings/connector/folder-search?a=${action.action}&v=${action.value}` + (!!action.userId ? `&uid=${action.userId}` : ''));
		yield put({
			type: 'COMPANY_SEARCH_CONNECTOR_FOLDER_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyFetchConnectorInstanceList(action) {
	try {
		const {data} = yield axiosGet('/api/internal/company/current/settings/connector-instance/overview');
		yield put({
			type: 'COMPANY_FETCH_CONNECTOR_INSTANCE_LIST_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyFetchConnectorInventoryList(action) {
	try {
		const {data} = yield axiosPost(`/api/internal/company/current/settings/connector-instance/${action.instanceId}/inventory-overview`, action.request);
		yield put({
			type: 'COMPANY_FETCH_CONNECTOR_INVENTORY_LIST_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyRestartConnectorInventoryEntry(action) {
	try {
		const {data} = yield axiosPost(`/api/internal/company/current/settings/connector-instance/${action.instanceId}/inventory/${action.entryId}/restart`);
		yield put({
			type: 'COMPANY_RESTART_CONNECTOR_INVENTORY_ENTRY_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyFetchConnectorActivityList(action) {
	try {
		const {data} = yield axiosPost(`/api/internal/company/current/settings/connector-instance/${action.instanceId}/activity-overview`, action.request);
		yield put({
			type: 'COMPANY_FETCH_CONNECTOR_ACTIVITY_LIST_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyUpdateConnectorInstanceConfig(action) {
	try {
		yield axiosPut(`/api/internal/company/current/settings/connector-instance/${action.instanceId}/config`, {config: action.config});
		yield put({
			type: 'COMPANY_UPDATE_CONNECTOR_INSTANCE_SUCCESS',
		});

		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		})
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyCreateConnectorActivity(action) {
	try {
		yield axiosPost(`/api/internal/company/current/settings/connector-instance/${action.instanceId}/activity`, action.request);
		yield put({
			type: 'COMPANY_CREATE_CONNECTOR_ACTIVITY_SUCCESS',
		});
		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		})
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyUpdateConnectorActivity(action) {
	try {
		yield axiosPut(`/api/internal/company/current/settings/connector-instance/${action.instanceId}/activity/${action.activityId}`, action.request);
		yield put({
			type: 'COMPANY_UPDATE_CONNECTOR_ACTIVITY_SUCCESS',
		});
		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		})
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyCreateConnectorInstance(action) {
	try {
		yield axiosPost(`/api/internal/company/current/settings/connector-instance`, action.request);
		yield put({
			type: 'COMPANY_CREATE_CONNECTOR_INSTANCE_SUCCESS',
		});
		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		})
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyDeleteConnectorInstance(action) {
	try {
		yield axiosDelete(`/api/internal/company/current/settings/connector-instance/${action.instanceId}`);
		yield put({
			type: 'COMPANY_DELETE_CONNECTOR_INSTANCE_SUCCESS',
		});
		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		})
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyDeleteConnectorActivity(action) {
	try {
		yield axiosDelete(`/api/internal/company/current/settings/connector-instance/${action.instanceId}/activity/${action.activityId}`);
		yield put({
			type: 'COMPANY_DELETE_CONNECTOR_ACTIVITY_SUCCESS',
		});
		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		})
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyTestConnectorInstanceConfig(action) {
	try {
		const {data} = yield axiosPost('/api/internal/company/current/settings/connector-instance/test', action.request);
		yield put({
			type: 'COMPANY_TEST_CONNECTOR_INSTANCE_CONFIG_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyFetchStatsConfiguration() {
	try {
		const response = yield axiosGet('/api/internal/company/current/stats/configuration');
		yield put({
			type: 'COMPANY_FETCH_STATS_CONFIG_SUCCESS',
			data: response.data
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyFetchStatsApprovals(action) {
	try {
		const response = yield axiosPost('/api/internal/company/current/stats/approvals', action.request);
		yield put({
			type: 'COMPANY_FETCH_STATS_APPROVALS_SUCCESS',
			data: response.data
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyFetchStatsSignatures(action) {
	try {
		const response = yield axiosPost('/api/internal/company/current/stats/signatures', {...action.request});
		yield put({
			type: 'COMPANY_FETCH_STATS_SIGNATURES_SUCCESS',
			data: response.data
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyFetchStatsSignaturesItsme(action) {
	try {
		const response = yield axiosPost('/api/internal/company/current/stats/signatures', {...action.request, itsmeSignDetails: true});
		yield put({
			type: 'COMPANY_FETCH_STATS_SIGNATURES_ITSME_SUCCESS',
			data: response.data
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyExportStatsSignatures(action) {
	try {
		const result = yield axiosPost('/api/internal/company/current/stats/signatures-export', action.request, undefined, 'blob');
		const fileName = result.headers['content-disposition']?.replace('attachment; filename=', '') || 'signature-statistics-export.csv';
		saveAs(result.data, fileName);
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyExportStatsApprovals(action) {
	try {
		const result = yield axiosPost('/api/internal/company/current/stats/approvals-export', action.request, undefined, 'blob');
		const fileName = result.headers['content-disposition']?.replace('attachment; filename=', '') || 'approvals-statistics-export.csv';
		saveAs(result.data, fileName);
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyExportStatsItsmeDetails(action) {
	try {
		const result = yield axiosPost('/api/internal/company/current/stats/itsme-details-export', action.request, undefined, 'blob');
		const fileName = result.headers['content-disposition']?.replace('attachment; filename=', '') || 'itsme-details-statistics-export.csv';
		saveAs(result.data, fileName);
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyFetchActionLogs(action) {
	try {
		const {data: {list, count}} = yield axiosPost(`/api/internal/company/current/action-logs`, action.request);
		yield put({
			type: 'COMPANY_FETCH_ACTION_LOGS_SUCCESS',
			list,
			count
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyFetchSigningCertificateSettings(action) {
	try {
		const {data} = yield axiosGet('/api/internal/company/current/settings/signing-certificate');
		yield put({
			type: 'COMPANY_FETCH_SIGNING_CERTIFICATE_SETTINGS_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyUpdateSigningCertificateSettings(action) {
	try {
		yield axiosPut('/api/internal/company/current/settings/signing-certificate', action.settings);

		yield put({
			type: 'COMPANY_UPDATE_SIGNING_CERTIFICATE_SETTINGS_SUCCESS'
		});

		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		})
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyApiKeysFetch(action) {
	try {
		const {data: {list, count}} = yield axiosPost('/api/internal/company/current/settings/apikey/overview', action.request);
		yield put({
			type: 'COMPANY_APIKEY_FETCH_SUCCESS',
			list,
			count,
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyApiKeyCreate(action) {
	try {
		const {data} = yield axiosPost(`/api/internal/company/current/settings/apikey`, action.request);
		yield put({
			type: 'COMPANY_APIKEY_CREATE_SUCCESS',
			data
		});

		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyApiKeyUpdate(action) {
	try {
		yield axiosPut(`/api/internal/company/current/settings/apikey/${action.companyApiKeyId}`, action.request);
		yield put({
			type: 'COMPANY_APIKEY_UPDATE_SUCCESS',
		});

		yield put({
			type: 'SNACKBAR_OPEN',
			message: i18n.t('changes.saved')
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyApiKeyRevoke(action) {
	try {
		yield axiosDelete(`/api/internal/company/current/settings/apikey/${action.companyApiKeyId}`);
		yield put({
			type: 'COMPANY_APIKEY_REVOKE_SUCCESS'
		});

		yield put({
			type: 'SNACKBAR_OPEN',
			message: i18n.t('changes.saved')
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyWebhookConfigFetchOverview(action) {
	try {
		const {data} = yield axiosGet('/api/internal/company/current/settings/webhook-config/overview');
		yield put({
			type: 'COMPANY_WEBHOOK_CONFIG_FETCH_OVERVIEW_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyWebhookConfigCreate(action) {
	try {
		yield axiosPost('/api/internal/company/current/settings/webhook-config', action.request);
		yield put({
			type: 'COMPANY_WEBHOOK_CONFIG_CREATE_SUCCESS',
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* companyWebhookConfigDelete(action) {
	try {
		yield axiosDelete(`/api/internal/company/current/settings/webhook-config/${action.webhookConfigId}`);
		yield put({
			type: 'COMPANY_WEBHOOK_CONFIG_DELETE_SUCCESS',
		});
	} catch (e) {
		yield put({
			type: 'COMPANY_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

/*** DOCUMENT *********************************************************************************************************/

function* documentFetchOverviewInfo(action) {
	try {
		const {data} = yield axiosGet('/api/internal/document/overview-info');
		yield put({
			type: 'DOCUMENT_FETCH_OVERVIEW_INFO_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'DOCUMENT_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* documentFetchGeneralOverviewList(action) {
	try {
		const {data: {list, count}} = yield axiosPost('/api/internal/document/overview-general', action.request);
		yield put({
			type: 'DOCUMENT_FETCH_GENERAL_OVERVIEW_LIST_SUCCESS',
			list,
			count,
		});
	} catch (e) {
		yield put({
			type: 'DOCUMENT_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* documentFetchApprovalOverviewList(action) {
	try {
		const {data: {list, count}} = yield axiosPost('/api/internal/document/overview-approval', action.request);
		yield put({
			type: 'DOCUMENT_FETCH_APPROVAL_OVERVIEW_LIST_SUCCESS',
			list,
			count,
		});
	} catch (e) {
		yield put({
			type: 'DOCUMENT_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* documentFetchSigningOverviewList(action) {
	try {
		const {data: {list, count}} = yield axiosPost('/api/internal/document/overview-signing', action.request);
		yield put({
			type: 'DOCUMENT_FETCH_SIGNING_OVERVIEW_LIST_SUCCESS',
			list,
			count,
		});
	} catch (e) {
		yield put({
			type: 'DOCUMENT_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* documentSingleDownloadArtifacts(action) {
	try {
		const result = yield axiosGet('/api/internal/document/' + action.id + '/artifacts?type=' + action.types.join('&type='), 'blob');
		const fileName = result.headers['content-disposition']?.replace('attachment; filename=', '') || `download.pdf`;

		saveAs(result.data, fileName);

		yield put({
			type: 'DOCUMENT_SUCCESS'
		});
	} catch (e) {
		yield put({
			type: action.editorOrigin ? 'EDITOR_ERROR' : 'DOCUMENT_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* documentDownloadArtifacts(action) {
	try {
		const result = yield axiosPost('/api/internal/document/artifacts', {ids: action.ids, types: action.types}, undefined, 'blob');
		if (result.data.size > 0) {
			const fileName = result.headers['content-disposition']?.replace('attachment; filename=', '') || `download.zip`;
			saveAs(result.data, fileName);
		} else {
			yield put({
				type: 'SNACKBAR_OPEN',
				messageKey: 'document.downloadNoResult',
				messageSeverity: 'info'
			});
		}

		yield put({
			type: 'DOCUMENT_SUCCESS'
		});
	} catch (e) {
		yield put({
			type: 'DOCUMENT_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* documentDownloadAllDocumentArtifacts(action) {
	try {
		const {data: {list}} = yield axiosPost('/api/internal/document/overview-general-ids', action.request);
		const result = yield axiosPost('/api/internal/document/artifacts', {ids: list, types: action.types}, undefined, 'blob');
		if (result.data.size > 0) {
			const fileName = result.headers['content-disposition']?.replace('attachment; filename=', '') || `download.zip`;
			saveAs(result.data, fileName);
		} else {
			yield put({
				type: 'SNACKBAR_OPEN',
				messageKey: 'document.downloadNoResult',
				messageSeverity: 'info'
			});
		}

		yield put({
			type: 'DOCUMENT_SUCCESS'
		});
	} catch (e) {
		yield put({
			type: 'DOCUMENT_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* documentSendReminders(action) {
	try {
		const {data} = yield axiosPost(`/api/internal/document/${action.id}/send-reminders`);
		yield put({
			type: 'DOCUMENT_SEND_REMINDERS_SUCCESS',
			data
		});
		if (data.reminderSent) {
			yield put({
				type: 'SNACKBAR_OPEN',
				messageKey: 'reminder.success'
			});
		}
	} catch (e) {
		yield put({
			type: 'DOCUMENT_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* documentDeleteDocuments(action) {
	try {
		yield axiosPost('/api/internal/document/delete-documents', action.ids);
		yield put({
			type: 'DOCUMENT_DELETE_DOCUMENTS_SUCCESS'
		});
	} catch (e) {
		yield put({
			type: 'DOCUMENT_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* documentDeleteAllDocuments(action) {
	try {
		const {data: {list}} = yield axiosPost('/api/internal/document/overview-general-ids', action.request);
		yield axiosPost('/api/internal/document/delete-documents', list);
		yield put({
			type: 'DOCUMENT_DELETE_DOCUMENTS_SUCCESS'
		});
	} catch (e) {
		yield put({
			type: 'DOCUMENT_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* documentExportGeneralOverview() {
	try {
		const result = yield axiosGet('/api/internal/document/overview-general-export', 'blob');
		const fileName = result.headers['content-disposition']?.replace('attachment; filename=', '') || 'document-export.csv';
		saveAs(result.data, fileName);
		yield put({
			type: 'DOCUMENT_SUCCESS'
		});
	} catch (e) {
		yield put({
			type: 'DOCUMENT_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* documentCreateFromTemplate(action) {
	try {
		const {data: {id}} = yield axiosPost('/api/internal/document/template-create', action.request);

		yield put(push('/editor/id=' + id));
	} catch (e) {
		yield put({
			type: 'DOCUMENT_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* documentMakeCollection(action) {
	try {
		const {data: {id}} = yield axiosPost('/api/internal/document/collection-create', action.request);
		yield put({
			type: 'DOCUMENT_SUCCESS'
		});
		yield put(push('/editor/id=' + id + (action.applyTemplate ? ';template' : '')));
	} catch (e) {
		yield put({
			type: 'DOCUMENT_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

/*** EDITOR ***********************************************************************************************************/

function* editorFetchEditInfo(action) {
	try {
		let url;
		if (action.template) {
			url = `/api/internal/template/${action.id}/edit-info`
		} else {
			url = `/api/internal/document/${action.id}/edit-info`
		}

		const {data} = yield axiosGet(url);
		yield put({
			type: 'EDITOR_FETCH_EDIT_INFO_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'EDITOR_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* editorSaveDocument(action) {
	try {
		const {data} = yield axiosPut(`/api/internal/document/${action.document.id}/save`, action.document);
		yield put({
			type: 'EDITOR_SAVE_DOCUMENT_SUCCESS',
			data
		});
		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		});
		if (!data.editPermission) {
			yield put(push('/document/general-overview'));
		}
	} catch (e) {
		yield put({
			type: 'EDITOR_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* editorSignDocument(action) {
	try {
		const {data} = yield axiosPost(`/api/internal/document/${action.document.id}/send`, {...action.document, selfSign: true});
		yield put({
			type: 'EDITOR_SEND_DOCUMENT_SUCCESS',
			data: data
		});

		if (data.sendingFailed) {
			return;
		}

		yield put(push('/sign/ids=' + action.document.id));
	} catch (e) {
		yield put({
			type: 'EDITOR_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* editorSendDocument(action) {
	try {
		const {data} = yield axiosPost(`/api/internal/document/${action.document.id}/send`, action.document);
		yield put({
			type: 'EDITOR_SEND_DOCUMENT_SUCCESS',
			data: data
		});

		if (data.sendingFailed) {
			return;
		}

		if (!action.selfSign) {
			yield put({
				type: 'SNACKBAR_OPEN',
				messageKey: 'editor.sent'
			});
		} else {
			if (data.senderCanSign) {
				yield put({
					type: 'SNACKBAR_OPEN',
					messageKey: 'editor.sent'
				});

				yield put(push('/sign/ids=' + action.document.id));
			} else {
				yield put({
					type: 'SNACKBAR_OPEN',
					messageKey: 'editor.sentButNothingToSign'
				});
			}
		}
	} catch (e) {
		yield put({
			type: 'EDITOR_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* editorCloseDocument(action) {
	try {
		const {data} = yield axiosPost(`/api/internal/document/${action.documentId}/close`);
		yield put({
			type: 'EDITOR_CLOSE_DOCUMENT_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'EDITOR_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* editorDeleteDocument(action) {
	try {
		yield axiosDelete(`/api/internal/document/${action.documentId}`);
		yield put({
			type: 'EDITOR_SUCCESS'
		});
		yield put(push('/document/general-overview'));
	} catch (e) {
		yield put({
			type: 'EDITOR_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* editorDeleteTemplate(action) {
	try {
		yield axiosDelete(`/api/internal/template/${action.documentId}`);
		yield put({
			type: 'EDITOR_SUCCESS'
		});
		yield put(push('/template/overview'));
		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'template.deleted'
		});
	} catch (e) {
		yield put({
			type: 'EDITOR_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* editorRestartDocument(action) {
	try {
		const {data} = yield axiosPost(`/api/internal/document/${action.documentId}/restart`);
		yield put({
			type: 'EDITOR_RESTART_DOCUMENT_SUCCESS',
			data
		});
		yield put(push(`/editor/id=${data.id}`));
		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'editor.restarted'
		});
	} catch (e) {
		yield put({
			type: 'EDITOR_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* editorFetchPossibleUsers(action) {
	try {
		// TODO: enhancement: replace document path by company path
		let url;
		if (0 === action.documentId) {
			url = `/api/internal/company/current/possible-approvers-signers`;
		} else {
			url = `/api/internal/document/${action.documentId}/possible-approvers-signers`;
		}
		const {data: {list, count }} = yield axiosPost(url, action.request);
		yield put({
			type: 'EDITOR_FETCH_POSSIBLE_USERS_SUCCESS',
			list,
			count
		});
	} catch (e) {
		yield put({
			type: 'EDITOR_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* editorFetchActionLogs(action) {
	try {
		const {data} = yield axiosGet(`/api/internal/document/${action.documentId}/action-logs`);
		yield put({
			type: 'EDITOR_FETCH_ACTION_LOGS_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'EDITOR_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* editorSaveTemplate(action) {
	try {
		yield axiosPost(`/api/internal/template/create`, action.request);
		yield put({
			type: 'EDITOR_SUCCESS',
		});
		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'editor.templateSaved'
		});
	} catch (e) {
		yield put({
			type: 'EDITOR_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

/*** EID **************************************************************************************************************/

function* eidStartMiddleware(action) {
	yield race({
		task: call(eidMiddlewareLoop),
		cancel: take(['EID_STOP_MIDDLEWARE'])
	});
}

/*** FOLDER ***********************************************************************************************************/

function* folderFetchOverviewList(action) {
	try {
		const {data: {list, count}} = yield axiosPost('/api/internal/folder/overview', action.request);
		yield put({
			type: 'FOLDER_FETCH_OVERVIEW_LIST_SUCCESS',
			list,
			count,
		});
	} catch (e) {
		yield put({
			type: 'FOLDER_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* folderCreate(action) {
	try {
		yield axiosPost('/api/internal/folder/', action.request);
		yield put({
			type: 'FOLDER_CREATE_SUCCESS',
		});
		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		});
	} catch (e) {
		yield put({
			type: 'FOLDER_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* folderDelete(action) {
	try {
		yield axiosDelete(`/api/internal/folder/${action.folderId}`);
		yield put({
			type: 'FOLDER_DELETE_SUCCESS',
		});
		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'folder.deleted'
		});
	} catch (e) {
		yield put({
			type: 'FOLDER_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* folderFetchCompanySettings(action) {
	try {
		const {data} = yield axiosGet('/api/internal/folder/company-settings');
		yield put({
			type: 'FOLDER_FETCH_COMPANY_SETTINGS_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'FOLDER_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* folderFetchRelevantGroups(action) {
	try {
		const {data} = yield axiosGet(`/api/internal/folder/relevant-groups`);
		yield put({
			type: 'FOLDER_FETCH_RELEVANT_GROUPS_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'FOLDER_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* folderFetchRelevantUsers(action) {
	try {
		const {data} = yield axiosGet(`/api/internal/folder/relevant-users`);
		yield put({
			type: 'FOLDER_FETCH_RELEVANT_USERS_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'FOLDER_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* folderFetchSettings(action) {
	try {
		const {data} = yield axiosGet(`/api/internal/folder/${action.folderId}/settings`);
		yield put({
			type: 'FOLDER_FETCH_SETTINGS_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'FOLDER_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* folderUpdateSettings(action) {
	try {
		yield axiosPut(`/api/internal/folder/${action.folderId}/settings`, action.settings);
		yield put({
			type: 'FOLDER_UPDATE_SETTINGS_SUCCESS',
		});
		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		});
	} catch (e) {
		yield put({
			type: 'FOLDER_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* folderFetchPermissions(action) {
	try {
		const {data} = yield axiosGet(`/api/internal/folder/${action.folderId}/permissions`);
		yield put({
			type: 'FOLDER_FETCH_PERMISSIONS_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'FOLDER_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* folderUpdatePermissions(action) {
	try {
		yield axiosPut(`/api/internal/folder/${action.folderId}/permissions`, action.permissions);
		yield put({
			type: 'FOLDER_UPDATE_PERMISSIONS_SUCCESS',
		});
		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		});
	} catch (e) {
		yield put({
			type: 'FOLDER_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* folderFetchDelegateLists(action) {
	try {
		const {data} = yield axiosGet(`/api/internal/folder/${action.folderId}/delegate-list/all`);
		yield put({
			type: 'FOLDER_FETCH_DELEGATE_LISTS_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'FOLDER_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* folderFetchDelegateList(action) {
	try {
		const {data} = yield axiosGet(`/api/internal/folder/${action.folderId}/delegate-list/${action.delegateListId}`);
		yield put({
			type: 'FOLDER_FETCH_DELEGATE_LIST_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'FOLDER_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* folderCreateUpdateDelegateList(action) {
	try {
		const {data} = yield axiosPost(`/api/internal/folder/${action.folderId}/delegate-list/`, action.delegateList);
		yield put({
			type: 'FOLDER_CREATE_UPDATE_DELEGATE_LIST_SUCCESS',
			data
		});
		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		});
	} catch (e) {
		yield put({
			type: 'FOLDER_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* folderDeleteDelegateList(action) {
	try {
		yield axiosDelete(`/api/internal/folder/${action.folderId}/delegate-list/${action.delegateListId}`, action.delegateList);
		yield put({
			type: 'FOLDER_DELETE_DELEGATE_LIST_SUCCESS',
		});
		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		});
	} catch (e) {
		yield put({
			type: 'FOLDER_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* folderDownloadDelegateFile(action) {
	try {
		const result = yield axiosGet(`/api/internal/folder/${action.folderId}/delegate-list/${action.delegateListId}/delegate/${action.delegateId}/download`, 'blob');
		const fileName = result.headers['content-disposition']?.replace('attachment; filename=', '') || `download.pdf`;

		saveAs(result.data, fileName);

		yield put({
			type: 'FOLDER_DOWNLOAD_DELEGATE_FILE_SUCCESS'
		});
	} catch (e) {
		yield put({
			type: 'FOLDER_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* folderDeleteDelegateFile(action) {
	try {
		yield axiosDelete(`/api/internal/folder/${action.folderId}/delegate-list/${action.delegateListId}/delegate/${action.delegateId}`);

		yield put({
			type: 'FOLDER_DELETE_DELEGATE_FILE_SUCCESS'
		});

		yield folderFetchDelegateList({folderId: action.folderId, delegateListId: action.delegateListId});
	} catch (e) {
		yield put({
			type: 'FOLDER_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}


/*** SESSION **********************************************************************************************************/

function* sessionAcceptTermsAndConditions(action) {
	try {
		yield axiosPost('/api/internal/session/accept-terms-and-conditions');
		yield put({
			type: 'SESSION_ACCEPT_TERMS_AND_CONDITIONS_SUCCESS',
		});
	} catch (e) {
		yield put({
			type: 'SESSION_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* sessionFetchCompanyInfo(action) {
	try {
		const {data} = yield axiosPost('/api/internal/session/company-info', {currentHostName: action.currentHostName});

		yield put({
			type: 'SESSION_FETCH_COMPANY_INFO_SUCCESS',
			data,
		});
	} catch (e) {
		yield put({
			type: 'SESSION_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* checkSession(action) {
	try {
		const {data} = yield axiosGet('/api/internal/session/info');
		if (!!data) {
			if (!!data.companyUrl && window.location.origin !== data.companyUrl) {
				// redirect the user to the correct URL if the domain doesn't match
				let redirectUrl = data.companyUrl + '/' + window.location.hash;

				const csrfToken = localStorage.getItem('TOKEN_CSRF');
				if (!!csrfToken) {
					localStorage.removeItem('TOKEN_CSRF');

					redirectUrl += '?csrf=' + csrfToken;
				}

				window.location.assign(redirectUrl);
			} else if (!!data.language && data.language !== i18n.resolvedLanguage) {
				yield i18n.changeLanguage(data.language);
			}

			yield put({
				type: 'SESSION_CHECK_SUCCESS',
				created: true,
				data
			});
			return;
		}
		const {router: {location: {pathname}}} = yield select();

		// no session exists yet -> either redirect to the login page, or inform the login page the session did not exist
		if (!action.loginPageOrigin) {
			localStorage.setItem('post-login-path', pathname);

			yield put({
				type: 'SESSION_CHECK_SUCCESS',
				created: false
			});
			yield put(push('/login'));
		} else {
			yield put({
				type: 'SESSION_CHECK_SUCCESS',
				created: false
			});
		}
	} catch (e) {
		yield put({
			type: 'SESSION_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* sessionCreateOidc(action) {
	try {
		const {data} = yield axiosPost('/api/internal/session/login-oidc-start', {
			oidcInstanceId: action.oidcInstanceId
		});
		if (data.oidcServerDown) {
			yield delay(5000);
		} else {
			// if there is no specific path set, set one now to redirect the user to after successful authentication
			let postLoginPath = localStorage.getItem('post-login-path');
			if (!postLoginPath) localStorage.setItem('post-login-path', '/');

			window.location.assign(data.redirectUrl);
		}
	} catch (e) {
		yield put({
			type: 'SESSION_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* sessionCreateHandleLoginCallback(action) {
	try {
		const {data: {tokens}} = yield axiosPost('/api/internal/session/login-oidc-complete', {
			oidcCallbackUrl: window.location.href,
			languageIsoCode: i18n.resolvedLanguage
		});
		if (!!tokens.CSRF) {
			// keep CSRF token
			localStorage.setItem('TOKEN_CSRF', tokens.CSRF.value);
		}

		window.location.assign(window.location.origin + '/#' + (action.loginSavedPath || '/'));
	} catch (e) {
		const serverError = yield extractServerError(e);
		window.location.assign(window.location.origin + '/#/login/error=' + serverError);
	}
}

function* sessionLoginPassword(action) {
	try {
		const response = yield axiosPost('/api/internal/session/login-password', {
			email: action.email,
			password: action.password,
			companyId: action.companyId
		});

		const {tokens} = response.data;

		if (!!tokens.CSRF) {
			// keep CSRF token
			localStorage.setItem('TOKEN_CSRF', tokens.CSRF.value);
		}

		yield put({
			type: 'SESSION_LOGIN_SUCCESS'
		});

		let postLoginPath = localStorage.getItem('post-login-path');
		yield put(push(postLoginPath || '/'));
	} catch (e) {
		yield put({
			type: 'SESSION_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* sessionCreateGuest(action) {
	try {
		yield axiosPost('/api/internal/session/login-guest', action.request);
		const {data} = yield axiosGet('/api/internal/session/info');
		if (!!data.language && data.language !== i18n.resolvedLanguage) {
			yield i18n.changeLanguage(data.language);
		}

		// keep track of the guest session entrypoint for navigation purposes
		const guestPath = window.location.hash.substring(1);

		yield put({
			type: 'SESSION_CHECK_SUCCESS',
			created: true,
			data, guestPath
		});
	} catch (e) {
		yield put({
			type: 'SESSION_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* sessionRegisterGuest(action) {
	try {
		yield axiosPost('/api/internal/session/register-guest');
		yield put({
			type: 'SESSION_DESTROY'
		});
		yield put(push('/login'));
	} catch (e) {
		yield put({
			type: 'SESSION_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* sessionRefreshInfo(action) {
	try {
		const {data} = yield axiosGet('/api/internal/session/info?refresh=true');
		if (!!data) {
			yield put({
				type: 'SESSION_REFRESH_INFO_SUCCESS',
				data
			});
		}
	} catch (e) {
		yield put({
			type: 'SESSION_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* sessionLoginEid(action) {
	try {
		const state = yield select();

		// gather data from card
		const [{data: rnData}, verifiedIdentityData, {certificate: authenticationCertificate},
			{certificate: citizenCertificate}] = yield all([
			yield eidMiddlewareReadRawIdentity(state.eid.card),
			yield eidMiddlewareReadVerifiedIdentity(state.eid.card),
			yield eidMiddlewareReadAuthenticationCertificate(state.eid.card),
			yield eidMiddlewareReadCitizenCertificate(state.eid.card),
		]);

		const cardNumber = verifiedIdentityData.identity.cardNumber;
		const nationalNumber = verifiedIdentityData.identity.nationalNumber;
		const rnCertificate = verifiedIdentityData.verifiedRnCertificate.certificate;
		const rnSignature = verifiedIdentityData.verifiedIdentitySignature.signature;
		const companyId = action.companyId;

		let response;
		try {
			// verify the user and generate a challenge
			response = yield axiosPost('/api/internal/session/login-eid-start', {
				eidNumber: cardNumber,
				belgiumNationalNumber: nationalNumber
			});
		} catch (e) {
			yield put({
				type: 'SESSION_ERROR',
				serverError: yield extractServerError(e)
			});
			return;
		}

		const {challenge: eidChallenge} = response.data;

		// sign the challenge through the card
		let eidChallengeSigned;
		try {
			const response = yield eidMiddlewareAuthenticate(state.eid.card, {
				hash: eidChallenge,
				hashType: 'SHA2-512',
				timeout: 60,
				language: i18n.resolvedLanguage,
				pinCode: action.pinCode
			});
			eidChallengeSigned = response.signature;
		} catch (e) {
			const incorrectPinStatusCodes = {
				VERIFY_PIN_INCORRECT_FOUR_TRIES_REMAINING: 4,
				VERIFY_PIN_INCORRECT_THREE_TRIES_REMAINING: 3,
				VERIFY_PIN_INCORRECT_TWO_TRIES_REMAINING: 2,
				VERIFY_PIN_INCORRECT_ONE_TRY_REMAINING: 1,
				VERIFY_PIN_BLOCKED: 0,
				VERIFY_PIN_INCORRECT: 0
			};

			if (!!e.data && !!e.data.status && incorrectPinStatusCodes.hasOwnProperty(e.data.status.code)) {
				yield put({
					type: 'EID_WRONG_PIN',
					triesLeft: incorrectPinStatusCodes[e.data.status.code]
				});
			} else if (!!e.data && !!e.data.status && ('VERIFY_PIN_TIMED_OUT' === e.data.status.code || 'VERIFY_PIN_CANCELED' === e.data.status.code)) {
				yield put({
					type: 'EID_PIN_TIMEOUT_OR_CANCEL',
				})
			} else {
				yield put({
					type: 'SESSION_ERROR',
					serverError: yield extractServerError(e)
				});
			}

			return;
		}

		// complete the eid authentication
		response = yield axiosPost('/api/internal/session/login-eid-complete', {
			eidChallenge,
			eidChallengeSigned,
			authenticationCertificate,
			citizenCertificate,
			rnData,
			rnSignature,
			rnCertificate,
			companyId
		});

		const {tokens} = response.data;

		if (!!tokens.CSRF) {
			// keep CSRF token
			localStorage.setItem('TOKEN_CSRF', tokens.CSRF.value);
		}

		yield put({
			type: 'EID_STOP_MIDDLEWARE'
		});

		yield put({
			type: 'SESSION_LOGIN_SUCCESS'
		});

		// redirect
		let postLoginPath = localStorage.getItem('post-login-path');
		yield put(push((postLoginPath || '/')));
	} catch (e) {
		yield put({
			type: 'SESSION_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* sessionSwitchCompany(action) {
	try {
		const {data} = yield axiosPost('/api/internal/session/switch-to-company', action.request);
		// TODO-FIXME: in theory the language could change?

		// check if we have to switch (sub)domains
		if (!!data.companyUrl && window.location.origin !== data.companyUrl) {
			let redirectUrl = data.companyUrl + '/' + window.location.hash;

			const csrfToken = localStorage.getItem('TOKEN_CSRF');
			if (!!csrfToken) {
				localStorage.removeItem('TOKEN_CSRF');

				redirectUrl += '?csrf=' + csrfToken;
			}

			window.location.assign(redirectUrl);
		} else {
			yield put({
				type: 'SESSION_SWITCH_COMPANY_SUCCESS',
				data
			});
		}
	} catch (e) {
		yield put({
			type: 'SESSION_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* sessionUpdateVisualSignature(action) {
	try {
		if (!!action.fileSize && action.fileSize > MAX_FILE_SIZE) {
			yield put({
				type: 'SESSION_ERROR',
				serverError: 'IMAGE_INVALID_SIZE'
			});
			return;
		}

		yield axiosPost('/api/internal/session/visual-signature', {
			visualSignature: action.visualSignature,
			mimeType: action.mimeType
		});
		yield put({
			type: 'SESSION_UPDATE_VISUAL_SIGNATURE_SUCCESS',
			visualSignature: action.visualSignature,
		});
		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		})
	} catch (e) {
		yield put({
			type: 'SESSION_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* sessionUpdateVisualParaph(action) {
	try {
		yield axiosPost('/api/internal/session/visual-paraph', {
			visualSignature: action.visualParaph,
			mimeType: action.mimeType
		});
		yield put({
			type: 'SESSION_UPDATE_VISUAL_PARAPH_SUCCESS',
			visualParaph: action.visualParaph,
		});
		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		})
	} catch (e) {
		yield put({
			type: 'SESSION_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* sessionFetchUserProfile(action) {
	try {
		const {data} = yield axiosGet('/api/internal/session/profile');

		yield put({
			type: 'SESSION_FETCH_USER_PROFILE_SUCCESS',
			profile: data
		})

	} catch (e) {
		yield put({
			type: 'SESSION_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* sessionUpdateUserProfile(action) {
	try {
		yield axiosPost('/api/internal/session/profile', action.request);
		yield put({
			type: 'SESSION_UPDATE_USER_PROFILE_SUCCESS',
			profile: action.request
		});
		if (!!action.request.language && action.request.language !== i18n.resolvedLanguage) {
			yield i18n.changeLanguage(action.request.language);
		}
		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		})

		// out-of-office settings might have changed, refresh the session info
		yield sessionRefreshInfo(action);
	} catch (e) {
		yield put({
			type: 'SESSION_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* sessionForgetItsmeIdentity(action) {
	try {
		yield axiosPost('/api/internal/session/forget-itsme-identity');
		yield put({
			type: 'SESSION_FORGET_ITSME_IDENTITY_SUCCES',
		});
		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'user.profileItsmeForgetSuccess'
		})
	} catch (e) {
		yield put({
			type: 'SESSION_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* sessionUpdateUserPassword(action) {
	try {
		yield axiosPost('/api/internal/session/update-password', {
			currentPassword: action.currentPassword,
			newPassword: action.newPassword,
			newPasswordConfirmation: action.newPasswordConfirmation
		});

		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		});
	} catch (e) {
		yield put({
			type: 'SESSION_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* sessionResetPasswordUpdate(action) {
	try {
		yield axiosPost('/api/internal/session/password-reset', {
			password: action.password,
			passwordConfirmation: action.passwordConfirmation,
			payload: action.payload
		});
		yield put({
			type: 'SESSION_RESET_PASSWORD_UPDATE_SUCCESS'
		});
	} catch (e) {
		yield put({
			type: 'SESSION_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* sessionForgotPassword(action) {
	try {
		yield axiosPost('/api/internal/session/password-should-reset', {
			email: action.email,
		});
		yield put({
			type: 'SESSION_FORGOT_PASSWORD_SUCCESS'
		});
	} catch (e) {
		yield put({
			type: 'SESSION_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* sessionFetchApiAccess(action) {
	try {
		const {data} = yield axiosGet('/api/internal/session/api-access');
		yield put({
			type: 'SESSION_FETCH_API_ACCESS_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'SESSION_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* sessionUpdateApiAccess(action) {
	try {
		const {data} = yield axiosPost('/api/internal/session/api-access', {enable: action.enable});
		yield put({
			type: 'SESSION_UPDATE_API_ACCESS_SUCCESS',
			data
		});
		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		});
	} catch (e) {
		yield put({
			type: 'SESSION_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* sessionSwitchKioskUser(action) {
	try {
		const {data} = yield axiosPost('/api/internal/session/switch-kiosk-user', {documentId: action.documentId, signerId: action.signerId});

		yield put({
			type: 'SESSION_SWITCH_KIOSK_USER_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'SESSION_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}


function* sessionLogOut(action) {
	try {
		const {data: {redirectUrl}} = yield axiosGet('/api/internal/session/logout');

		localStorage.removeItem('TOKEN_CSRF');

		if (!!redirectUrl) {
			window.location.assign(redirectUrl);
		}
	} catch (e) {
		yield put({
			type: 'SESSION_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

/*** SIGNER GROUP *******************************************************************************************************/

function* signerGroupFetchOverviewList(action) {
	try {
		const {data: {list, count}} = yield axiosPost('/api/internal/signergroup/overview', action.request);
		yield put({
			type: 'SIGNERGROUP_FETCH_OVERVIEW_LIST_SUCCESS',
			list,
			count,
		});
	} catch (e) {
		yield put({
			type: 'SIGNERGROUP_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* signerGroupCreate(action) {
	try {
		const {data: {id}} = yield axiosPost(`/api/internal/signergroup`, action.request);
		yield put({
			type: 'SIGNERGROUP_CREATE_SUCCESS',
			createdSignerGroupId: id,
		});

		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		});
	} catch (e) {
		yield put({
			type: 'SIGNERGROUP_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* signerGroupDelete(action) {
	try {
		yield axiosDelete(`/api/internal/signergroup/${action.signerGroupId}`);
		yield put({
			type: 'SIGNERGROUP_DELETE_SUCCESS',
		});

		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'signerGroup.deleted'
		});
	} catch (e) {
		yield put({
			type: 'SIGNERGROUP_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* signerGroupFetchSettings(action) {
	try {
		const {data} = yield axiosGet(`/api/internal/signergroup/${action.signerGroupId}/settings`);
		yield put({
			type: 'SIGNERGROUP_FETCH_SETTINGS_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'SIGNERGROUP_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* signerGroupUpdateSettings(action) {
	try {
		yield axiosPut(`/api/internal/signergroup/${action.signerGroupId}/settings`, action.settings);
		yield put({
			type: 'SIGNERGROUP_UPDATE_SETTINGS_SUCCESS',
		});

		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		});
	} catch (e) {
		yield put({
			type: 'SIGNERGROUP_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* signerGroupFetchUserList(action) {
	try {
		const {
			data: {
				list,
				count
			}
		} = yield axiosPost(`/api/internal/signergroup/${action.signerGroupId}/user/list`, action.request);
		yield put({
			type: 'SIGNERGROUP_FETCH_USER_LIST_SUCCESS',
			list,
			count,
		});
	} catch (e) {
		yield put({
			type: 'SIGNERGROUP_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* signerGroupFetchPossibleUserList(action) {
	try {
		const {data: {list, count}} = yield axiosPost('/api/internal/signergroup/possible-users', action.request);
		yield put({
			type: 'SIGNERGROUP_FETCH_POSSIBLE_USER_LIST_SUCCESS',
			list,
			count,
		});
	} catch (e) {
		yield put({
			type: 'SIGNERGROUP_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* signerGroupAddUsers(action) {
	try {
		yield axiosPost(`/api/internal/signergroup/${action.signerGroupId}/user`, {userIds: action.userIds});

		yield put({
			type: 'SIGNERGROUP_FETCH_USER_LIST',
			signerGroupId: action.signerGroupId,
			request: action.pageRequest
		});

		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		});
	} catch (e) {
		yield put({
			type: 'SIGNERGROUP_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* signerGroupRemoveUser(action) {
	try {
		yield axiosDelete(`/api/internal/signergroup/${action.signerGroupId}/user/${action.userId}`);
		yield put({
			type: 'SIGNERGROUP_FETCH_USER_LIST',
			signerGroupId: action.signerGroupId,
			request: action.pageRequest
		});

		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		});
	} catch (e) {
		yield put({
			type: 'SIGNERGROUP_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* signerGroupFetchApproversSigners(action) {
	try {
		const {data} = yield axiosGet(`/api/internal/signergroup/${action.signerGroupId}/approvers-signers${!!action.folderId ? '?folderId=' + action.folderId : ''}`);
		yield put({
			type: 'SIGNERGROUP_FETCH_APPROVERS_SIGNERS_SUCCESS',
			data,
		});
	} catch (e) {
		yield put({
			type: 'SIGNERGROUP_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

/*** SIGNING **********************************************************************************************************/

function* signingFetchData(action) {
	try {
		const {data} = yield axiosGet('/api/internal/signing/data' + (!!action.ids ? ('?ids=' + action.ids) : ''));
		yield put({
			type: 'SIGNING_FETCH_DATA_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'SIGNING_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* signingRenewGuestAccess(action) {
	try {
		yield axiosPost('/api/internal/signing/renew-guest-access');
		yield put({
			type: 'SIGNING_RENEW_GUEST_ACCESS_SUCCESS'
		});
	} catch (e) {
		yield put({
			type: 'SIGNING_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* signingAcquireLocks(action) {
	try {
		// try to acquire locks
		const {data: {lockReceived, guestAccessExpired, queued, positionInQueue}} = yield axiosPost('/api/internal/signing/lock', {
			entries: action.signRequestIds.map(signRequestId => ({
				signRequestId,
				...(action.signatureType !== 'DECLINE' && {signatureType: action.signatureType}),
				declining: action.signatureType === 'DECLINE',
			}))
		});

		if (!lockReceived) {
			yield put({
				type: 'SIGNING_ACQUIRE_LOCKS_FAILED',
				guestAccessExpired
			});
		} else if (queued) {
			yield put({
				type: 'SIGNING_ACQUIRE_LOCKS_QUEUED',
				positionInQueue
			});
			while (true) {
				yield delay(5000);

				const {data} = yield axiosPost('/api/internal/signing/lock', {
					entries: action.signRequestIds.map(signRequestId => ({signRequestId})),
					obtainPositionInQueue: true
				});
				if (!data.queued) {
					yield put({
						type: 'SIGNING_ACQUIRE_LOCKS_ACQUIRED',
						signRequestIds: action.signRequestIds,
					});
					return;
				}

				yield put({
					type: 'SIGNING_ACQUIRE_LOCKS_QUEUED',
					positionInQueue: data.positionInQueue
				});
			}
		} else {
			yield put({
				type: 'SIGNING_ACQUIRE_LOCKS_ACQUIRED',
				signRequestIds: action.signRequestIds,
			});
		}
	} catch (e) {
		yield put({
			type: 'SIGNING_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* signingExtendLocksStart(action) {
	yield race({
		task: call(signingExtendLocksTask, action),
		cancel: take(['SIGNING_EXTEND_LOCKS_STOP'])
	});
}

function* signingExtendLocksTask(action) {
	try {
		while (true) {
			const {signing: {requestsLocked, requestsCompleted}} = yield select();
			const signRequests = requestsLocked.filter(id => requestsCompleted.indexOf(id) === -1);
			if (signRequests.length === 0) {
				return;
			}
			yield axiosPost('/api/internal/signing/extend-lock', {
				entries: signRequests.map(signRequestId => ({
					signRequestId,
					...(action.signatureType !== 'DECLINE' && {signatureType: action.signatureType}),
					declining: action.signatureType === 'DECLINE',
				}))
			});
			yield delay(10000);
		}
	} catch (e) {
		yield put({
			type: 'SIGNING_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* signingSignStart(action) {
	try {
		const signRequests = action.signRequestIds.map(signRequestId => {
			const reason = (action.reasons.find(reason =>
				reason.linkedSignRequests.findIndex(sr => (sr.id === signRequestId)) >= 0) || {}).value || '';
			const capacity = action.capacities.find(capacity => capacity.signRequestId === signRequestId);
			const filledInFormFields = action.signRequestFormFields
				.filter(field => field.signRequestId === signRequestId)
				.flatMap(field => field.formFields);

			return {
				signRequestId,
				reason,
				remark: action.remark,
				capacity,
				filledInFormFields
			};
		});

		const visualSignatures = [];
		const allowedMimeTypes = ["image/jpeg", "image/jpg", "image/png"];
		if (action.visualSignature.overwrite) {
			if (!!action.visualSignature.fileSize && action.visualSignature.fileSize > MAX_FILE_SIZE) {
				yield put({
					type: 'SIGNING_SIGN_ERROR',
					serverError: 'IMAGE_INVALID_SIZE'
				});
				return;
			}
			if (!!action.visualSignature.mimeType && !allowedMimeTypes.includes(action.visualSignature.mimeType)) {
				yield put({
					type: 'SIGNING_SIGN_ERROR',
					serverError: 'IMAGE_INVALID_MIME_TYPE'
				});
				return;
			}

			if (action.visualSignature.saveAsDefault && !!action.visualSignature.data) {
				yield call(sessionUpdateVisualSignature, {
					visualSignature: action.visualSignature.data,
					mimeType: action.visualSignature.mimeType
				});
			} else {
				visualSignatures.push({
					type: 'SIGNATURE',
					dataBase64: action.visualSignature.data,
					mimeType: action.visualSignature.mimeType
				});
			}
		}
		if (action.visualParaph.overwrite) {
			if (!!action.visualParaph.fileSize && action.visualParaph.fileSize > MAX_FILE_SIZE) {
				yield put({
					type: 'SIGNING_SIGN_ERROR',
					serverError: 'IMAGE_INVALID_SIZE'
				});
				return;
			}
			if (!!action.visualParaph.mimeType && !allowedMimeTypes.includes(action.visualParaph.mimeType)) {
				yield put({
					type: 'SIGNING_SIGN_ERROR',
					serverError: 'IMAGE_INVALID_MIME_TYPE'
				});
				return;
			}

			if (action.visualParaph.saveAsDefault && !!action.visualParaph.data) {
				yield call(sessionUpdateVisualParaph, {
					visualParaph: action.visualParaph.data,
					mimeType: action.visualParaph.mimeType
				});
			} else {
				visualSignatures.push(
					{
						type: 'PARAPH',
						dataBase64: action.visualParaph.data,
						mimeType: action.visualParaph.mimeType
					}
				);
			}
		}

		if ('ITSME' === action.signatureType || 'ITSME_ADVANCED' === action.signatureType) {
			const {data: {sessionId}} = yield axiosPost('/api/internal/signing/sign-itsme-start', {
				qualification: ('ITSME' === action.signatureType ? 'QUALIFIED' : 'ADVANCED'),
				signingEntries: signRequests,
				visualSignatures: visualSignatures,
				linkItsmeUserCodeToUser: action.itsmeLinkUserCode,
				originUrl: window.location.href,
				kioskSignerId: action.kioskSignerId
			});

			yield signingItsmeStatusLoop({
				itsmeSessionId: sessionId,
				signRequestIds: action.signRequestIds,
				kioskSignerId: action.kioskSignerId
			});

			return;
		}

		let pinToken;

		for (const signRequest of signRequests) {
			if ('DECLINE' === action.signatureType) {
				yield axiosPost('/api/internal/signing/decline', {
					signRequestId: signRequest.signRequestId,
					reason: action.declineReason,
					attachmentId: action.declineAttachmentId
				});
			} else if ('ELECTRONIC_WITH_WITNESS_SEAL' === action.signatureType) {
				yield axiosPost('/api/internal/signing/sign-seal', {
					signingEntries: [signRequest],
					visualSignatures: visualSignatures,
					kioskSignerId: action.kioskSignerId
				});
			} else if ('SMS_OTP' === action.signatureType || 'EMAIL_OTP' === action.signatureType) {
				yield axiosPost('/api/internal/signing/sign-otp-finish', {
					signingEntries: [signRequest],
					visualSignatures: visualSignatures,
					otpChallenge: action.otpChallenge,
					otpMethod: 'SMS_OTP' === action.signatureType ? 'SMS' : 'EMAIL',
					kioskSignerId: action.kioskSignerId
				});
			} else if ('BELGIAN_EID' === action.signatureType) {
				let response;

				let nonRepudiationCertificate;
				let citizenCaCertificate;
				try {
					response = yield eidMiddlewareReadNonRepudiationCertificate(action.eidCard);
					nonRepudiationCertificate = response.certificate;

					response = yield eidMiddlewareReadCitizenCertificate(action.eidCard);
					citizenCaCertificate = response.certificate;
				} catch (e) {
					yield put({
						type: 'SIGNING_SIGN_ABORTED',
						reason: 'ERROR'
					});
					return;
				}

				response = yield axiosPost('/api/internal/signing/sign-eid-get-hash', {
					signingEntries: [signRequest],
					visualSignatures: visualSignatures,
					nonRepudiationCertificate,
					kioskSignerId: action.kioskSignerId
				});

				// sign it by the middleware
				// supported middleware hashes:
				//  SHA-1, SHA2-224, SHA2-256, SHA2-384, SHA2-512
				// server types: PdfSigningHelper
				const hash = response.data.base64Hash
				const hashType = ((serverType) => {
					switch (serverType) {
						case 'SHA512':
							return 'SHA2-512';
						case 'SHA256':
							return 'SHA2-256';
					}
				})(response.data.hashAlgorithm);

				try {
					const data = {
						hash, hashType, timeout: 60, language: i18n.resolvedLanguage,
						...(!pinToken && action?.pin?.length > 0 && {pinCode: action.pin}),
						...(!!pinToken && {pinToken: pinToken})
					};
					response = yield eidMiddlewareSign(action.eidCard, data);
					pinToken = response.pinToken;
				} catch (e) {
					const statusCodes = {
						VERIFY_PIN_INCORRECT_FOUR_TRIES_REMAINING: 4,
						VERIFY_PIN_INCORRECT_THREE_TRIES_REMAINING: 3,
						VERIFY_PIN_INCORRECT_TWO_TRIES_REMAINING: 2,
						VERIFY_PIN_INCORRECT_ONE_TRY_REMAINING: 1,
						VERIFY_PIN_BLOCKED: 0,
						VERIFY_PIN_INCORRECT: 0
					};
					if (!!e.data && !!e.data.status && statusCodes.hasOwnProperty(e.data.status.code)) {
						yield put({
							type: 'SIGNING_SIGN_ABORTED',
							reason: 'PIN_WRONG',
							triesLeft: statusCodes[e.data.status.code]
						});
						return;
					} else if (!!e.data && !!e.data.status && ('VERIFY_PIN_TIMED_OUT' === e.data.status.code || 'VERIFY_PIN_CANCELED' === e.data.status.code)) {
						yield put({
							type: 'SIGNING_SIGN_ABORTED',
							reason: 'PIN_TIMEOUT_OR_CANCEL',
						})
						return;
					} else {
						yield put({
							type: 'SIGNING_SIGN_ABORTED',
							reason: 'ERROR'
						});
						return;
					}
				}

				response = yield axiosPost('/api/internal/signing/sign-eid-finish', {
					signRequestId: signRequest.signRequestId,
					signedHash: response.signature,
					citizenCaCertificate,
					nonRepudiationCertificate
				});
			} else if ('HANDWRITTEN' === action.signatureType) {
				yield axiosPost('/api/internal/signing/sign-handwritten', {
					signingEntries: [signRequest],
					visualSignatures: visualSignatures,
					kioskSignerId: action.kioskSignerId
				});
			}

			yield put({
				type: 'SIGNING_SIGN_DOCUMENT_COMPLETE',
				signRequestId: signRequest.signRequestId,
				signed: 'DECLINE' !== action.signatureType
			});
		}
		yield put({
			type: 'SIGNING_SIGN_FINISHED',
		});
	} catch (e) {
		yield put({
			type: 'SIGNING_SIGN_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* signingForward(action) {
	try {
		yield axiosPost(`/api/internal/signing/forward`, action.request);
		yield put({
			type: 'SIGNING_FORWARD_SUCCESS'
		});
		if (action.isGuest) {
			yield put(push(`/sign/forwarded`));
		} else {
			yield put(push(`/document/signing-overview`));
			yield put({
				type: 'SNACKBAR_OPEN',
				messageKey: 'signing.forwardSuccess'
			});
		}
	} catch (e) {
		yield put({
			type: 'SIGNING_FORWARD_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* signingOtpAuthenticate(action) {
	try {
		yield axiosPost('/api/internal/signing/sign-otp-authenticate', {
			signRequestIds: action.signRequestIds,
			otpMethod: action.otpMethod,
			otpNumberCheck: action.otpNumberCheck,
			attempt: action.attempt,
		});
		yield put({
			type: 'SIGNING_OTP_AUTHENTICATE_SUCCESS',
		});
		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'signing.smsOtpAuthenticateSuccess'
		});
	} catch (e) {
		yield put({
			type: 'SIGNING_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* signingItsmeStatusLoop(action) {
	try {
		for (; ;) {
			const {data: {state, error, itsMeUrl, progress}} = yield axiosPost('/api/internal/signing/sign-itsme-status', {
				sessionId: action.itsmeSessionId
			});

			if (!!error) {
				yield put({
					type: 'SIGNING_SIGN_ERROR',
					serverError: error
				});

				return;
			}

			yield put({
				type: 'SIGNING_ITSME_UPDATE_STATE',
				state,
				progress
			});

			if ('FINISHED' === state) {
				for (const signRequestId of action.signRequestIds) {
					yield put({
						type: 'SIGNING_SIGN_DOCUMENT_COMPLETE',
						signRequestId,
						signed: true
					});
				}

				yield put({
					type: 'SIGNING_SIGN_FINISHED',
				});

				return;
			}

			if (!!itsMeUrl) {
				window.location.assign(itsMeUrl);
				return;
			}

			yield delay(5000);
		}
	} catch (e) {
		yield put({
			type: 'SIGNING_SIGN_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

/*** TEMPLATE *********************************************************************************************************/

function* templateFetchOverviewList(action) {
	try {
		const {data: {list, count}} = yield axiosPost('/api/internal/template/overview', action.request);
		yield put({
			type: 'TEMPLATE_FETCH_OVERVIEW_LIST_SUCCESS',
			list,
			count,
		});
	} catch (e) {
		yield put({
			type: 'TEMPLATE_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* templateFetch(action) {
	try {
		const {data} = yield axiosGet(`/api/internal/template/${action.id}`);
		yield put({
			type: 'TEMPLATE_FETCH_SUCCESS',
			data,
		});
	} catch (e) {
		yield put({
			type: 'TEMPLATE_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* templateUpdate(action) {
	try {
		yield axiosPut(`/api/internal/template/${action.id}`, action.template);
		yield put({
			type: 'TEMPLATE_UPDATE_SUCCESS',
		});
		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		});
	} catch (e) {
		yield put({
			type: 'TEMPLATE_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* templateDelete(action) {
	try {
		yield axiosDelete(`/api/internal/template/${action.id}`);
		yield put({
			type: 'TEMPLATE_DELETE_SUCCESS',
		});
		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'template.deleted'
		});
	} catch (e) {
		yield put({
			type: 'TEMPLATE_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* templateFetchCreationInfo() {
	try {
		const {data} = yield axiosGet('/api/internal/template/creation-info');
		yield put({
			type: 'TEMPLATE_FETCH_CREATION_INFO_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'TEMPLATE_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

/*** USER *************************************************************************************************************/

function* userFetchOverviewList(action) {
	try {
		const {data: {list, count}} = yield axiosPost('/api/internal/user/overview', action.request);
		yield put({
			type: 'USER_FETCH_OVERVIEW_LIST_SUCCESS',
			list,
			count,
		});
	} catch (e) {
		yield put({
			type: 'USER_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* userExportOverview() {
	try {
		const result = yield axiosGet('/api/internal/user/overview-export', 'blob');
		const fileName = result.headers['content-disposition']?.replace('attachment; filename=', '') || 'user-export.csv';
		saveAs(result.data, fileName);
		yield put({
			type: 'USER_SUCCESS'
		});
	} catch (e) {
		yield put({
			type: 'USER_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}


function* userCreate(action) {
	try {
		const {data} = yield axiosPost(`/api/internal/user`, action.request);
		yield put({
			type: 'USER_CREATE_SUCCESS',
			createdUser: data,
		});

		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		});
	} catch (e) {
		yield put({
			type: 'USER_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* userDelete(action) {
	try {
		yield axiosDelete(`/api/internal/user/${action.userId}`);
		yield put({
			type: 'USER_SUCCESS',
		});

		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'user.deleted'
		});
	} catch (e) {
		yield put({
			type: 'USER_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* userRegisterGuest(action) {
	try {
		yield axiosPost(`/api/internal/user/${action.userId}/register`);
		yield put({
			type: 'USER_SUCCESS',
		});

		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		});
	} catch (e) {
		yield put({
			type: 'USER_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* userResendInvitation(action) {
	try {
		yield axiosPost(`/api/internal/user/${action.userId}/resend-invitation`);
		yield put({
			type: 'USER_SUCCESS',
		});
	} catch (e) {
		yield put({
			type: 'USER_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* userFetchSettings(action) {
	try {
		const {data} = yield axiosGet(`/api/internal/user/${action.userId}/settings`);
		yield put({
			type: 'USER_FETCH_SETTINGS_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'USER_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* userUpdateSettings(action) {
	try {
		yield axiosPut(`/api/internal/user/${action.userId}/settings`, action.settings);
		yield put({
			type: 'USER_SUCCESS',
		});

		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		});
	} catch (e) {
		yield put({
			type: 'USER_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* userUpdateVisualSignature(action) {
	try {
		if (!!action.fileSize && action.fileSize > MAX_FILE_SIZE) {
			yield put({
				type: 'USER_ERROR',
				signatureUpdateError: 'IMAGE_INVALID_SIZE'
			});
			return;
		}

		yield axiosPost(`/api/internal/user/${action.userId}/visual-signature`, {
			visualSignature: action.visualSignature,
			mimeType: action.mimeType
		});
		yield put({
			type: 'USER_UPDATE_VISUAL_SIGNATURE_SUCCESS',
			visualSignature: action.visualSignature,
		});

		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		});
	} catch (e) {
		yield put({
			type: 'USER_ERROR',
			signatureUpdateError: yield extractServerError(e)
		});
	}
}


/*** USER GROUP *******************************************************************************************************/

function* userGroupFetchOverviewList(action) {
	try {
		const {data: {list, count}} = yield axiosPost('/api/internal/usergroup/overview', action.request);
		yield put({
			type: 'USERGROUP_FETCH_OVERVIEW_LIST_SUCCESS',
			list,
			count,
		});
	} catch (e) {
		yield put({
			type: 'USERGROUP_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* userGroupCreate(action) {
	try {
		const {data: {id}} = yield axiosPost(`/api/internal/usergroup`, action.request);
		yield put({
			type: 'USERGROUP_CREATE_SUCCESS',
			createdUserGroupId: id,
		});

		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		});
	} catch (e) {
		yield put({
			type: 'USERGROUP_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* userGroupDelete(action) {
	try {
		yield axiosDelete(`/api/internal/usergroup/${action.userGroupId}`);
		yield put({
			type: 'USERGROUP_DELETE_SUCCESS',
		});

		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'userGroup.deleted'
		});
	} catch (e) {
		yield put({
			type: 'USERGROUP_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* userGroupFetchSettings(action) {
	try {
		const {data} = yield axiosGet(`/api/internal/usergroup/${action.userGroupId}/settings`);
		yield put({
			type: 'USERGROUP_FETCH_SETTINGS_SUCCESS',
			data
		});
	} catch (e) {
		yield put({
			type: 'USERGROUP_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* userGroupUpdateSettings(action) {
	try {
		yield axiosPut(`/api/internal/usergroup/${action.userGroupId}/settings`, action.settings);
		yield put({
			type: 'USERGROUP_UPDATE_SETTINGS_SUCCESS',
		});

		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		});
	} catch (e) {
		yield put({
			type: 'USERGROUP_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* userGroupFetchUserList(action) {
	try {
		const {
			data: {
				list,
				count
			}
		} = yield axiosPost(`/api/internal/usergroup/${action.userGroupId}/user/list`, action.request);
		yield put({
			type: 'USERGROUP_FETCH_USER_LIST_SUCCESS',
			list,
			count,
		});
	} catch (e) {
		yield put({
			type: 'USERGROUP_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* userGroupFetchPossibleUserList(action) {
	try {
		const {data: {list, count}} = yield axiosPost('/api/internal/usergroup/possible-users', action.request);
		yield put({
			type: 'USERGROUP_FETCH_POSSIBLE_USER_LIST_SUCCESS',
			list,
			count,
		});
	} catch (e) {
		yield put({
			type: 'USERGROUP_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* userGroupAddUsers(action) {
	try {
		yield axiosPost(`/api/internal/usergroup/${action.userGroupId}/user`, {userIds: action.userIds});

		yield put({
			type: 'USERGROUP_ADD_USERS_SUCCESS'
		});

		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		});
	} catch (e) {
		yield put({
			type: 'USERGROUP_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* userGroupRemoveUser(action) {
	try {
		yield axiosDelete(`/api/internal/usergroup/${action.userGroupId}/user/${action.userId}`);

		yield put({
			type: 'USERGROUP_REMOVE_USER_SUCCESS'
		});

		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		});
	} catch (e) {
		yield put({
			type: 'USERGROUP_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}

function* userGroupUserUpdateSettings(action) {
	try {
		// update user setting and take in account the user might have removed his own access to the folder
		const {data: {loggedInUserCanEditUserGroup}} = yield axiosPut(`/api/internal/usergroup/${action.userGroupId}/user/${action.userId}`, {admin: action.admin});

		yield put({
			type: 'SNACKBAR_OPEN',
			messageKey: 'changes.saved'
		});

		if (loggedInUserCanEditUserGroup) {
			yield put({
				type: 'USERGROUP_USER_UPDATE_SETTINGS_SUCCESS'
			});
		} else {
			// user no longer has access to the group, redirect to index
			yield put(push('/'));
		}
	} catch (e) {
		yield put({
			type: 'USERGROUP_ERROR',
			serverError: yield extractServerError(e)
		});
	}
}