import React, {Component} from "react";
import {connect} from "react-redux";
import {
	Alert,
	Autocomplete,
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	LinearProgress,
	Link,
	Step,
	StepContent,
	StepLabel,
	Stepper,
	TextField,
	Typography
} from "@mui/material";
import {withTranslation} from "react-i18next";
import LoadingComponent from "../common/LoadingComponent";
import ServerErrorComponent from "../common/ServerErrorComponent";
import ImageReader from "../../img/reader.png";
import {IS_MOBILE_DEVICE, SIGNATURE_TYPES} from "../common/Constants";
import EidPinInput from "../common/EidPinInput";
import VisualSignatureOrParaphComponent from "../common/VisualSignatureOrParaphComponent";
import {DataGridPro as DataGrid} from "@mui/x-data-grid-pro";
import mwPinInputEn from "../../img/mw-pin-input-en.png";
import mwPinInputNl from "../../img/mw-pin-input-nl.png";
import mwPinInputFr from "../../img/mw-pin-input-fr.png";
import i18n from "i18next";
import AddIcon from "@mui/icons-material/Add";
import {v4 as uuidv4} from "uuid";

const flowStates = {
	CHOOSE_DOCUMENTS: 0,
	CHOOSE_TYPE: 1,
	ACQUIRE_LOCK: 2,
	CAPACITIES: 3,
	SIGNATURE_DETAILS: 4,
	SIGNING: 5,
}

const defaultState = {
	flowState: flowStates.CHOOSE_TYPE,
	pin: '',
	otpNumberCheck: '',
	otpChallenge: '',
	smsOtpSent: false,
	smsOtpAttempt: 0,
	timeoutSmsOtpValue: null,
	declineReason: '',
	itsmeUserCodeForgotten: false,
	itsmeLinkUserCode: false,
	capacities: [],
	emailOtpSent: false,
	declineAttachment: null,
	declineAttachmentId: null,
	declineAttachmentUploadError: null,
	selectedDocumentIds: []
}

const ITSME_BULK_SIGN_MAX_DOCUMENTS = 70;

const EXTRACT_SELECTED_DOCUMENTS = (signingDocumentsToSign, selectedDocumentIds) => {
	return signingDocumentsToSign.filter(doc => selectedDocumentIds.filter(id => id === doc.signRequestId) > 0)
};

class SigningDialog extends Component {

	constructor(props) {
		super(props);
		this.state = {
			...defaultState,
			...(this.deriveDocumentData())
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			const documentData = this.deriveDocumentData();
			const flowState = documentData.allowPartialCollectionDocumentHandling ? flowStates.CHOOSE_DOCUMENTS : flowStates.CHOOSE_TYPE;
			this.setState({
				...defaultState,
				...documentData,
				flowState,
			}, () => flowState === flowStates.CHOOSE_TYPE ? this.onDocumentsSelected() : null);

			if (!this.props.declining) {
				this.props.onEidStartMiddleware();
			}
		}

		if (!this.props.open && prevProps.open) {
			if (!!this.timeout) {
				clearTimeout(this.timeout);
				this.timeout = null;
			}
			if (!!this.timeoutSmsOtp) {
				clearTimeout(this.timeoutSmsOtp);
				this.timeoutSmsOtp = null;
			}
			this.props.onEidStopMiddleware();
			this.props.onSigningExtendLocksStop();
		}
		if (this.props.signingRequestsLockedState === 'ACQUIRED' && prevProps.signingRequestsLockedState !== 'ACQUIRED') {
			this.setState({
				flowState: this.state.shouldHandleCapacities ? flowStates.CAPACITIES : flowStates.SIGNATURE_DETAILS
			});
			if (this.timeout) {
				clearTimeout(this.timeout);
			}
			this.timeout = setTimeout(() => {
				this.timeout = null;
				if (this.props.open) {
					this.onClose();
				}
			}, 120000);
			this.props.onSigningExtendLocksStart(this.state.signatureType);
		}
		if (this.props.signingRequestsLockedGuestAccessExpired &&
			this.props.signingRequestsLockedState === 'FAILED' && prevProps.signingRequestsLockedState !== 'FAILED') {
			this.props.onClose(true);
		}
		if (this.props.signingRequestsCompletedState !== 'INIT' && prevProps.signingRequestsCompletedState === 'INIT') {
			if (this.state.flowState === flowStates.SIGNING && 'ALL' === this.props.signingRequestsCompletedState) {
				this.onCompleted();
			}
		}
		if (!!this.props.signingAbortedReason && !prevProps.signingAbortedReason) {
			if (this.state.flowState === flowStates.SIGNING) {
				if ('PIN_WRONG' === this.props.signingAbortedReason ||
					'PIN_TIMEOUT_OR_CANCEL' === this.props.signingAbortedReason ||
					'ERROR' === this.props.signingAbortedReason) {
					// go back
					this.setState({flowState: flowStates.SIGNATURE_DETAILS});
				}
			}
		}
		if (this.props.signingServerError && !prevProps.signingServerError) {
			if (this.state.flowState === flowStates.SIGNATURE_DETAILS &&
				this.state.signatureType === 'SMS_OTP' &&
				(this.props.signingServerError === 'SMS_SEND' || this.props.signingServerError === 'SIGNING_OTP_NUMBER_CHECK_FAILED')) {
				if (!!this.timeoutSmsOtp) {
					clearTimeout(this.timeoutSmsOtp);
					this.timeoutSmsOtp = null;
				}
				this.setState({
					timeoutSmsOtpValue: null
				});
			}
		}
	}

	deriveDocumentData = () => {
		// derive some data for ez access
		const reasons = [];
		let reasonDisabledForSomeDocuments = false;
		let showRemark = false;
		let remarkDisabledForSomeDocuments = false;
		const isRegisteredUserSigning = !this.props.sessionInfo.kioskUserId || this.props.sessionInfo.kioskUserId === this.props.sessionInfo.userId;
		const selectedDocumentIds = this.props.signingDocumentsToSign.filter(doc => doc.signRequestId > 0).map(doc => doc.signRequestId) || [];
		const allowPartialCollectionDocumentHandling = this.props.signingData?.documents.some(doc => doc.allowPartialCollectionDocumentHandling) && this.props.signingDocumentsToSign.length > 1;

		(this.props.signingData?.documents || []).forEach(doc => {
			if (doc.reasonActive) {
				const legalNotice = doc.reasonLegalNoticeMandatory || false;
				const index = reasons.findIndex(reason =>
					reason.legalNotice === legalNotice &&
					(!legalNotice || reason.legalNoticeText === (doc.reasonLegalNoticeText || '')));
				if (index >= 0) {
					reasons[index].linkedSignRequests.push({id: doc.signRequestId, name: doc.name});
				} else {
					reasons.push({
						id: reasons.length,
						value: '',
						legalNotice,
						legalNoticeText: (doc.reasonLegalNoticeText || ''),
						meetsRequirements: !legalNotice, // an empty reason doesn't meet the requirements if we have a mandatory legal notice,
						linkedSignRequests: [{id: doc.signRequestId, name: doc.name, guestKey: doc.guestKey}]
					});
				}
			}
			reasonDisabledForSomeDocuments = reasonDisabledForSomeDocuments || !doc.reasonActive;
			showRemark = showRemark || doc.remarkActive
			remarkDisabledForSomeDocuments = remarkDisabledForSomeDocuments || !doc.remarkActive;
		});

		return {
			reasons,
			reasonDisabledForSomeDocuments,
			showRemark,
			remarkDisabledForSomeDocuments,
			allowPartialCollectionDocumentHandling,
			selectedDocumentIds,
			remark: isRegisteredUserSigning ? this.props.sessionInfo?.defaultRemark || '' : ''
		};
	}

	onDocumentsSelected = () => {
		const isRegisteredUserSigning = !this.props.sessionInfo.kioskUserId || this.props.sessionInfo.kioskUserId === this.props.sessionInfo.userId;
		const selectedDocuments = EXTRACT_SELECTED_DOCUMENTS(this.props.signingDocumentsToSign, this.state.selectedDocumentIds);
		this.setState({
			shouldHandleCapacities: (() => {
				if (isRegisteredUserSigning && this.props.sessionInfo.capacities.length > 0) {
					return true;
				}
				const docIds = [];
				for (let doc of selectedDocuments) {
					if (!!doc.capacityTitle) {
						return true;
					}
					if (docIds.indexOf(doc.id) >= 0) {
						return true;
					}
					docIds.push(doc.id);
				}
				return false;
			})(),
			capacities: selectedDocuments.map(doc => ({
				signRequestId: doc.signRequestId,
				title: doc.capacityTitle,
				// search in the users' capacities for remark
				remark: ((isRegisteredUserSigning && this.props.sessionInfo.capacities
					.find(search => search.title === doc.capacityTitle) || {})).remark || ''
			}))
		});

		if (this.props.declining) {
			this.onChooseSignatureType('DECLINE', selectedDocuments);
		} else {
			// determine all types for all documents
			const types = this.props.signingDocumentsToSign
				.reduce((acc, doc) => {
					doc.signatureTypes.forEach(type => {
						if (acc.indexOf(type) === -1) {
							acc.push(type)
						}
					});
					return acc;
				}, []);

			// auto select if there is only one (and we support it)
			if (types.length === 1 && (types[0] !== 'BELGIAN_EID' || !IS_MOBILE_DEVICE)) {
				this.onChooseSignatureType(types[0], selectedDocuments);
			}
		}
	}

	render() {
		const canSignOrDecline =
			// we need to be in signature details
			this.state.flowState >= flowStates.SIGNATURE_DETAILS &&
			// the reasons should be ok
			(this.state.signatureType === 'DECLINE' ||
				this.state.reasons
					// only the reasons for the locked documents are relevant
					.filter(reason => reason.linkedSignRequests.some(sr => this.props.signingRequestsLocked.indexOf(sr.id) >= 0))
					.every(reason => reason.meetsRequirements)) &&
			(this.state.signatureType !== 'DECLINE' ||
				(!!this.state.declineReason)) &&
			// eid
			(this.state.signatureType !== 'BELGIAN_EID' ||
				(this.props.eidInserted && ((this.state.pin.length >= 4 && this.state.pin.length <= 12)
					|| (this.props.eidExternalPinPad || this.props.eidUseMiddlewarePinInput)))) &&
			// smsOtp
			((this.state.signatureType !== 'SMS_OTP' && this.state.signatureType !== 'EMAIL_OTP') ||
				(this.state.otpChallenge.length === 6)) &&
			(this.state.signatureType !== 'HANDWRITTEN' ||
				!!this.props.visualSignature?.data)
		;

		let dialogTitle;
		if (this.props.declining && this.state.selectedDocumentIds.length === 1) {
			dialogTitle = 'signing.declineHeaderSingle';
		} else if (this.props.declining && this.state.selectedDocumentIds.length > 1) {
			dialogTitle = 'signing.declineHeaderMultiple';
		} else if (!this.props.declining && this.state.selectedDocumentIds.length === 1) {
			dialogTitle = 'signing.signHeaderSingle';
		} else {
			dialogTitle = 'signing.signHeaderMultiple';
		}

		return <Dialog open={this.props.open} onClose={this.onClose} onKeyUp={this.onKeyUp} fullWidth maxWidth="lg">
			<DialogTitle>{this.props.t(dialogTitle)}</DialogTitle>
			<DialogContent>
				<Stepper activeStep={-1} orientation="vertical">
					{/* document selection */}
					{this.state.allowPartialCollectionDocumentHandling && this.renderDocumentSelection()}
					{/* type */}
					{this.state.flowState >= flowStates.CHOOSE_TYPE && !this.props.declining && this.renderTypes()}
					{/* lock */}
					{this.state.flowState >= flowStates.ACQUIRE_LOCK && this.renderLock()}
					{/* capacity */}
					{this.state.flowState >= flowStates.CAPACITIES &&
						this.state.shouldHandleCapacities && this.renderCapacities()}
					{/* signature details */}
					{this.state.flowState >= flowStates.SIGNATURE_DETAILS &&
						this.state.signatureType === 'BELGIAN_EID' && this.renderEidMiddlewareAndCard()}
					{this.state.flowState >= flowStates.SIGNATURE_DETAILS &&
						this.state.signatureType === 'ITSME' && this.renderItsmeDetails()}
					{this.state.flowState >= flowStates.SIGNATURE_DETAILS &&
						this.state.signatureType === 'HANDWRITTEN' && this.renderHandwrittenSignatureDetails()}
					{this.state.flowState >= flowStates.SIGNATURE_DETAILS &&
						this.state.signatureType !== 'DECLINE' && this.state.reasons.length > 0 && this.renderReasons()}
					{this.state.flowState >= flowStates.SIGNATURE_DETAILS &&
						this.state.signatureType !== 'DECLINE' && this.state.showRemark && this.renderRemark()}
					{this.state.flowState >= flowStates.SIGNATURE_DETAILS &&
						this.state.signatureType === 'BELGIAN_EID' && this.renderEidPin()}
					{this.state.flowState >= flowStates.SIGNATURE_DETAILS &&
						this.state.signatureType === 'SMS_OTP' && this.renderSmsOtpAuthenticate()}
					{this.state.flowState >= flowStates.SIGNATURE_DETAILS &&
						this.state.signatureType === 'SMS_OTP' && this.renderSmsOtpChallenge()}
					{this.state.flowState >= flowStates.SIGNATURE_DETAILS &&
						this.state.signatureType === 'EMAIL_OTP' && this.renderEmailOtpChallenge()}
					{this.state.flowState >= flowStates.SIGNATURE_DETAILS &&
						this.state.signatureType === 'DECLINE' && this.renderDecline()}
					{/* signing */}
					{this.state.flowState >= flowStates.SIGNING && this.renderSigning()}
				</Stepper>
			</DialogContent>
			<DialogActions>
				{this.state.flowState <= flowStates.SIGNING &&
					<Button variant="outlined"
							onClick={this.onClose}
							disabled={this.props.signingBusy}
							id="btn-signing-sign-cancel"
					>
						{this.props.t('cancel')}
					</Button>
				}
				{(this.state.flowState === flowStates.CAPACITIES) &&
					<Button variant="contained"
							disabled={this.props.signingBusy}
							onClick={this.onContinueCapacities}
							id="btn-signing-sign-continue">
						{this.props.t('signing.continue')}
					</Button>
				}
				{(this.state.flowState === flowStates.CHOOSE_DOCUMENTS) &&
					<Button variant="contained"
							disabled={this.props.signingBusy || this.state.selectedDocumentIds.length === 0}
							onClick={this.onContinueDocumentsSelected}
							id="btn-signing-sign-continue">
						{this.props.t('signing.continue')}
					</Button>
				}
				{this.state.flowState === flowStates.SIGNATURE_DETAILS &&
					<Button variant="contained"
							disabled={!canSignOrDecline || this.props.signingBusy}
							onClick={this.onUploadAndSign}
							id="btn-signing-sign-complete">
						{this.props.t(this.state.signatureType === 'DECLINE' ? 'signing.decline' : 'signing.sign')}
					</Button>
				}
			</DialogActions>
		</Dialog>
	}

	renderDocumentSelection = () => {
		const columns = [
			{
				field: 'name',
				headerName: this.props.t('signing.documentName'),
				editable: false,
				flex: 1
			}
		]

		const rows = this.props.signingDocumentsToSign;

		return <Step active={this.state.flowState === flowStates.CHOOSE_DOCUMENTS}>
			<StepLabel>{this.props.t('signing.documents')}</StepLabel>
			<StepContent>
				<Typography variant="body2">{this.props.t('signing.documentsInfo').replace('{0}', this.props.t(this.props.declining ? 'signing.documentsInfoDecline' : 'signing.documentsInfoSign'))}</Typography>

				<DataGrid
					autoPageSize
					disableColumnSelector
					disableColumnFilter
					disableColumnReorder
					disableColumnMenu
					pagination
					checkboxSelection
					rowSelectionModel={this.state.selectedDocumentIds}
					onRowSelectionModelChange={this.onChangeSelectedDocumentIds}

					columns={columns}

					getRowId={(row) => row.signRequestId}
					rows={rows}
					density="compact"

					sx={{height: '400px', mt: 1}}
					onCellKeyDown={(params, events) => events.stopPropagation()}
				/>
			</StepContent>
		</Step>
	}


	renderTypes = () => {
		const entries = [];
		let hasMultipleDocsInSameType = false;

		const selectedDocuments = EXTRACT_SELECTED_DOCUMENTS(this.props.signingDocumentsToSign, this.state.selectedDocumentIds);
		// add the real signature types
		Object.keys(SIGNATURE_TYPES).forEach(type => {
			const entry = {type, docs: []};
			selectedDocuments.forEach(doc => {
				if (doc.signatureTypes.indexOf(type) >= 0) {
					hasMultipleDocsInSameType = hasMultipleDocsInSameType || entry.docs.some(search => search.id === doc.id);
					entry.docs.push(doc);
				}
			});
			if (entry.docs.length > 0) {
				entries.push(entry);
			}
		});

		// Verify if there are different signing options between documents
		const differentSigningOptions = entries.some(entry => entry.docs.length !== selectedDocuments.length);

		return <Step active={this.state.flowState === flowStates.CHOOSE_TYPE}>
			<StepLabel>
				{
					this.state.flowState > flowStates.CHOOSE_TYPE ?
						(this.props.t('signing.signingMethodChoose') + ': ' + this.props.t('signing.signingMethod_' + this.state.signatureType))
						:
						this.props.t('signing.signingMethodChoose')
				}
			</StepLabel>
			<StepContent>
				<Box sx={{display: 'flex', flexDirection: 'column', gap: 2, justifyContent: 'center'}}>
					{differentSigningOptions && <Alert severity="info">{this.props.t('signing.signingMethodDiffers')}</Alert>}
					{entries.map(entry => {
						const disabledType = entry.type === 'BELGIAN_EID' && IS_MOBILE_DEVICE;
						const itsmeBulk = (entry.type === 'ITSME' && entry.docs.length > ITSME_BULK_SIGN_MAX_DOCUMENTS); // limit is only for qualified signing
						const itsmeNoSameDocuments = ((entry.type === 'ITSME' || entry.type === 'ITSME_ADVANCED') && hasMultipleDocsInSameType);
						const documentName = entry.docs.reduce((acc, doc) => {
							if (acc.findIndex(search => search.id === doc.id) === -1) {
								acc.push(doc);
							}
							return acc;
						}, []).map(doc => doc.name).join(', ');
						return <Box key={entry.type} sx={{display: 'flex', fontSize: 'small', alignItems: 'center'}}>
							<Box sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'flex-start',
								flexBasis: 300,
								flexShrink: 1,
							}}>
								<Box sx={{width: '35px'}}>
									<img src={SIGNATURE_TYPES[entry.type].img} style={{width: '30px', height: 'auto'}}/>
								</Box>
								<Box
									sx={{textAlign: 'center'}}>
									{this.props.t('signing.signingMethod_' + entry.type)}
								</Box>
							</Box>
							<Box sx={{
								ml: 1,
								display: 'flex',
								flexWrap: 'wrap',
								flexGrow: 1,
								justifyContent: 'flex-end'
							}}>
								<Box sx={{flexGrow: 1, flexShrink: 10000}}>
									{disabledType && this.props.t('signing.signingMethodDisabledDevice')}
									{itsmeBulk && this.props.t('signing.signingMethodNoBulkSigning')}

									{!disabledType && !itsmeBulk && <Box>{documentName}</Box>}

									{itsmeNoSameDocuments &&
										<Box>{this.props.t('signing.signingMethodNoSameDocuments')}</Box>}
								</Box>
								<Box>
									<Button
										variant="contained"
										size="small"
										onClick={() => this.onChooseSignatureType(entry.type, entry.docs)}
										disabled={disabledType}
										id={"btn-signing-sign-select-" + entry.type}>
										{this.props.t('select')}
									</Button>
								</Box>
							</Box>
						</Box>
					})}
				</Box>
			</StepContent>
		</Step>
	}

	renderLock = () => {
		return <Step active={this.state.flowState === flowStates.ACQUIRE_LOCK}>
			<StepLabel>{this.props.t(this.state.selectedDocumentIds.length > 1 ? 'signing.acquiringLockMultiple' : 'signing.acquiringLockSingle')}</StepLabel>
			<StepContent>
				{this.props.signingRequestsLockedState === 'FAILED' &&
					<Alert severity="error">{this.props.t('signing.acquiringLockFailed')}</Alert>
				}
				{this.props.signingRequestsLockedState === 'QUEUED' && <>
					<Box>
						<Typography variant="body2">{this.props.t('signing.acquiringLockQueued')}</Typography>
						<Typography variant="body2">{this.props.t('signing.acquiringLockQueuedPosition') + ' ' + this.props.signingRequestsLockedPositionInQueue}</Typography>
					</Box>
				</>}
				<ServerErrorComponent serverError={this.props.signingServerError}/>
			</StepContent>
		</Step>
	}

	renderCapacities = () => {
		const columns = [
			{
				field: 'name',
				headerName: this.props.t('signing.capacitiesDocumentName'),
				editable: false,
				flex: 0.5
			},
			{
				field: 'pageIndex',
				headerName: this.props.t('signing.capacitiesPage'),
				editable: false,
				flex: 0.3,
				valueGetter: (data) => data.value + 1
			},
			{
				field: 'capacityTitle',
				headerName: this.props.t('signing.capacitiesSelectedTitle'),
				editable: false,
				flex: 1,
				renderCell: (cellValues) => {
					const value = (this.state.capacities
							.find(search => search.signRequestId === cellValues.row.signRequestId)
					)?.title || '';
					return <Autocomplete
						id={"input-signing-sign-capacity-title-select-" + cellValues.row.signRequestId}
						freeSolo
						fullWidth
						options={this.props.sessionInfo.capacities.map(capacity => capacity.title)}
						inputValue={value}
						onInputChange={(e, newValue) => this.onChangeCapacityTitle(cellValues.row.signRequestId, newValue)}
						onKeyDown={(event) => event.stopPropagation()}
						renderInput={(params) =>
							<TextField
								{...params}
								variant="standard"
							/>
						}
					/>
				}
			},
			{
				field: 'capacityRemark',
				headerName: this.props.t('signing.capacitiesSelectedRemark'),
				editable: false,
				flex: 1,
				renderCell: (cellValues) => {
					const capacity = (this.state.capacities
						.find(search => search.signRequestId === cellValues.row.signRequestId));
					return !!capacity.title && <TextField
						id={"input-signing-sign-capacity-remark-text-" + cellValues.row.signRequestId}
						fullWidth
						value={capacity?.remark || ''}
						onChange={e => this.onChangeCapacityRemark(cellValues.row.signRequestId, e.target.value)}
						onKeyDown={(event) => event.stopPropagation()}
						variant="standard"
						autoComplete="off"
					/>
				}
			}
		]

		const rows = EXTRACT_SELECTED_DOCUMENTS(this.props.signingDocumentsToSign, this.state.selectedDocumentIds)
			.filter(doc => this.props.signingRequestsLocked.indexOf(doc.signRequestId) >= 0);

		return <Step active={this.state.flowState === flowStates.CAPACITIES}>
			<StepLabel>{this.props.t('signing.capacities')}</StepLabel>
			<StepContent>
				<Typography variant="body2">{this.props.t('signing.capacitiesInfo')}</Typography>
				<DataGrid
					autoPageSize
					disableColumnSelector
					disableColumnFilter
					disableRowSelectionOnClick
					pagination

					columns={columns}

					getRowId={(row) => row.signRequestId}
					rows={rows}
					density="compact"

					sx={{height: '400px', mt: 1}}
					isRowSelectable={() => false}
					onCellKeyDown={(params, events) => events.stopPropagation()}
				/>
			</StepContent>
		</Step>
	}

	renderEidMiddlewareAndCard = () => {
		return <Step active={this.state.flowState === flowStates.SIGNATURE_DETAILS}>
			<StepLabel>{this.props.t('signing.eidCardHeader')}</StepLabel>
			<StepContent>
				{this.props.eidRunState === 'UNKNOWN' && <LoadingComponent/>}
				{this.props.eidRunState === 'RUNNING' && <>
					<img src={ImageReader}/>
					{this.props.eidInserted ?
						<Alert severity="info">{this.props.t('signing.eidCardFound')}</Alert>
						:
						<Alert severity="warning">{this.props.t('signing.eidCardNotFound')}</Alert>}
				</>}
				{this.props.eidRunState === 'NOT_RUNNING' && <>
					<Alert severity="info">
						<Box>{this.props.t('signing.eidMiddlewareNotRunning')}</Box>
						{this.props.eidVersions.length > 0 && <div>
							<br/>
							{this.props.t('signing.eidMiddlewareDownloadHere')}
							<ul>
								{this.props.eidVersions.map((v, index) => <li key={index}><a
									href={v.download_url}>{v.os}</a></li>)}
							</ul>
						</div>}
					</Alert>
				</>}
			</StepContent>
		</Step>
	}

	renderEidPin = () => {
		return <Step active={this.state.flowState === flowStates.SIGNATURE_DETAILS}>
			<StepLabel>{this.props.t('signing.eidEnterPin')}</StepLabel>
			<StepContent>
				{!this.props.eidExternalPinPad && !this.props.eidUseMiddlewarePinInput &&
					<EidPinInput
						id="input-signing-sign-eid-text"
						wrongPin={this.props.signingAbortedReason === 'PIN_WRONG'}
						maxPinLength={12}
						onPinChanged={(pin) => this.setState({pin})}
						onEnter={this.onEidTrySign}
					/>}

				{this.props.eidExternalPinPad &&
					<Alert severity="warning">{this.props.t('signing.eidExternalPinPad')}</Alert>}

				{!this.props.eidExternalPinPad && this.props.eidUseMiddlewarePinInput && <Box>
					<Typography variant="body2">{this.props.t('signing.eidExternalPinDialog')}</Typography>
					<img
						src={(() => {
							switch (i18n.resolvedLanguage) {
								case 'nl':
									return mwPinInputNl;
								case 'fr':
									return mwPinInputFr;
								case 'en':
								default:
									return mwPinInputEn;
							}
						})()}

						alt="PIN input"
						style={{border: '1px solid #000'}}
					/></Box>}

				{this.props.signingAbortedReason === 'PIN_WRONG' &&
					<Alert severity="error" sx={{mt: 1}}>{this.props.t('signing.eidWrongPin')}</Alert>}
				{this.props.signingAbortedReason === 'PIN_TIMEOUT_OR_CANCEL' &&
					<Alert severity="error" sx={{mt: 1}}>{this.props.t('signing.eidPinTimeoutOrCancel')}</Alert>}
				{this.props.signingAbortedReason === 'ERROR' &&
					<Alert severity="error" sx={{mt: 1}}>{this.props.t('signing.eidSignError')}</Alert>}
			</StepContent>
		</Step>
	}

	renderItsmeDetails = () => {
		return <Step active={this.state.flowState === flowStates.SIGNATURE_DETAILS}>
			<StepLabel>{this.props.t('signing.itsmeSigningLabel')}</StepLabel>
			<StepContent>
				<Typography variant="body2">
					{this.props.t('signing.itsmeSigningDetails').split('$').map((v, index) => {
						if (v.indexOf('link') === 0) {
							const elements = v.split(';');
							return <Link key={index} target="_blank" href={elements[1]}>{elements[2]}</Link>
						} else {
							return <span key={index}>{v}</span>
						}
					})}
				</Typography>

				{!this.props.kioskMode && this.props.signingData?.itsmeUserCodePresent && !this.state.itsmeUserCodeForgotten && <>
					<Typography variant="body2"
								sx={{mt: 1}}>{this.props.t('signing.itsmeForgetUserCodeDetails')}</Typography>
					<Button variant="contained"
							color="secondary"
							onClick={this.onForgetItsmeIdentity}
							disabled={this.props.signingBusy || this.props.sessionBusy}
							size="small"
							id="btn-signing-sign-forget-itsme"
					>
						{this.props.t('signing.itsmeForgetUserCode')}
					</Button>
				</>}

				{!this.props.kioskMode && (!this.props.signingData?.itsmeUserCodePresent || this.state.itsmeUserCodeForgotten) && <>
					<Typography variant="body2"
								sx={{mt: 1}}>{this.props.t('signing.itsmeLinkUserCodeDetails')}</Typography>
					<FormControlLabel
						control={
							<Checkbox checked={this.state.itsmeLinkUserCode}
									  onChange={this.onChangeItsmeLinkUserCode}
									  sx={{p: 0}}
							/>
						}
						label={<Typography variant="body2">{this.props.t('signing.itsmeLinkUserCode')}</Typography>}
						disabled={this.props.signingBusy}
						sx={{m: 0}}
					/>
				</>}

			</StepContent>
		</Step>
	}

	renderSmsOtpAuthenticate = () => {
		const otpNumberVerificationFailed = this.props.signingServerError === 'SIGNING_OTP_NUMBER_CHECK_FAILED';
		const resendAllowed = !this.state.timeoutSmsOtpValue || otpNumberVerificationFailed;

		return <Step active={this.state.flowState === flowStates.SIGNATURE_DETAILS}>
			<StepLabel>{this.props.t('signing.smsOtpAuthenticate')}</StepLabel>
			<StepContent>
				<Typography variant="body2">{this.props.t('signing.smsOtpAuthenticateDescription')}</Typography>
				<Box sx={{display: 'flex', alignItems: 'center', mt: 1}}>
					<TextField
						required
						label={this.props.t('signing.smsOtpAuthenticateLabel')}
						inputProps={{maxLength: 4}}
						id="input-signing-sign-smsotp-auth-text"
						value={this.state.otpNumberCheck}
						onChange={this.onChangeOtpNumberCheck}
						autoComplete="off"
						size="small"
						sx={{flexGrow: 1, mr: 1}}
					/>
					<Button variant="contained"
							color="secondary"
							onClick={this.onSmsOtpAuthenticate}
							disabled={this.props.signingBusy || this.state.otpNumberCheck.length != 4 || !resendAllowed}
							id="btn-signing-sign-smsotp-auth"
					>
						{this.state.smsOtpSent && this.props.t('signing.smsOtpAuthenticateSendAgain') + (!!this.state.timeoutSmsOtpValue ? ` (${this.state.timeoutSmsOtpValue})` : '')}
						{!this.state.smsOtpSent && this.props.t('signing.smsOtpAuthenticateSend')}
					</Button>
				</Box>
				<ServerErrorComponent serverError={this.props.signingServerError}/>
			</StepContent>
		</Step>
	}

	renderSmsOtpChallenge = () => {
		return <Step active={this.state.flowState === flowStates.SIGNATURE_DETAILS}>
			<StepLabel>{this.props.t('signing.smsOtpChallenge')}</StepLabel>
			<StepContent>
				<TextField
					fullWidth
					required
					label={this.props.t('signing.smsOtpChallengeLabel')}
					id="input-signing-sign-smsotp-challenge-text"
					inputProps={{maxLength: 6}}
					value={this.state.otpChallenge}
					onChange={this.onChangeOtpChallenge}
					autoComplete="off"
					disabled={!this.state.smsOtpSent}
					size="small"
				/>
			</StepContent>
		</Step>
	}

	renderEmailOtpChallenge = () => {
		return <Step active={this.state.flowState === flowStates.SIGNATURE_DETAILS}>
			<StepLabel>{this.props.t('signing.emailOtpChallenge')}</StepLabel>
			<StepContent>
				<Box sx={{display: 'flex', alignItems: 'center', mt: 1, gap: 1}}>
					{this.state.emailOtpSent && <TextField
						required
						label={this.props.t('signing.emailOtpChallengeLabel')}
						id="input-signing-sign-emailotp-challenge-text"
						inputProps={{maxLength: 6}}
						value={this.state.otpChallenge}
						onChange={this.onChangeOtpChallenge}
						autoComplete="off"
						size="small"
						sx={{flexGrow: 1}}
					/>}
					<Button variant="contained"
							color="secondary"
							onClick={this.onEmailOtpAuthenticate}
							disabled={this.props.signingBusy}
							id="btn-signing-sign-smsotp-auth"
					>
						{this.props.t(this.state.emailOtpSent ? 'signing.emailOtpAuthenticateSendAgain' : 'signing.emailOtpAuthenticateSend')}
					</Button>
				</Box>
				<ServerErrorComponent serverError={this.props.signingServerError}/>
			</StepContent>
		</Step>
	}

	renderHandwrittenSignatureDetails = () => {
		return <Step active={this.state.flowState === flowStates.SIGNATURE_DETAILS}>
			<StepLabel>{this.props.t('signing.handwrittenSignature')}</StepLabel>
			<StepContent>
				<Box sx={{display: 'flex', flexWrap: 'wrap', flexDirection: 'column'}}>
					<VisualSignatureOrParaphComponent type="signature"
													  visualSignature={this.props.visualSignature.data}
													  onChangeVisualSignature={this.props.onChangeVisualSignature}/>
					{!this.props.kioskMode && !!this.props.visualSignature.overwrite && !!this.props.visualSignature.data && <FormControlLabel
						control={
							<Checkbox checked={this.props.visualSignature.saveAsDefault}
									  onChange={this.props.onChangeSaveSignatureAsDefault}
									  sx={{p: 0}}
							/>
						}
						label={<Typography
							variant="body2">{this.props.t('signing.visualSignatureSaveAsDefault')}</Typography>}
						disabled={this.props.signingBusy}
						sx={{m: 0}}
					/>}
				</Box>
			</StepContent>
		</Step>
	}

	renderDecline = () => {
		return <Step active={this.state.flowState === flowStates.SIGNATURE_DETAILS}>
			<StepLabel>{this.props.t(this.state.selectedDocumentIds.length > 1 ? 'signing.declineInfoMultiple' : 'signing.declineInfoMultiple')}</StepLabel>
			<StepContent>
				<Typography variant="body2">{this.props.t(this.state.selectedDocumentIds.length > 1 ? 'signing.declineInfoDetailsMultiple' : 'signing.declineInfoDetailsSingle')}</Typography>
				<Typography variant="body2">{this.props.t(this.state.selectedDocumentIds.length > 1 ? 'signing.declineReasonDetailsMultiple' : 'signing.declineReasonDetailsSingle')}</Typography>
				<TextField
					required
					fullWidth
					label={this.props.t('signing.declineReasonLabel')}
					id="input-signing-decline-reason-text"
					multiline
					value={this.state.declineReason}
					onChange={this.onDeclineReasonChange}
					autoComplete="off"
					size="small"
					sx={{mt: 1, mb: 1}}
				/>
				{!!this.state.declineAttachmentUploadError &&
					<Alert severity="error" sx={{mb: 1}}>{this.props.t(this.state.declineAttachmentUploadError === 'serverError.DOCUMENT_INVALID_CONTENTS' ? 'signing.declineReasonUploadError' : this.state.declineAttachmentUploadError)}</Alert>}
				<Box sx={{display: 'flex', justifyContent: 'flex-start'}}>
					<Button
						variant='contained'
						startIcon={<AddIcon/>}
						onClick={this.onSelectFiles}
						id='btn-upload-select'
					>
						{this.props.t('signing.declineAttachment')}
					</Button>
					{!!this.state.declineAttachment?.name &&
						<Typography sx={{pl: 2, pt: 1}}>
							{this.state.declineAttachment.name}
						</Typography>}
				</Box>
			</StepContent>
			<input type='file' id='file'
				   accept='application/pdf,image/png,image/jpeg,.doc,.docx,.xls*'
				   style={{display: 'none'}}
				   onChange={this.onChangeAttachment}/>
		</Step>;
	}

	renderReasons = () => {
		const signingRequestsLocked = this.props.signingRequestsLocked;
		const reasonsWithLegalNotice = this.state.reasons
			.filter(reason => reason.linkedSignRequests.some(sr => signingRequestsLocked.indexOf(sr.id) >= 0))
			.filter(reason => reason.legalNotice && reason.legalNoticeText.length > 0)
			.sort((r1, r2) => r2.linkedSignRequests.length - r1.linkedSignRequests.length);
		const reasonsWithLegalNoticeNoText = this.state.reasons
			.filter(reason => reason.linkedSignRequests.some(sr => signingRequestsLocked.indexOf(sr.id) >= 0))
			.filter(reason => reason.legalNotice && reason.legalNoticeText.length === 0)
			.sort((r1, r2) => r2.linkedSignRequests.length - r1.linkedSignRequests.length);
		const reasonsNoLegalNotice = this.state.reasons
			.filter(reason => reason.linkedSignRequests.some(sr => signingRequestsLocked.indexOf(sr.id) >= 0))
			.filter(reason => !reason.legalNotice)
			.sort((r1, r2) => r2.linkedSignRequests.length - r1.linkedSignRequests.length);

		return <Step active={this.state.flowState === flowStates.SIGNATURE_DETAILS}>
			<StepLabel>{this.props.t('signing.reasonHeader')}</StepLabel>
			<StepContent>
				{reasonsWithLegalNotice.length > 0 && reasonsWithLegalNotice.map(reason => <Box key={reason.id}>
					<Typography variant="body2">
						{this.props.t('signing.reasonLegalNoticeMandatoryText') +
							(signingRequestsLocked.length > 1 ?
								(': ' + reason.linkedSignRequests.filter(sr => signingRequestsLocked.indexOf(sr.id) >= 0).map(sr => sr.name).join(', ')) : '')
						}
					</Typography>
					<Typography variant="body2" color="red">
						{this.props.t('signing.reasonLegalNoticeText') + ': ' + reason.legalNoticeText}
					</Typography>
					{this.renderReasonInputField(reason)}
				</Box>)}

				{reasonsWithLegalNoticeNoText.length > 0 && reasonsWithLegalNoticeNoText.map(reason => <Box
					key={reason.id}>
					<Typography variant="body2">
						{this.props.t('signing.reasonLegalNoticeMandatory') +
							(signingRequestsLocked.length > 1 ?
								(':' + reason.linkedSignRequests.filter(sr => signingRequestsLocked.indexOf(sr.id) >= 0).map(sr => sr.name).join(', ')) : '')
						}
					</Typography>
					{this.renderReasonInputField(reason)}
				</Box>)}

				{reasonsNoLegalNotice.length > 0 && reasonsNoLegalNotice.map(reason => <Box key={reason.id}>
					<Typography variant="body2">
						{this.props.t('signing.reasonOptional') +
							(signingRequestsLocked.length > 1 ?
								(': ' + reason.linkedSignRequests.filter(sr => signingRequestsLocked.indexOf(sr.id) >= 0).map(sr => sr.name).join(', ')) : '')
						}
					</Typography>
					{this.renderReasonInputField(reason)}
				</Box>)}

				{this.state.reasonDisabledForSomeDocuments &&
					<Alert severity="warning">{this.props.t('signing.reasonDisabledForSomeDocumentsContent')}</Alert>}
			</StepContent>
		</Step>;
	}

	renderReasonInputField = (reason) => {
		return <Box>
			<TextField
				fullWidth
				required={reason.legalNotice}
				label={this.props.t(reason.legalNotice ? 'signing.reasonLegalNoticeLabel' : 'signing.reasonLabel')}
				inputProps={{maxLength: 500}}
				value={reason.value}
				onChange={(e) => this.onReasonChange(e.target.value, reason.id)}
				autoComplete="off"
				size="small"
				sx={{mt: 1, ...(!reason.meetsRequirements && {backgroundColor: "#FFDDDD"})}}
				id="input-signing-sign-reason-text"
			/>
			<Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
				<Typography variant="body2">{reason.value.length + "/500"}</Typography>
			</Box>
		</Box>
	}

	renderRemark = () => {
		return <Step active={this.state.flowState === flowStates.SIGNATURE_DETAILS}>
			<StepLabel>{this.props.t('signing.remarkHeader')}</StepLabel>
			<StepContent>
				<Typography variant="body2">{this.props.t('signing.remarkOptional')}</Typography>

				<TextField
					fullWidth
					label={this.props.t('signing.remarkLabel')}
					inputProps={{maxLength: 50}}
					value={this.state.remark}
					onChange={this.onRemarkChange}
					autoComplete="off"
					size="small"
					sx={{mt: 1}}
					id="input-signing-sign-remark-text"
				/>
				<Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
					<Typography variant="body2">{this.state.remark.length + "/50"}</Typography>
				</Box>

				{this.state.remarkDisabledForSomeDocuments &&
					<Alert severity="warning">{this.props.t('signing.remarkDisabledForSomeDocumentsContent')}</Alert>}
			</StepContent>
		</Step>
	}

	renderSigning = () => {
		const signingServerError = this.props.signingServerError;
		const itsmeSignatureType = this.state.signatureType === 'ITSME' || this.state.signatureType === 'ITSME_ADVANCED';
		return <Step active={this.state.flowState === flowStates.SIGNING}>
			<StepLabel>{this.props.t(this.state.signatureType === 'DECLINE' ? 'signing.decliningBusy' : 'signing.signingBusy')}</StepLabel>
			<StepContent>
				{itsmeSignatureType && <>
					<Typography variant="body2" sx={{mb: 1}}>{this.props.t('signing.itsmeDirections')}</Typography>
					{!signingServerError && <LinearProgress variant="indeterminate" sx={{width: '100%'}}/>}
				</>}

				{!itsmeSignatureType && !signingServerError && <>
					<LinearProgress variant="determinate"
									value={100 * this.props.signingRequestsCompleted.length / this.props.signingRequestsLocked.length}
									sx={{width: '100%'}}/>
				</>}

				{!!signingServerError && <ServerErrorComponent serverError={signingServerError}/>}
			</StepContent>
		</Step>
	}

	onChangeSelectedDocumentIds = (selectedIds) => {
		const addedId = selectedIds.filter(id => !this.state.selectedDocumentIds.includes(id))[0];
		if (!!addedId) {
			// addition
			const selectedDocument = this.props.signingDocumentsToSign.find(doc => addedId === doc.signRequestId);
			if (selectedDocument.allowPartialCollectionDocumentHandling) {
				this.setState({selectedDocumentIds: this.state.selectedDocumentIds.concat(addedId)});
			} else {
				const entireDocumentCollectionIds = this.props.signingDocumentsToSign.filter(doc => doc.collectionParentId === selectedDocument.collectionParentId).map(doc => doc.signRequestId);
				this.setState({selectedDocumentIds: this.state.selectedDocumentIds.concat(entireDocumentCollectionIds)});
			}
		} else {
			// removal
			const removedId = this.state.selectedDocumentIds.filter(id => !selectedIds.includes(id))[0];
			const selectedDocument = this.props.signingDocumentsToSign.find(doc => removedId === doc.signRequestId);

			if (selectedDocument.allowPartialCollectionDocumentHandling) {
				this.setState({selectedDocumentIds: this.state.selectedDocumentIds.filter(id => id !== removedId)});
			} else {
				const entireDocumentCollectionIds = this.props.signingDocumentsToSign.filter(doc => doc.collectionParentId === selectedDocument.collectionParentId).map(doc => doc.signRequestId);
				this.setState({selectedDocumentIds: this.state.selectedDocumentIds.filter(id => !entireDocumentCollectionIds.includes(id))});
			}
		}
	}

	onChooseSignatureType = (signatureType, docs) => {
		const itsme = signatureType === 'ITSME';
		const signRequestIds = docs
			// for itsme make the docs unique
			.reduce((acc, doc) => {
				if (!itsme || acc.findIndex(search => search.id === doc.id) === -1) {
					acc.push(doc);
				}
				return acc;
			}, [])
			// map to signrequest
			.map(doc => doc.signRequestId)
			// filter out max ITSME_BULK_SIGN_MAX_DOCUMENTS for itsme
			.slice(0, itsme ? ITSME_BULK_SIGN_MAX_DOCUMENTS : docs.length)
		;

		this.setState({signatureType, flowState: flowStates.ACQUIRE_LOCK},
			() => this.props.onSigningAcquireLocks(signRequestIds, signatureType));
	}

	onChangeCapacityTitle = (signRequestId, title) => {
		const defaultCapacity = this.props.sessionInfo.capacities.find(capacity => capacity.title === title)

		const capacities = this.state.capacities
			.map(capacity => capacity.signRequestId === signRequestId ? {
				...capacity,
				title,
				...(!!defaultCapacity && {remark: defaultCapacity.remark})
			} : capacity);
		this.setState({capacities});
	}

	onChangeCapacityRemark = (signRequestId, remark) => {
		const capacities = this.state.capacities
			.map(capacity => capacity.signRequestId === signRequestId ? {
				...capacity,
				remark
			} : capacity);
		this.setState({capacities});
	}

	onContinueDocumentsSelected = () => {
		this.setState({flowState: flowStates.CHOOSE_TYPE}, () => this.onDocumentsSelected());
	}

	onContinueCapacities = () => {
		this.setState({flowState: flowStates.SIGNATURE_DETAILS});
	}

	onReasonChange = (value, id) => {
		const reasons = [...this.state.reasons];
		const reason = {...reasons[id]};
		reason.value = value || '';
		reason.meetsRequirements =
			!reason.legalNotice ||
			(reason.value.trim().length > 0 && reason.legalNoticeText.length === 0) ||
			(reason.value.length > 0 && reason.legalNoticeText.toLowerCase() === value.toLowerCase());
		reasons[id] = reason;
		this.setState({reasons});
	}

	onRemarkChange = (e) => {
		const remark = e.target.value;
		this.setState({remark});
	}

	onChangeOtpNumberCheck = (e) => {
		const otpNumberCheck = e.target.value;
		this.setState({otpNumberCheck});
	}

	onChangeOtpChallenge = (e) => {
		const otpChallenge = e.target.value;
		this.setState({otpChallenge});
	}

	onSmsOtpAuthenticate = () => {
		// clear any existing timer
		if (!!this.timeoutSmsOtp) {
			clearTimeout(this.timeoutSmsOtp);
			this.timeoutSmsOtp = null;
		}

		// allow a resend after 40s
		this.timeoutSmsOtp = setInterval(() => {
			if (this.state.timeoutSmsOtpValue > 0) {
				this.setState((prevState) => ({
					timeoutSmsOtpValue: prevState.timeoutSmsOtpValue - 1
				}));
			} else {
				this.setState({
					timeoutSmsOtpValue: null
				});
			}
		}, 1000);



		const attempt = this.state.smsOtpAttempt;
		this.setState({
			timeoutSmsOtpValue: 40,
			smsOtpSent: true,
			smsOtpAttempt: attempt + 1
		}, () => this.props.onSigningOtpAuthenticate({
			signRequestIds: this.props.signingRequestsLocked,
			otpMethod: 'SMS',
			otpNumberCheck: this.state.otpNumberCheck,
			attempt,
		}));
	}

	onEmailOtpAuthenticate = () => {
		this.setState({emailOtpSent: true}, () => this.props.onSigningOtpAuthenticate({
			signRequestIds: this.props.signingRequestsLocked,
			otpMethod: 'EMAIL',
		}));
	}

	onDeclineReasonChange = (e) => {
		const declineReason = e.target.value;
		this.setState({declineReason});
	}

	onForgetItsmeIdentity = () => {
		this.setState({itsmeUserCodeForgotten: true}, this.props.onSessionForgetItsmeIdentity);
	}

	onChangeItsmeLinkUserCode = (e, checked) => {
		this.setState({itsmeLinkUserCode: checked});
	}

	onEidTrySign = () => {
		if (this.props.eidInserted && (this.state.pin.length >= 4 && this.state.pin.length <= 12)) {
			this.onSign();
		}
	}

	onUploadAndSign = () => {
		if (!!this.state.declineAttachment) {
			this.setState({flowState: flowStates.SIGNING}, () => this.onUploadDeclineAttachment(this.state.declineAttachment));
		} else {
			this.onSign();
		}
	}

	onUploadDeclineAttachment = (item) => {
		const xhr = new XMLHttpRequest();

		xhr.onreadystatechange = () => {
			if (xhr.readyState === 4) {
				if (xhr.status >= 200 && xhr.status < 300) {
					if (!xhr.responseText) {
						this.props.onSessionDestroy();
						return this.setState({
							flowState: flowStates.SIGNATURE_DETAILS,
							declineAttachment: null,
							declineAttachmentId: null,
							declineAttachmentUploadError: null,
						});
					}

					const preparedDocument = JSON.parse(xhr.responseText);
					if (!!preparedDocument?.id && preparedDocument.id !== 0) {
						this.setState({
							declineAttachment: null,
							declineAttachmentId: preparedDocument.id,
							declineAttachmentUploadError: null,
						}, () => this.onSign());
					} else {
						this.setState({
							flowState: flowStates.SIGNATURE_DETAILS,
							declineAttachment: null,
							declineAttachmentId: null,
							declineAttachmentUploadError: !!preparedDocument?.error ? 'serverError.' + preparedDocument.error : "upload.unknownError",
						});
					}
				} else {
					this.setState({
						declineAttachment: null,
						declineAttachmentId: null,
						declineAttachmentUploadError: null,
					});
				}
			}
		};

		xhr.open('POST', '/api/internal/document/upload', true);
		xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

		const formData = new FormData();
		formData.append('files', item.file, encodeURIComponent(item.name));
		formData.append('attachmentUsage', "DECLINE");

		xhr.send(formData);
	}

	onSign = () => {
		this.setState({flowState: flowStates.SIGNING}, () => this.props.onSigningSignStart({
			signatureType: this.state.signatureType,
			signRequestIds: this.props.signingRequestsLocked,

			signRequestFormFields: this.props.signRequestFormFields,

			reasons: this.state.reasons,
			remark: this.state.remark,
			capacities: this.state.capacities,

			// eid specific stuff
			pin: this.state.pin,
			eidCard: this.props.eidCard,

			// itsme stuff
			itsmeLinkUserCode: this.state.itsmeLinkUserCode,

			// otp specific stuff
			otpChallenge: this.state.otpChallenge,

			// decline specific stuff
			declineReason: this.state.declineReason,
			declineAttachmentId: this.state.declineAttachmentId,

			// visual signatures
			visualSignature: this.props.visualSignature,
			visualParaph: this.props.visualParaph,
		}));
	}

	onClose = (e, reason) => {
		if (reason === 'backdropClick') {
			return;
		}
		this.props.onClose();
	}

	onCompleted = () => {
		let dialogTitle;
		if (this.props.declining && this.props.signingRequestsCompleted.length === 1) {
			dialogTitle = 'signing.documentsDeclinedSingle';
		} else if (this.props.declining && this.props.signingRequestsCompleted.length > 1) {
			dialogTitle = 'signing.documentsDeclinedMultiple';
		} else if (!this.props.declining && this.props.signingRequestsCompleted.length === 1) {
			dialogTitle = 'signing.documentsSignedSingle';
		} else {
			dialogTitle = 'signing.documentsSignedMultiple';
		}

		this.props.onSnackbarOpen(this.props.t(dialogTitle)
			.replace('{0}', this.props.signingRequestsCompleted.length));
		this.props.onCompleted();
	}

	onSelectFiles = (e) => {
		document.getElementById('file').click();
	}

	onChangeAttachment = (e) => {
		const el = document.getElementById('file');
		const file = el.files[0];
		const item = {
			id: uuidv4(),
			file,
			name: file.name,
			size: file.size,
			type: file.type,
		}
		this.setState({
			declineAttachment: item
		}, () => el.value = '')
	}
}

export default withTranslation()(connect(
	state => {
		return {
			signingBusy: state.signing.busy,
			signingServerError: state.signing.serverError,
			signingData: state.signing.data,
			signingDocumentsToSign: (state.signing.data?.documents || [])
				.filter(doc => doc.signRequestState === 'WAITING_FOR_SIGNATURE'),
			signingRequestsLocked: state.signing.requestsLocked,
			signingRequestsLockedState: state.signing.requestsLockedState,
			signingRequestsLockedGuestAccessExpired: state.signing.requestsLockedGuestAccessExpired,
			signingRequestsCompleted: state.signing.requestsCompleted,
			signingRequestsCompletedState: state.signing.requestsCompletedState,
			signingRequestsLockedPositionInQueue: state.signing.requestsLockedPositionInQueue,
			signingAbortedReason: state.signing.abortedReason,

			eidRunState: state.eid.runState,
			eidVersions: state.eid.versions,
			eidInserted: state.eid.inserted,
			eidExternalPinPad: state.eid.externalPinPad,
			eidUseMiddlewarePinInput: state.session.info.useEidMiddlewarePinInput,
			eidCard: state.eid.card,

			sessionBusy: state.session.busy,
			sessionInfo: state.session.info,
		}
	},
	dispatch => {
		return {
			onSigningAcquireLocks: (signRequestIds, signatureType) => {
				dispatch({
					type: 'SIGNING_ACQUIRE_LOCKS',
					signRequestIds,
					signatureType
				});
			},
			onSigningExtendLocksStart: (signatureType) => {
				dispatch({
					type: 'SIGNING_EXTEND_LOCKS_START',
					signatureType
				});
			},
			onSigningExtendLocksStop: () => {
				dispatch({
					type: 'SIGNING_EXTEND_LOCKS_STOP',
				});
			},
			onSigningSignStart: (args) => {
				dispatch({
					type: 'SIGNING_SIGN_START',
					...args
				});
			},
			onSigningOtpAuthenticate: (args) => {
				dispatch({
					type: 'SIGNING_OTP_AUTHENTICATE',
					...args
				});
			},
			onEidStartMiddleware: () => {
				dispatch({
					type: 'EID_START_MIDDLEWARE',
				});
			},
			onEidStopMiddleware: () => {
				dispatch({
					type: 'EID_STOP_MIDDLEWARE',
				});
			},
			onSessionForgetItsmeIdentity: () => {
				dispatch({
					type: 'SESSION_FORGET_ITSME_IDENTITY',
				})
			},
			onSnackbarOpen: (message) => {
				dispatch({
					type: 'SNACKBAR_OPEN',
					message
				})
			},
			onSessionDestroy: () => {
				dispatch({
					type: 'SESSION_DESTROY'
				})
			},
		}
	}
)(SigningDialog));
