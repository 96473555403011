import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Box, Button, IconButton, MenuItem, Select, Typography} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import LoadingComponent from "../common/LoadingComponent";
import ServerErrorComponent from "../common/ServerErrorComponent";
import {DataGridPro as DataGrid} from "@mui/x-data-grid-pro";
import DeleteIcon from "@mui/icons-material/Delete";
import {v4 as uuidv4} from "uuid";
import AddIcon from "@mui/icons-material/Add";
import CompanyKeyVaultCertificateConfigDialog from "./CompanyKeyVaultCertificateConfigDialog";
import SaveIcon from "@mui/icons-material/Save";

const DEFAULT_QUILL_CERTIFICATE = -1

class CompanySigningCertificateSettingsComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			certificateConfigDialogOpen: false,
		}
	}

	componentDidMount() {
		this.props.onCompanyFetchCertificateSettings();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!prevProps.shouldSave &&
			this.props.shouldSave) {
			this.onSave();
		}
	}

	render() {
		const settings = this.props.companySigningCertificateSettings;
		if (!settings) {
			return <LoadingComponent/>;
		}

		const dataGridColumns = [
			{
				field: 'name',
				headerName: this.props.t('company.certificateSettingsConfigName'),
				editable: false,
				flex: 1
			},
			{
				field: 'vaultUrl',
				headerName: this.props.t('company.certificateSettingsConfigVaultUrl'),
				editable: false,
				flex: 1
			},
			{
				field: 'tenantId',
				headerName: this.props.t('company.certificateSettingsConfigTenantId'),
				editable: false,
				flex: 1
			},
			{
				field: 'clientId',
				headerName: this.props.t('company.certificateSettingsConfigClientId'),
				editable: false,
				flex: 1
			},
			{
				field: 'clientSecret',
				headerName: this.props.t('company.certificateSettingsConfigClientSecret'),
				editable: false,
				flex: 1,
				renderCell: (cellValues) => (<span>{'*'.repeat(!!cellValues.row.clientSecretLength ? cellValues.row.clientSecretLength : cellValues.row.clientSecret?.length)}</span>)
			},
			{
				field: 'certificateName',
				headerName: this.props.t('company.certificateSettingsConfigCertificateName'),
				editable: false,
				flex: 1
			},
			{
				field: 'keyId',
				headerName: this.props.t('company.certificateSettingsConfigKeyId'),
				editable: false,
				flex: 1
			},
			{
				field: 'actions',
				headerName: this.props.t('company.actions'),
				editable: false,
				sortable: false,
				disableColumnMenu: true,
				width: 100,
				renderCell: (cellValues) => (<Fragment>
					<IconButton
						variant="contained"
						color="primary"
						title={this.props.t('company.certificateSettingsConfigEdit')}
						onClick={() => this.onOpenEditCertificateConfigDialog(cellValues.row)}>
						<EditIcon/>
					</IconButton>
					<IconButton
						variant="contained"
						color="primary"
						title={this.props.t('company.certificateSettingsConfigDelete')}
						onClick={() => this.onDeleteCertificateConfig(cellValues.row)}>
						<DeleteIcon/>
					</IconButton>
				</Fragment>)
			}
		];

		return <Box sx={{mt: 2}}>
			<Typography variant="h6">{this.props.t('company.certificateSettingsFor') + ' ' + this.props.sessionInfo.companyName}</Typography>
			<ServerErrorComponent serverError={this.props.companyServerError}/>


			<Box sx={{mt: 2}}>
				<Typography>{this.props.t('company.certificateSettingsInfo')}</Typography>


				<Typography sx={{fontWeight: 700, mt: 2}}>{this.props.t('company.certificateSettingsConfigs')}</Typography>
				<Box sx={{ml: 1}}>
					<DataGrid autoHeight
							  disableColumnSelector
							  disableColumnFilter
							  disableColumnMenu
							  disableRowSelectionOnClick
							  hideFooter

							  columns={dataGridColumns}

							  getRowId={row => row.id || row.internalId}

							  rows={settings.companyKeyVaultCertificateConfigs}
							  rowCount={!!settings.companyKeyVaultCertificateConfigs ? settings.companyKeyVaultCertificateConfigs.length : 0}
							  density="compact"/>

					<Box sx={{mt: 1, display: 'flex', justifyContent: 'flex-end'}}>
						<Button
							variant="contained"
							color="secondary"
							onClick={this.onOpenAddCertificateConfigDialog}
							startIcon={<AddIcon/>}
							id="btn-company-certificateconfig-settings-add"
						>
							{this.props.t('company.certificateSettingsConfigAdd')}
						</Button>
					</Box>
				</Box>


				<Typography sx={{fontWeight: 700}}>{this.props.t('company.certificateSettingsConfigUsage')}</Typography>
				<Box sx={{ml: 1}}>
					<Typography sx={{mt: 1}}>{this.props.t('company.certificateSettingsGuestUserCertificate')}</Typography>

					<Select
						size="small"
						value={!settings.guestUserKeyVaultCertificateId ? DEFAULT_QUILL_CERTIFICATE : settings.guestUserKeyVaultCertificateId}
						onChange={this.onChangeGuestUserKeyVaultCertificate}
					>
						{[{
							id: DEFAULT_QUILL_CERTIFICATE,
							name: this.props.t('company.certificateSettingsDefaultConfig')
						}]
							.concat(settings.companyKeyVaultCertificateConfigs)
							.map(config =>
								<MenuItem key={config.id}
										  value={!!config.id ? config.id : config.internalId}>{config.name}</MenuItem>
							)}
					</Select>


					<Typography sx={{mt: 2}}>{this.props.t('company.certificateSettingsRegisteredUserCertificate')}</Typography>
					<Select
						size="small"
						value={!settings.registeredUserKeyVaultCertificateId ? DEFAULT_QUILL_CERTIFICATE : settings.registeredUserKeyVaultCertificateId}
						onChange={this.onChangeRegisteredUserKeyVaultCertificate}
					>
						{[{
							id: DEFAULT_QUILL_CERTIFICATE,
							name: this.props.t('company.certificateSettingsDefaultConfig')
						}]
							.concat(settings.companyKeyVaultCertificateConfigs)
							.map(config =>
								<MenuItem key={config.id}
										  value={config.id}>{config.name}</MenuItem>
							)}
					</Select>
				</Box>


				<Box sx={{ mt: 2 }}>
					<Button variant="contained"
							onClick={this.onSave}
							startIcon={<SaveIcon/>}
							disabled={this.props.companyBusy}
							id="btn-settings-save">{this.props.t('save')}</Button>
				</Box>

			</Box>

			<CompanyKeyVaultCertificateConfigDialog
				open={this.state.certificateConfigDialogOpen}
				current={this.state.selectedCertificateConfig}
				onClose={this.onCloseCertificateConfigDialog}
				onConfirm={this.onChangeCertificateConfig}
			/>

		</Box>
	}

	onOpenEditCertificateConfigDialog = (certificateConfig) => {
		this.setState({selectedCertificateConfig: certificateConfig, certificateConfigDialogOpen: true});
	}

	onOpenAddCertificateConfigDialog = () => {
		this.setState({selectedCertificateConfig: null, certificateConfigDialogOpen: true});
	}

	onCloseCertificateConfigDialog = () => {
		this.setState({certificateConfigDialogOpen: false});
	}

	onChangeGuestUserKeyVaultCertificate = (e) => {
		this.props.onCompanyChangeCertificateSettings('guestUserKeyVaultCertificateId', e.target.value);
	}

	onChangeRegisteredUserKeyVaultCertificate = (e) => {
		this.props.onCompanyChangeCertificateSettings('registeredUserKeyVaultCertificateId', e.target.value);
	}

	onChangeCertificateConfig = (selectedCertificateConfig) => {
		const certificateConfigs = [...this.props.companySigningCertificateSettings.companyKeyVaultCertificateConfigs];

		if (!!selectedCertificateConfig.id || !!selectedCertificateConfig.internalId) {
			const index = certificateConfigs.findIndex(search => search.id === selectedCertificateConfig.id);
			if (index < 0) {
				return;
			}

			// update
			certificateConfigs[index] = selectedCertificateConfig;
		} else {
			// set temporary id to identify the config in the UI when no persisted id has been set
			selectedCertificateConfig.internalId = uuidv4();
			// new
			certificateConfigs.push(selectedCertificateConfig);
		}

		this.setState({certificateConfigDialogOpen: false}, () => {
			this.props.onCompanyChangeCertificateSettings('companyKeyVaultCertificateConfigs', certificateConfigs);
		});
	}

	onDeleteCertificateConfig =  (selectedCertificateConfig) => {
		const certificateConfigs = [...this.props.companySigningCertificateSettings.companyKeyVaultCertificateConfigs];
		const index = certificateConfigs.findIndex(search =>
			search.id === selectedCertificateConfig.id || (!selectedCertificateConfig.id && search.internalId === selectedCertificateConfig.internalId));
		if (index >= 0) {
			certificateConfigs.splice(index, 1);
			this.props.onCompanyChangeCertificateSettings('companyKeyVaultCertificateConfigs', certificateConfigs);
		}

		const configId = !!selectedCertificateConfig.id ? selectedCertificateConfig.id : selectedCertificateConfig.internalId;

		// note: since the certificateId might be a long (persisted) or a string (newly created), we explicitely don't check on the type here
		if (this.props.companySigningCertificateSettings.registeredUserKeyVaultCertificateId == configId) {
			this.props.onCompanyChangeCertificateSettings('registeredUserKeyVaultCertificateId', DEFAULT_QUILL_CERTIFICATE);
		}
		if (this.props.companySigningCertificateSettings.guestUserKeyVaultCertificateId == configId) {
			this.props.onCompanyChangeCertificateSettings('guestUserKeyVaultCertificateId', DEFAULT_QUILL_CERTIFICATE);
		}
	}

	onSave = () => {
		const settings = {...this.props.companySigningCertificateSettings};
		if (settings.guestUserKeyVaultCertificateId === DEFAULT_QUILL_CERTIFICATE) {
			settings.guestUserKeyVaultCertificateId = null;
		}
		if (settings.registeredUserKeyVaultCertificateId === DEFAULT_QUILL_CERTIFICATE) {
			settings.registeredUserKeyVaultCertificateId = null;
		}

		this.props.onCompanyUpdateCertificateSettings(settings);
	}
}

export default withTranslation()(connect(
	state => {
		return {
			sessionInfo: state.session.info,
			sessionBusy: state.session.busy,
			pendingChanges: state.session.pendingChanges,

			companyBusy: state.company.busy,
			companyServerError: state.company.serverError,
			companySigningCertificateSettings: state.company.signingCertificateSettings,
		}
	},
	dispatch => {
		return {
			onCompanyFetchCertificateSettings: () => {
				dispatch({
					type: 'COMPANY_FETCH_SIGNING_CERTIFICATE_SETTINGS'
				});
			},
			onCompanyUpdateCertificateSettings: (settings) => {
				dispatch({
					type: 'COMPANY_UPDATE_SIGNING_CERTIFICATE_SETTINGS',
					settings
				});
			},
			onCompanyChangeCertificateSettings: (key, value) => {
				dispatch({
					type: 'COMPANY_CHANGE_SIGNING_CERTIFICATE_SETTINGS',
					key, value
				});
			},
		}
	}
)(CompanySigningCertificateSettingsComponent));
