export default {
	lang: 'français',
	search: 'Chercher',
	previous: 'Précédent',
	next: 'Suivant',
	cancel: 'Annuler',
	select: 'Sélectionner',
	confirm: 'Confirmer',
	close: 'Fermer',
	ok: 'OK',
	back: 'Retour',
	save: 'Enregistrer',
	page: 'Page',
	attention: 'Attention!',
	columns: 'Colonnes',
	export: 'Exporter',
	filter: 'Filtre',
	clear: 'Effacer',
	edit: 'Modifier',
	new: 'Nouvel',
	delete: 'Supprimer',
	resetColumnsWidth: 'Réinitialiser la largeur des colonnes',

	session: {
		admin: 'Gestionnaire de',
		user: 'Utilisateur de',
		loggedInAs: 'Utilisateur authentifié comme',
		becomeRegisteredUser: 'Devenir un utilisateur enregistré',
		becomeRegisteredUserDetails: 'Voulez-vous convertir votre accès comme visiteur en compte enregistré? L\'access comme visiteur via les liens, que vous avez reçu précédemment, ne fonctionnera plus. Vous pouvez à nouveau consulter les documents après enregistrement et connexion. Après confirmation vous recevrez un e-mail d\'invitation et serez redirigé vers la page de connexion. Veuillez suivre les instructions dans l\'e-mail d\'invitation pour compléter l\'enregistrement.',
		welcome: 'Bienvenu(e)',
		login: 'Connecter',
		email: 'E-mail',
		password: 'Mot de passe',
		alternativeLogins: 'OU',
		loginMethod_EID: 'eID belge',
		resetPassword: 'Modifié votre mot de passe',
		resetPasswordUpdateSuccess: 'Mise à jour du mot de passe a réussi, cliquez sur \'Suivant\' pour continuer à l\'écran de connexion...',
		forgotPassword: 'Mot de passe oublié?',
		forgotPasswordSuccess: 'Si $email appartient à un utilisateur enregistré, un e-mail contenant des instructions supplémentaires sera envoyé à cette adresse.',
		forgotPasswordSuccessContinue: 'Cliquez sur \'Suivant\' pour continuer à l\'écran de connexion.',
		pendingChanges: 'Il y a des changements non sauvegardés, êtes-vous sûr de vouloir quitter cette page?',
		pendingChangesLeave: 'Quitter la page, ignorer les changements',
		pendingChangesSaveAndLeave: 'Enregister et quitter la page',
		versionOutOfSync: 'Une nouvelle mise à jour de l\'application est maintenant disponible. Cliquez sur \'Actualiser\' pour continuer avec la dernière version.',
		versionOutOfSyncRefresh: 'Actualiser',
	},

	language: {
		en: 'anglais',
		nl: 'néerlandais',
		fr: 'français',
	},

	locale: {
		dateFormat: 'jj/mm/aaaa',
		datetimeFormat: 'jj/mm/aaaa hh:mm'
	},

	notFound: {
		message: 'La page demandée n\'a pu être trouvée'
	},

	changes: {
		saved: 'Changements enregistrés',
		required: 'Ce champ est requis'
	},

	info: {
		manualHeader: 'Manuel',
		manualContent: 'La dernière version du manuel est disponible $link;https://quill.dioss.com/documentation;ici$',
		termsHeader: 'Conditions Générales',
		termsContent: 'Vous trouverez des informations à ce sujet $link;https://smartsolutions.dioss.com/fr/products/quill/general-terms-and-conditions/;ici$.',
		faqHeader: 'FAQ - Foire aux questions',
		faqContent: 'Vous trouverez des informations à ce sujet $link;https://smartsolutions.dioss.com/en/products/quill/FAQ-quill/;ici$.',
		supportHeader: 'Support',
		supportContent: 'Pour assistance, veuillez contacter $mail$',
		gdprHeader: 'RGPD - Règlement Général sur la Protection des Données | Politique de confidentialité',
		gdprContent: 'Vous trouverez des informations à ce sujet $link;https://smartsolutions.dioss.com/fr/products/quill/privacy-statement/;ici$.',
		isoEidasHeader: 'Certifié ISO | Conforme eIDAS',
		isoHeader: 'Est-ce que Dioss Smart Solutions est conforme à la norme ISO27001 et certifié?',
		isoContent: 'ISO27001 est une spécification pour un système de gestion de la sécurité de l\'information. Il s\'agit d\'un cadre de politiques et de procédures qui comprend des contrôles juridiques, physiques et techniques impliqués dans nos processus de gestion des risques liés à l\'information. Nous l\'utilisons pour nous assurer que nous menons nos activités de manière organisée et sécurisée et que nous améliorons continuellement notre approche et notre niveau de sécurité. Nous sommes régulièrement audités par BSI, qui est un organisme de certification accrédité.',
		eidasHeader: 'La plateforme Quill utilise-t-elle des signatures conformes à eIDAS?',
		eidasContent: 'Oui, cette plateforme est conforme à la réglementation européenne eIDAS.',
		platformHeader: 'Version plateforme',
		releaseNotesHeader: 'Notes de mise à jour',
		releaseNotesContent: 'Vous trouverez des informations à ce sujet $link;https://smartsolutions.dioss.com/en/products/quill/release-notes/;ici.',

		tab_GENERAL: 'Général',
		tab_FAQ: 'FAQ',
		tab_VERSION: 'Version'
	},

	application: {
		tab_COMPANY_OVERVIEW: 'Sociétés',
		tab_OIDC_SETTINGS: 'Configurations OIDC',
		tab_TESTS: 'Tester',
		tab_LOGGING: 'Logging',

		testsMail: 'Envoyer un test pour chaque modèle d\'e-mail dans la langue',

		loggingHeader: 'Configurer logging',
		loggingLogger: 'Logger',
		loggingLevel: 'Niveau',
		loggingDelete: 'Supprimer',
		loggingAdd: 'Ajouter',
	},


	tac: {
		accept: 'Acceptez',
		agree: 'J\'ai lu, compris et accepté le suivant:',
		general: '$link;https://smartsolutions.dioss.com/fr/products/quill/general-terms-and-conditions/;Les conditions générales$',
		privacy: '$link;https://smartsolutions.dioss.com/fr/products/quill/privacy-statement/;La déclaration de confidentialité$',
		title: 'Veuillez lire nos conditions et politiques',
	},

	cookie: {
		message: 'Nous utilisons des cookies et des technologies similaires pour offrir la meilleure expérience sur notre site Web. Référez-vous à notre $link;https://smartsolutions.dioss.com/fr/products/quill/quill-cookie-policy/;politique des cookies$ pour plus d\'informations.',
		accept: 'Acceptez',
	},

	outOfOffice: {
		warning: 'Vous êtes actuellement marqué comme absent du bureau',
	},

	company: {
		createName: 'Nom de la société',
		createFinish: 'Tout est complété?',
		createFinishDescription: 'La nouvelle société sera ajoutée à la plateforme.',

		create: 'Entrer une nouvelle société',
		createChild: 'Entrer une nouvelle sous-société',
		header: 'Sociétés',
		companyName: 'Société',
		actions: 'Actions',
		settings: 'Paramètres',
		delete: 'Supprimer',
		deleteConfirm: 'Voulez-vous vraiment supprimer cette entreprise?',
		deleted: 'L\'entreprise a été supprimée',
		child: 'Sous-entreprise',
		parentCompany: 'Société principale',
		switch: 'Changer société active',

		adminSettingsHeader: 'Paramètres pour société',
		adminSettingsInactive: 'Inactif',
		name: 'Nom',

		adminSettingsSignatureTypes: 'Types de signature',
		adminSettingsMisc: 'Paramètres divers',
		adminSettingsPdfRecreatedShown: 'Afficher une notification si la structure du PDF a été corrigée en raison de problèmes d\'incompatibilité',
		adminSettingsDocuments: 'Paramètres du document',
		adminSettingsDocumentEntryDeletionThreshold: 'jour(s) jusqu\'à la suppression définitive des documents (laisser vide par défaut 730 jours)',
		adminSettingsEmail: 'Paramètres d\'e-mail',
		adminSettingsMailboxEnabled: 'Cette société peut créer des boîtes aux lettres par dossier',
		adminSettingsWhitelistedEmailDomains: 'Domaines d\'e-mail autorisés',
		adminSettingsWhitelistedEmailDomainsHelperText: 'Plusieurs domaines peuvent être séparés par , ou ;',
		adminSettingsWhitelistedFrameDomainsDescription: 'Domaines à partir desquels l\'application peut être intégrée dans un cadre',
		adminSettingsWhitelistedFrameDomains: 'Domaines de cadres autorisés',
		adminSettingsWhitelistedFrameDomainsHelperText: 'Plusieurs domaines peuvent être séparés par , ou ;',
		adminSettingsCanManageChildCompanies: 'Cette entreprise peut gérer des sous-entreprises',
		adminSettingsCanRequestSensitiveSignerData: 'Cette entreprise peut demander des données personnelles sensibles pour la validation du signataire.',
		adminSettingsUseEidMiddlewarePinInput: 'Utiliser Dioss eID Middleware pour l\'entrée du code pin eID',
		adminSettingsItsmePartnerCode: 'itsme\u00AE frequent signer code partenaire',
		adminSettingsItsmePartnerCodeOverride: 'Utiliser un code partenaire itsme\u00AE spécifique à l\'entreprise',
		adminSettingsItsmePartnerCodeWarning: 'Attention : le code partenaire itsme\u00AE n\'est utilisé que pour les dossiers de documents dont le paramètre est explicitement activé (désactivé par défaut).',
		adminSettingsFinish: 'Tout est complété?',
		adminSettingsFinishDescription: 'Appuyer sur enregister pour conserver les changements',

		adminSettingsDomain: 'Paramètres du domaine',
		adminSettingsDomainNone: 'Aucun',
		adminSettingsDomainSubdomain: 'Sous-domaine',
		adminSettingsDomainCustomDomain: 'Domaine personnalisé',
		adminSettingsDomainSubdomainExample: 'Échantillon d\'url: ',
		adminSettingsDomainSubdomainValidation: 'Les caractères minuscules, les chiffres, - et _ sont autorisés.',
		adminSettingsDomainCustomDomainValidation: 'Les caractères minuscules, les chiffres, ., - et _ sont autorisés.',

		adminSettingsSignature_ELECTRONIC_WITH_WITNESS_SEAL: 'Autoriser le sceau électronique comme type de signature',
		adminSettingsSignature_BELGIAN_EID: 'Autoriser l\'eID belge comme type de signature',
		adminSettingsSignature_ITSME: 'Autoriser itsme® qualifiée comme type de signature',
		adminSettingsSignature_ITSME_ADVANCED: 'Autoriser itsme® avancée comme type de signature',
		adminSettingsSignature_SMS_OTP: 'Autoriser OTP via SMS comme type de signature',
		adminSettingsSignature_EMAIL_OTP: 'Autoriser OTP via e-mail comme type de signature',
		adminSettingsSignature_HANDWRITTEN: 'Autoriser signature manuscrite élémentaire comme type de signature',

		adminApiSettingsHeader: 'Paramètres de l\'API',
		adminApiSettingsAccess: 'Accès à l\'API',
		adminApiSettingsAccessEnabled: '{0} a accès à l\'API',
		adminApiSettingsAccessDisabled: '{0} n\'a pas accès à l\'API',
		adminApiSettingsDetails: 'Détails de l\'API',
		adminApiSettingsClientId: 'Client id',
		adminApiSettingsClose: 'Fermer',
		adminApiSettingsDisable: 'Désactiver',
		adminApiSettingsEnable: 'Activer',
		adminApiSettingsApiSecretMoved: 'La gestion du clé API a été déplacée dans la section \'clés API\' des paramètres de l\'entreprise. La création, modification et révocation des clés API sont désormais gérées par les administrateurs de l\'entreprise et de l\'application et gérées au nouvel emplacement. L\'accès de l\'entreprise à l\'API en général peut toujours être désactivé ou activé ici, comme auparavant.',

		settingsTab_GENERAL_SETTINGS: 'Général',
		settingsTab_APIKEY_SETTINGS: 'Clés API',
		settingsTab_CERTIFICATE_SETTINGS: 'Certificats',
		settingsTab_EMAIL_SETTINGS: 'E-mail',
		settingsTab_NOTIFICATION_SETTINGS: 'Notifications',
		settingsTab_STYLE_SETTINGS: 'Style d\'entreprise',
		settingsTab_FORMFIELD_SETTINGS: 'Champs',
		settingsTab_OIDC_SETTINGS: 'Configurations OIDC',
		settingsTab_DELEGATES: 'Délégués',
		settingsTab_CONNECTORS: 'Connecteurs',
		settingsTab_WEBHOOK_SETTINGS: 'Configurations webhook',
		settingsTab_SWITCH: 'Mes sociétés',

		generalSettingsValue_ALWAYS_AVAILABLE: 'toujours disponible',
		generalSettingsValue_DEFAULT_AVAILABLE: 'disponible par défaut',
		generalSettingsValue_DEFAULT_UNAVAILABLE: 'non disponible par défaut',
		generalSettingsValue_NEVER_AVAILABLE: 'jamais disponible',
		generalSettingsFor: 'Paramètres généraux pour',
		generalSettingsSigningSettings: 'Paramètres de signature',
		generalSettingsDocumentSettings: 'Paramètres du document',
		generalSettingsSigningMethods: 'Types de signature',
		generalSettingsSigningMethodsHelp: 'Ces paramètres vous permettent de déterminer quels types de signature peuvent être choisis par les éditeurs de documents. \nLe paramètre "toujours disponible" le rend disponible pour tous les documents et ne peut être annulé au niveau du dossier. \nLe paramètre "jamais disponible" le désactive pour tous les documents et ne peut pas être modifié au niveau du dossier. \nLes paramètres "par défaut" déterminent la disponibilité pour les nouveaux dossiers, mais permettent aux paramètres de dossier de la remplacer pour les documents du dossier. \nUne modification de ces paramètres n\'affecte pas les documents déjà envoyés. \nSi un paramètre est désactivé, cela signifie que le type de signature n\'est pas disponible pour votre entreprise.',
		generalSettingsAllowSignature: 'Autoriser $SIGNATURE',
		generalSettingsSignature_ELECTRONIC_WITH_WITNESS_SEAL: 'sceau électronique',
		generalSettingsSignature_BELGIAN_EID: 'eID belge',
		generalSettingsSignature_ITSME: 'itsme\u00AE qualifiée',
		generalSettingsSignature_ITSME_ADVANCED: 'itsme\u00AE avancée',
		generalSettingsSignature_SMS_OTP: 'OTP via SMS',
		generalSettingsSignature_EMAIL_OTP: 'OTP via e-mail',
		generalSettingsSignature_HANDWRITTEN: 'signature manuscrite élémentaire',
		generalSettingsSignatureApplyToFolders: 'Appliquer à tous les dossiers',
		generalSettingsSigningPreferences: 'Préférences de signature',
		generalSettingsSigningPreferencesHelp: 'Ces paramètres vous permettent de déterminer si un signataire peut ajouter une remarque et/ou une raison à sa signature. \nLe paramètre "toujours disponible" le rend disponible pour tous les documents et ne peut pas être annulé au niveau du dossier. \nLe paramètre "jamais disponible" le désactive pour tous les documents et ne peut être remplacé au niveau du dossier. \nLes paramètres "par défaut" déterminent la disponibilité pour les nouveaux dossiers, mais permettent aux paramètres de dossier de remplacer cela pour les documents du dossier.',
		generalSettingsSigningReason: 'Autoriser l\'ajout d\'une raison lors de la signature pour tous les utilisateurs (non représenté visuellement)',
		generalSettingsSigningReasonMandatoryLegalNotice: 'Faites-en une mention légale obligatoire pour que l\'utilisateur doit saisir. Cela apparaîtra également visuellement sur le document.',
		generalSettingsSigningReasonLegalNotice: 'Mention légale',
		generalSettingsSigningRemark: 'Autoriser l\'ajout d\'une remarque lors de la signature pour tous les utilisateurs (imprimé sur le document)',
		generalSettingsSigningAddSigningMethod: 'Ajouter la méthode de signature utilisée à chaque signature',
		generalSettingsSigningAddFullSigningInfo: 'Ajouter les informations complètes sur la signature',
		generalSettingsSigningAddImage: 'Ajouter un nom ou une image manuscrite à la signature visuelle',
		generalSettingsSigningVisualSignatureFont: 'Police de signature visuelle et initiales',
		generalSettingsSigningVisualSignatureFont_INDIEFLOWER: 'Indie Flower',
		generalSettingsSigningVisualSignatureFont_GREATVIBES: 'Great Vibes',
		generalSettingsSigningVisualSignatureFont_QWIGLEY: 'Qwigley',
		generalSettingsSigningVisualSignatureFont_ARTYSIGNATURE: 'Arty Signature',
		generalSettingsSigningVisualSignatureFontPreview: 'Exemple de police',
		generalSettingsApprovalPreferences: 'Préférences d\'approbation',
		generalSettingsApprovalShowSignerInfo: 'Afficher les informations sur les signataires dans le flux d\'approbation',
		generalSettingsApplicationOidcOnlyLogin: 'Connexion uniquement via OIDC',
		generalSettingsUsers: 'Paramètres de l\'utilisateur',
		generalSettingsUsersAllowGuestToBecomeRegistered: 'Permettez les utilisateurs invités à s\'inscire',
		generalSettingsExpiration: 'Expiration automatique des approbations et signatures en attente',
		generalSettingsExpirationEnable: "Activer l\'expiration",
		generalSettingsExpirationDays: "jour(s) avant l\'expiration des approbations et signatures incomplètes",
		generalSettingsDocumentCleanup: 'Suppression automatique des documents',
		generalSettingsAllowFoldersToDefineExpirations: "Autoriser les dossiers à utiliser des paramètres d\'expiration différents",
		generalSettingsAllowDocumentsToDefineExpirations: "Autoriser les documents à utiliser des paramètres d\'expiration différents",
		generalSettingsDocumentCleanupHelp: 'Les documents sont automatiquement supprimés après un certain nombre de jours depuis leur dernière modification.\nLe paramètre pour les documents terminés s\'applique aux documents sans signatures ouvertes ou qui ont été fermés.\nLe paramètre pour les documents inachevés s\'applique aux documents en préparation ou en attente de signatures ou d\'approbations.\nVous pouvez autoriser les dossiers à définir différentes limites pour leurs documents.',
		generalSettingsDocumentCleanupDaysProcessed: 'jour(s) après la suppression d\'un document terminé',
		generalSettingsDocumentCleanupDaysPending: 'jour(s) après la suppression d\'un document inactif inachevé',
		generalSettingsDocumentCleanupAllowFolderSettings: 'Autoriser les dossiers à utiliser des paramètres de suppression différents',
		generalSettingsDescription: 'Description des documents',
		generalSettingsDescriptionMandatory: 'Rendre la description obligatoire',
		generalSettingsDescriptionText: 'Description par défaut',
		generalSettingsDocumentCollectionMarkAsReadMandatory: 'Collections',
		generalSettingsDocumentCollectionMarkAsReadMandatoryInfo: 'Exiger que tous les documents marqués comme lus avant que la signature soit activée',
		generalSettingsForwarding: 'Transmission',
		generalSettingsDocumentForwardingAllowed: 'Autoriser la transmission des demandes de signature',
		generalSettingsDefaultSignatureFieldStyle: 'Style de signature par défaut',
		generalSettingsDownloading: 'Téléchargement',
		generalSettingsSignerNeedsSignatureToDownload: 'Une signature entièrement placée est nécessaire pour que le signataire puisse télécharger un document',

		styleSettingsFor: 'Style d\'entreprise pour',
		styleSettingsPrimary: 'Primaire',
		styleSettingsSecondary: 'Secondaire',
		styleSettingsMainColor: 'Couleur principale',
		styleSettingsContrastColor: 'Couleur de contraste',
		styleSettingsLogo: 'Logo',
		styleSettingsLogoChoose: 'Changer logo',

		emailSettingsFor: 'Paramètres e-mail pour',
		emailSettingsDistributionList: 'Paramètres par défaut pour la liste de distribution après signer',
		emailSettingsDistributionListDescription: 'Destinataires des documents',
		emailSettingsDistributionListEvidenceReportDescription: 'Destinataires des documents et rapport de preuve',
		emailSettingsDistributionListEmails: 'Liste des adresses e-mail séparée par \';\'',

		emailSettingsCommunicationType: 'Type de communication d\'email',
		emailSettingsDescription_DEFAULT_HEADERS: 'Mode alias: utilisez le serveur email de Quill avec l\'adresse d\'email de l\'application par défaut comme address \'from\'. Les adresses \'sender\' et \'reply-to\' peuvent être utilisées (voir \'sender\').',
		emailSettingsDescription_DEFAULT_SPF: 'Mode autorisé SPF: utilisez le serveur email de Quill, mais avec l\'adresse e-mail de l\'expéditeur ou entreprise comme adresse d\'email \'de\'. Attention : pour cela, vous devez configurer des enregistrements DNS ci-dessous et réussir la validation SPF',
		emailSettingsDescription_SENDGRID_CUSTOM: 'SendGrid personnalisée: utilisez le compte SendGrid personnalisé et envoyez de e-mail via cette configuration',
		emailSettingsSendGridServerSettings: 'Paramètres de SendGrid',
		emailSettingsPersonalizeCompany: 'Utilisez l\'adresse e-mail d\'entreprise comme expéditeur',
		emailSettingsPersonalizeCompanyHeaders: '(en tant qu\'en-tête \'sender\' et \'reply-to\')',
		emailSettingsPersonalizeUser: 'Utilisez l\'adresse e-mail d\'utilisateur associé comme expéditeur autant que possible',
		emailSettingsPersonalizeUserHeaders: '(en tant qu\'en-tête \'sender\' et \'reply-to\')',
		emailSettingsSenderSettings: 'Expéditeur',
		emailSettingsServerApplicability: 'Applicabilité',
		emailSettingsServerApplicabilityDescription: 'Détermine la configuration de serveur qui sera utilisée. Si la configuration a définis des domaines spécifiques, elle sera utilisée lors de l\'envoi à partir de ces domaines. Dans les autres cas, c\'est la configuration par défaut qui sera utilisée. Une configuration par défaut est nécessaire.',
		emailSettingsServerSettings: 'Paramètres du serveur',
		emailSettingsServerDelete: 'Supprimer',
		emailSettingsServerEdit: 'Modifier / Valider',
		emailSettingsServerAdd: 'Ajouter',
		emailSettingsServerConnectionInvalid: 'Une ou plusieurs connexions SendGrid ne sont pas valides. Aucun courrier ne sera envoyé via cette ou ces configuration(s) invalide(s). Les connexions invalides sont automatiquement revalidées en arrière-plan ou peuvent être validées manuellement via le dialogue de configuration de SendGrid.',
		emailSettingsServerConnectionValidate: 'Valider la connexion',
		emailSettingsServerConnectionValidate_VALID: 'Connexion valide',
		emailSettingsServerConnectionValidate_INVALID_API_KEY: 'Connexion invalide : clé API invalide',
		emailSettingsServerConnectionValidate_MISSING_MAIL_SEND_PRIVILEGE: 'Connexion invalide : le privilège \'Mail Send\' manque',
		emailSettingsServerValid: 'Validé',
		emailSettingsServerDefault: 'Défaut',
		emailSettingsServerDomains: 'Domaines',
		emailSettingsServerDomainsLabel: 'Liste des domaines séparés par \';\'',
		emailSettingsServerUserName: 'Nom d\'utilisateur',
		emailSettingsServerPassword: 'Mot de passe / clé API',
		emailSettingsAuthenticatedDomainValidation: 'SPF validation',
		emailSettingsAuthenticatedDomainConfiguration: 'Configuration du domain SPF',
		emailSettingsAuthenticatedDomainDialog: 'SPF DNS records',
		emailSettingsAuthenticatedDomainDomain: 'Domain',
		emailSettingsAuthenticatedDomainRecordType: 'Type',
		emailSettingsAuthenticatedDomainRecordName: 'Naam',
		emailSettingsAuthenticatedDomainRecordAliasFor: 'Alias pour',
		emailSettingsAuthenticatedDomainValidationDialog: 'Validation',
		emailSettingsAuthenticatedDomainDnsRecordName: 'Nom',
		emailSettingsAuthenticatedDomainDnsRecordValid: 'Valide',
		emailSettingsAuthenticatedDomainDnsRecordReason: 'Raison',
		emailSettingsAuthenticatedDomainCreate: 'Demander la configuration du domaine',
		emailSettingsAuthenticatedDomainView: 'Voir des records du domain',
		emailSettingsAuthenticatedDomainValidate: 'Valider des records du domain',
		emailSettingsAuthenticatedDomainValidationResultStatus: 'Statut',
		emailSettingsAuthenticatedDomainValidationResult_VALID: 'Valide',
		emailSettingsAuthenticatedDomainValidationResult_INVALID: 'Invalide',
		emailSettingsAuthenticatedDomainValidationResult_PENDING: 'Pas validé',
		emailSettingsAuthenticatedDomainValidationResult_IDLE: 'Pas commencé',
		emailSettingsAuthenticatedDomainValidationResult_UNKNOWN: 'Inconnu',
		emailSettingsAuthenticatedDomainPendingChanges: 'Pour continuer en mode autorisé SPF, il faut d\'abord sauvegarder les paramètres actuels. Voulez-vous continuer?',
		emailSettingsAuthenticatedDomainNoWhitelistedDomains: 'Aucun domaine autorisé n\'a été détecté pour compléter la validation SPF. Veuillez contacter le support Quill pour configurer les domaines autorisés.',
		emailSettingsAuthenticatedDomainDmarcRecord: 'Pour maximiser la délivrabilité des courriels, il est conseillé de configurer un enregistrement DMARC si ce n\'est pas encore le cas. Exemple:',
		emailSettingsAddress: 'Adresse e-mail compagnie',
		emailSettingsName: 'Nom',
		emailSettingsSupport: 'Support',
		emailSettingsSupportEmail: 'Adresse e-mail du support',
		emailSettingsApiKeys: 'Clés API',
		emailSettingsApiKeysNew: 'Saisir une nouvelle valeur',
		emailSettingsApiKeySending: 'Clé API pour envoyer',
		emailSettingsApiKeySendingNotVisible: 'La clé API pour envoyer a été définie mais est masquée pour des raisons de sécurité',
		emailSettingsLayout: 'Personnalisation de l\'e-mail',
		emailSettingsLayoutCustomize: 'Appliquer la personnalisation',
		emailSettingsHeaderFooter: 'En-tête - Pied de page',
		emailSettingsHeaderEdit: 'Modifier l\'en-tête',
		emailSettingsFooterEdit: 'Modifier le pied de page',
		emailSettingsColorBackground: 'Couleur d\'arrière-plan',
		emailSettingsColorBackgroundContent: 'Couleur d\'arrière-plan du contenu',
		emailSettingsColorButtonBackground: 'Couleur d\'arrière-plan du bouton',
		emailSettingsColorButtonText: 'Couleur du texte du bouton',
		emailSettingsColorDefault: 'Défaut',
		emailSettingsColorCompanyMain: 'Couleur principale du style de l\'entreprise',
		emailSettingsColorCompanySecondary: 'Couleur secondaire du style de la maison',
		emailSettingsColorCustom: 'Couleur personnalisée',

		notificationSettingsFor: 'Paramètres de notification pour',
		notificationSettingsReminders: 'Rappels pour les approbations et signatures en attente',
		notificationSettingsEnableReminders: 'Activer les rappels',
		notificationSettingsRemindersDays: 'jour(s) entre l\'envoi des rappels pour les approbations et signatures incomplètes',
		notificationSettingsDailyDigest: 'Bulletin quotidien',
		notificationSettingsDailyDigestTime: 'Temps d\'envoi',
		notificationSettingsMinimizeCollectionInvites: 'Limiter les invitations de collections',
		notificationSettingsMinimizeCollectionInvitesEnable: 'Attendez d\'autres approbations/signatures pour limiter le nombre d\'invitations de collections',
		notificationSettingsMinimizeCollectionInvitesGracePeriod: 'Temps d\'attente maximum en minutes',
		notificationSettingsMinimizeCollectionInvitesGracePeriodHelp: 'Laisser vide pour ne pas définir de maximum',
		notificationSettingsMinimizeCollectionFinishedNotifications: 'Limiter les notifications de documents terminés des collections',
		notificationSettingsMinimizeCollectionFinishedNotificationsEnable: 'Envoyer des notifications de fin de document lorsque l\'ensemble de la collection est terminée',

		generalSettingsFormFieldValidatorSettings: 'Les validateurs pour les champs de formulaire',
		generalSettingsFormFieldValidatorTest: 'Test',
		generalSettingsFormFieldValidatorType_TEXT: 'Texte',
		generalSettingsFormFieldValidatorType_CHECKBOX: 'Checkbox',
		generalSettingsFormFieldValidatorTestInput: 'Entrée',
		generalSettingsFormFieldValidatorTestInvalid: 'Entrée invalide',
		generalSettingsFormFieldValidatorTestValid: 'Entrée valide',
		generalSettingsFormFieldValidatorName: 'Nom',
		generalSettingsFormFieldValidatorType: 'Type',
		generalSettingsFormFieldValidatorFormat: 'Format',
		generalSettingsFormFieldValidatorFormatDescription: 'Vous pouvez utiliser # pour représenter n\'importe quel nombre (0-9). Tous les autres caractères sont des caractères littéraux; la saisie de caractères littéraux est facultative. Par exemple, le format BE## #### #### #### décrit un IBAN belge',
		generalSettingsFormFieldValidatorActions: 'Actions',
		generalSettingsFormFieldValidatorAdd: 'Ajouter',
		generalSettingsFormFieldValidatorEdit: 'Modifier',
		generalSettingsFormFieldValidatorDelete: 'Supprimer',

		certificateSettingsFor: 'Paramètres du certificat pour',
		certificateSettingsDefaultConfig: 'Défaut de Quill',
		certificateSettingsConfigs: 'Configurations des certificats du coffre-fort',
		certificateSettingsConfig: 'Configuration du certificat du coffre-fort',
		certificateSettingsConfigHeader: 'Nom de la configuration',
		certificateSettingsConfigVaultConnectionHeader: 'Connexion au coffre à clés',
		certificateSettingsConfigCertificateHeader: 'Certificat',
		certificateSettingsConfigName: 'Nom',
		certificateSettingsConfigVaultUrl: 'URL du coffre-fort',
		certificateSettingsConfigCertificateName: 'Nom de certificat',
		certificateSettingsConfigClientId: 'Client ID',
		certificateSettingsConfigClientSecret: 'Client secret',
		certificateSettingsConfigTenantId: 'Tenant ID',
		certificateSettingsConfigKeyId: 'Key ID',
		certificateSettingsConfigAdd: 'Ajouter',
		certificateSettingsConfigEdit: 'Modifier',
		certificateSettingsConfigDelete: 'Supprimer',
		certificateSettingsConfigUsage: 'Utilisation du certificat',
		certificateSettingsInfo: 'Vous pouvez gérer les certificats ci-dessous qui seront utilisés lors de l\'utilisation du type de signature \'sceau électronique\' ou \'itsme\u00AE avancée\'.',
		certificateSettingsGuestUserCertificate: 'Certificat de signature pour les visiteurs.',
		certificateSettingsRegisteredUserCertificate: 'Certificat de signature pour les utilisateurs enregistrés.',

		apiKeySettingsHeaderFor: 'Paramètres de la clé API pour',
		apiKeySettings: 'Paramètres de la clé API',
		apiKeySettingsValue: 'Clé',
		apiKeySettingsAlert: 'Veuillez noter que la clé ne peut pas être visualisée après la fermeture de cette dialogue.',
		apiKeySettingsAlmostExpireDay: 'Expire après 1 jour',
		apiKeySettingsAlmostExpireDays: 'Expire après {{days}} jours',
		apiKeySettingsCopy: 'Copier',
		apiKeySettingsCreate: 'Créer',
		apiKeySettingsContactEmail: 'E-mail de contact',
		apiKeySettingsDescription: 'Description',
		apiKeySettingsDuration: 'Période de validité',
		apiKeySettingsDurationDuring: 'pendant une période de',
		apiKeySettingsDuration_DURATION_DAY: 'jour',
		apiKeySettingsDuration_DURATION_DAYS: 'jours',
		apiKeySettingsDuration_DURATION_MONTH: 'mois',
		apiKeySettingsDuration_DURATION_MONTHS: 'mois',
		apiKeySettingsDuration_DURATION_WEEK: 'semaine',
		apiKeySettingsDuration_DURATION_WEEKS: 'semaines',
		apiKeySettingsDuration_DURATION_YEAR: 'année',
		apiKeySettingsDuration_DURATION_YEARS: 'années',
		apiKeySettingsFromUntilHeader: 'Veuillez préciser la durée de validité de la clé.',
		apiKeySettingsOptionalDescription: 'Description de la clé',
		apiKeySettingsContactEmailHeader: 'E-mail de contact à notifier lorsque la clé est sur le point d\'expirer, par exemple la boîte de réception du service d\'assistance de l\'entreprise.',
		apiKeySettingsState: 'Statut',
		apiKeySettingsStateActive: 'Actif',
		apiKeySettingsStateExpired: 'Expiré',
		apiKeySettingsStateNotActive: 'Pas actif',
		apiKeySettingsValidFrom: 'Valable à partir du',
		apiKeySettingsValidUntil: 'Valable jusqu\'à',
		apiKeySettingsRevoke: 'Révoquer clé API',
		apiKeySettingsRevokeConfirm: 'Êtes-vous sûr de vouloir révoquer ce clé API? C\'est irréversible.',

		switchCurrentCompany: 'Société active',
		switchToOtherCompany: 'Changer la société',
		switchTo: 'Changer',

		insightsTab_STATISTICS: 'Statistiques',
		insightsTab_ACTION_LOGS: 'Journal des actions',

		statsNoData: 'Pas de données',
		statsSource_SIGNATURES: 'Signatures',
		statsLegendTitle_SIGNATURES: 'Signatures (sélectionner le type à masquer)',
		statsSource_APPROVALS: 'Approbations',
		statsLegendTitle_APPROVALS: 'Approbations (sélectionner le type à masquer)',
		statsMode_MONTH: 'Par mois',
		statsMode_DAY: 'Par jour',
		statsBeginDate: 'Début',
		statsEndDate: 'Fin',
		statsCompanyFilterTitle: 'Société',
		statsCompanyFilter_ALL: 'Toutes',
		statsFolderFilterTitle: 'Dossier',
		statsFolderFilter_ALL: 'Tous (individuel)',
		statsFolderFilter_NONE: 'Tous (groupés)',
		statsSigningOrigin: 'Origine de signature',
		statsSigningOrigin_ALL: 'Tous',
		statsSigningOrigin_LOCAL: 'Interne (locale)',
		statsSigningOrigin_REMOTE: 'Externe (distance)',
		statsInfo: 'Vous pouvez afficher et exporter les statistiques d\'approbation, de signature et itsme\u00AE ci-dessous. Les filtres s\'appliquent sur le graphique, les données détaillées et les exportations.',
		statsChart: 'Graphique',
		statsItsmeDetails: 'itsme\u00AE détails',
		statsItsmeDetailsFolder: 'Dossier de documents',
		statsItsmeDetailsCompany: 'Entreprise',
		statsItsmeDetailsPartnerCodeOrigin: 'itsme\u00AE code partenaire',
		statsItsmeDetailsPartnerCodeOrigin_QUILL: 'Quill',
		statsItsmeDetailsPartnerCodeOrigin_COMPANY: 'Spécifique à l\'entreprise',
		statsItsmeDetailsCount: 'Signatures',
		statsItsmeDetailsUniqueSigners: 'Utilisateurs uniques',
		statsItsmeDetailsInfo: 'Attention : le nombre d\'utilisateurs uniques itsme\u00AE n\'est stocké que depuis la v2.6 (09/07/2024). Toutes les signatures itsme\u00AE antérieures à cette version ne sont pas prises en compte pour les statistiques de signataire unique.',
		statsItsmeDetailsRatio: 'Ratio',
		statsItsmeDetailsRatioTooltip: 'Ratio nombre de signatures sur nombre de signataires uniques',
		statsSigningItsmePartnerCode: 'itsme\u00AE code partenaire',
		statsSigningItsmePartnerCode_ALL: 'Tous',
		statsSigningItsmePartnerCode_QUILL: 'Quill (par défaut)',
		statsSigningItsmePartnerCode_COMPANY: 'Spécifique à l\'entreprise',

		webhookSettingsHeader: 'Configurations de webhook',
		webhookSettingsHeaderDescription: 'Vous pouvez gérer différentes configurations de webhook; actuellement, seule la configuration d\'authentification mTLS est prise en charge.',
		webhookSettingsConfigCreate: 'Créer une configuration',
		webhookSettingsConfigDescription: 'Description',
		webhookSettingsConfigExpiry: 'Expiration du certificat client',
		webhookSettingsConfigSelectTitle: 'Sélectionner le fichier PKCS12',
		webhookSettingsConfigSelect: 'Sélectionner',
		webhookSettingsConfigSelectDescription: 'Ce fichier doit contenir une clé privée, certificat client et une chaîne de certificats. Il peut être éventuellement crypté par mot de passe.',
		webhookSettingsConfigFilePassword: 'Mot de passe',
		webhookSettingsConfigFilePasswordDescription: 'Mot de passe utilisé pour créer le fichier PKCS12 (facultatif)',
		webhookSettingsConfigDelete: 'Supprimer la configuration',
		webhookSettingsConfigDeleteConfirm: 'Êtes-vous sûr de vouloir supprimer cette configuration de webhook? Ceci est irréversible.',

		actionLogPerformedAt: 'Horodatage',
		actionLogActorFullName: 'Acteur',
		actionLogEvent: 'Événement',
		actionLog_COMPANY_CREATED: 'La société {0} a été créée',
		actionLog_COMPANY_UPDATE_NAME: 'Le nom de l\'entreprise a été changé de {0} à {1}',
		actionLog_COMPANY_API_ENABLE: 'L\'accès à l\'API est accordé',
		actionLog_COMPANY_API_DISABLE: 'L\'accès à l\'API a été révoqué',
		actionLog_COMPANY_API_KEY_CREATED: 'Clé API a été créée avec une validité de {0} à {1}',
		actionLog_COMPANY_API_KEY_REVOKED: 'Clé API a été révoquée avec une validité de {0} à {1}',
		actionLog_DOCUMENT_DELETE: 'Le document {0} (id={1}) a été supprimé',
		actionLog_FOLDER_CREATE: 'Le dossier {0} a été créé',
		actionLog_FOLDER_DELETE: 'Le dossier {0} a été supprimé',
		actionLog_FOLDER_GROUP_PERMISSION_DELETE: 'L\'autorisation {0} a été révoquée pour le groupe {1} sur le dossier {2}',
		actionLog_FOLDER_GROUP_PERMISSION_CREATE: 'L\'autorisation {0} a été accordée pour le groupe {1} sur le dossier {2}',
		actionLog_FOLDER_USER_PERMISSION_DELETE: 'L\'autorisation {0} a été révoquée pour l\'utilisateur {1} sur le dossier {2}',
		actionLog_FOLDER_USER_PERMISSION_CREATE: 'L\'autorisation {0} a été accordée à l\'utilisateur {1} sur le dossier {2}',
		actionLog_USER_INVITED: 'L\'utilisateur avec l\'e-mail {0} a été invité',
		actionLog_USER_INVITATION_RESEND: 'L\'e-mail d\'invitation a été renvoyé à {0}',
		actionLog_USER_GUEST_ADDED: 'L\'utilisateur avec l\'e-mail {0} a été ajouté comme visiteur',
		actionLog_USER_PROMOTED: 'L\'utilisateur avec l\'e-mail {0} a maintenant {1} comme rôle',
		actionLog_USER_DEMOTED: 'L\'utilisateur avec l\'e-mail {0} n\'a plus le rôle {1}',
		actionLog_USER_DELETED: 'L\'utilisateur avec l\'e-mail {0} a été supprimé',
		actionLog_USER_EMAIL_CHANGED: 'L\'utilisateur avec l\'e-mail {0} a maintenant {1} comme e-mail',
		actionLog_USER_FIRSTNAME_CHANGED: 'L\'utilisateur avec l\'e-mail {0} a maintenant {2} comme prénom (était {1})',
		actionLog_USER_LASTNAME_CHANGED: 'L\'utilisateur avec l\'e-mail {0} a maintenant {2} comme nom de famille (était {1})',
		actionLog_USER_NOTIFICATION_ADDED: 'La notification {1} a été activée pour l\'utilisateur avec l\'e-mail {0}',
		actionLog_USER_NOTIFICATION_UPDATED: 'La notification {1} a été remplacé par {2} pour l\'utilisateur avec l\'e-mail {0}',
		actionLog_USER_NOTIFICATION_REMOVED: 'La notification {1} a été supprimée pour l\'utilisateur avec l\'e-mail {0}',
	},

	delegates: {
		listsFor: 'Listes des délégués pour',
		description: 'Vous pouvez gérer les listes des délégués ici. Par liste, vous pouvez définir le type (approbation ou signature) et également gérer s\'il s\'agit d\'une liste pour tout le monde ou d\'une liste pour un utilisateur spécifique. Les listes pour des utilisateurs spécifiques ont toujours priorité sur les listes pour tout le monde.',
		listCreate: 'Créer une liste',
		listEdit: 'Modifier la liste',
		count: 'Nombre de délégués',
		actions: 'Actions',
		create: 'Créer une liste de délégués',
		edit: 'Modifier la liste des délégués',
		type: 'Type de délégation',
		type_DOCUMENT_APPROVAL: 'Approbation',
		type_DOCUMENT_SIGNATURE: 'Signature',
		for: 'Pour un utilisateur spécifique',
		forEveryone: 'Tout le monde',
		chooseFor: 'Choisissez un utilisateur spécifique',
		deleteFor: 'Supprimer un utilisateur spécifique',
		delegates: 'Délégués',
		addDelegates: 'Ajouter des délégués',
		order: 'Ordre',
		up: 'Placer vers le haut',
		down: 'Placer vers le bas',
		firstName: 'Prénom',
		lastName: 'Nom de famille',
		email: 'E-mail',
		delete: 'Supprimer',
		listDelete: 'Supprimer la liste',
		listDeleteConfirm: 'Voulez-vous vraiment supprimer cette liste?',
		deleted: 'Liste supprimée',
		delegate: 'Délégué',
		file: 'Procuration',
		fileUploadDescription: 'Vous pouvez ici (en option) télécharger un fichier décrivant la procuration. Ce fichier sera ajouté au rapport de preuve. Remarque : cette modification sera sauvegardée immédiatement.',
		fileDescription: 'Le procuration est configurée et sera ajoutée au rapport de preuve. Attention : la suppression ou la modification du fichier sera appliquée immédiatement.',
		fileName: 'Nom du fichier procuration',
		fileDownload: 'Télécharger le fichier de procuration',
		fileUpload: 'Télécharger',
		fileDelete: 'Supprimer le fichier procuration',
		fileDeleteConfirm: 'Êtes-vous sûr de vouloir supprimer ce fichier ?',
		fileSaveFirst: 'Note : pour ajouter des fichiers proxy à de nouveaux délégués, la liste actuelle doit d\'abord être sauvegardée.',
		uploadFile: 'Télécharger la procuration'
	},

	user: {
		state_INVITED: 'Invité',
		state_REGISTERED: 'Enregistré',
		state_GUEST: 'Visiteur',
		state_GUEST_CONTACT: 'Contact dans {{folder}}',
		state_REMOVED: 'Supprimé',
		state_API: 'API',

		header: 'Utilisateurs',
		firstName: 'Prénom',
		lastName: 'Nom de famille',
		guest: 'Visiteur',
		memo: 'Mémo',
		email: 'E-mail',
		phoneNumber: 'Numéro de téléphone (format +32477112233)',
		state: 'Statut',
		defaultRemarkVisualSignature: 'Remarque standard pour signature visuelle',
		actions: 'Actions',
		create: 'Inviter',
		import: 'Importer',
		delete: 'Supprimer',
		registerGuest: 'Convertir en utilisateur enregisté',
		resendInvitation: 'Renvoyer l\'invitation',
		deleted: 'Utilisateur supprimé',

		deleteConfirm: 'Voulez-vous vraiment supprimer cet utilisateur?',
		registerGuestConfirm: 'Voulez-vous vraiment enregistrer cet utilisateur?',

		createName: 'Nom d\'utilisateur',
		createEmail: 'Adresse e-mail de l\'utilisateur',
		createLanguage: 'Langue de l\'utilisateur',
		createPhoneNumber: 'Numéro de téléphone de l\'utilisateur',
		createAddType: 'Ajouter comme',
		createAddType_REGISTERED: 'Utilisateur enregistré',
		createAddType_GUEST: 'Visiteur',
		createAddType_CONTACT: 'Contact dans {{folder}}',
		createFinish: 'Tout est complété?',
		createFinishDescription: 'Après l\'invitation de l\'utilisateur, une configuration supplémentaire peuvent être effectuée (si les autorisations appropriées sont présentes).',

		settingsUserMetaAttributes: 'Attributs méta',
		settingsUserMetaAttributeNew: 'Nouvel attribut méta',
		settingsUserMetaAttributeKey: 'Attribut',
		settingsUserMetaAttributeValue: 'Valuer',

		uploadAllowUpdate: 'Permettre la modification des utilisateurs existants',
		uploadDownloadTemplate: 'Télécharger un fichier d\'exemple',
		uploadInvalidFileExtension: 'Type de fichier non valide. Veuillez télécharger un fichier .csv.',
		uploadFileLimitExceeded: 'La taille du fichier est dépassée. Veuillez sélectionner un fichier dont la taille est moins de 100 MB.',
		uploadImportedUsersResult: 'Nombre d\'utilisateurs importés avec succès',
		uploadImportedErrors: 'Erreurs trouvées lors de l\'importation du fichier fourni:',
		uploadParsingError: 'Format de données non valide',
		uploadError_CANNOT_ADD_TO_COMPANY_GROUP: 'Impossible d\'ajouter cet utilisateur à un ou plusieurs des groupes d\'utilisateurs spécifiés',
		uploadError_COMPANY_USER_GROUP_NOT_FOUND: 'Impossible de trouver un ou plusieurs groupes d\'utilisateurs spécifiés pour cet utilisateur.',
		uploadError_EMAIL_DUPLICATE: 'Email spécifié plusieurs fois dans le fichier donné',
		uploadError_EMAIL_INVALID: 'Adresse électronique invalide',
		uploadError_CONVERT_REGISTERED_TO_GUEST: 'L\'utilisateur enregistré ne peut pas être transformé en visiteur',
		uploadError_CONTACT_FOLDER_MISSING: 'Impossible de trouver le dossier dans lequel créer l\'utilisateur contact',
		uploadError_GUEST_AND_CONTACT_CONFLICT: 'L\'utilisateur ne peut pas être ajouté en tant que visiteur et contact en même temps',
		uploadError_INVALID_NOTIFICATION: 'Un ou plusieurs types de notification n\'existent pas',
		uploadError_PARSING_ERROR: 'Données invalides',
		uploadError_UNKNOWN: 'Une erreur inconnue s\'est produite',
		uploadError_USER_ALREADY_EXISTS: 'L\'utilisateur existe déjà',
		uploadError_NOT_ALLOWED: 'Vous n\'êtes pas autorisé à effectuer cette action',

		outOfOfficeNotActive: 'Désactivé',
		outOfOfficeAlwaysActive: 'Toujours actif',
		outOfOfficeConfiguredActive: 'Actif',
		outOfOfficeConfiguredActiveFrom: 'À partir de',
		outOfOfficeConfiguredActiveUntil: 'Jusqu\'à',

		settings: 'Paramètres',
		settingsGeneral: 'Général',
		settingsVisualSignature: 'Signature',
		settingsVisualSignatureUpload: 'Importer une nouvelle signature visuelle (max 1MB)',
		settingsVisualSignatureDelete: 'Supprimé signature visuelle',
		settingsVisualSignatureDescription: 'Les modifications de la signature visuelle seront appliquées immédiatement.',
		settingsOutOfOffice: 'Absence du bureau',
		settingsLanguage: 'Langue',
		settingsCommunication: 'Communication',
		settingsCommunicationReceiveEmail: 'L\'utilisateur reçoit un e-mail quand:',
		settingsInviteUserNotification: 'Demande d\'approbation/signature de document',
		settingsReminderUserNotification: 'Rappel',
		settingsApprovalUserNotification: 'Le document approuvé/refusé',
		settingsSignatureUserNotification: 'Votre document signé',
		settingsFullySignedUserNotification: 'Le document entièrement signé',
		settingsClosedUserNotification: 'Le document fermé',
		settingsExpiredUserNotification: 'Le document expiré',
		settingsPermissions: 'Les droits',
		settingsPermissionsGuestDisabled: 'Remarque: les paramètres d\'autorisation ne s\'appliquent pas aux visiteurs/contacts',
		settingsPermissionsUserIs: 'L\'utilisateur est:',
		settingsPermissionsUserCanManage: 'L\'utilisateur peut gérer:',
		settingsAdmin: 'Administrateur',
		settingsUserManager: 'Utilisateurs',
		settingsUserGroupManager: 'Groupes d\'utilisateurs',
		settingsUserGroups: 'Adhésion à un groupe d\'utilisateurs',
		settingsUserGroupsNoAccess: 'Vous n\'avez pas accès pour gérer les groupes d\'utilisateurs',
		settingsUserGroupName: 'Nom',
		settingsUserGroupMember: 'Membre',
		settingsUserGroupAdmin: 'Admin',
		settingsSignerGroupManager: 'Groupes de signataires',
		settingsSignerGroups: 'Adhésion au groupe de signataires',
		settingsSignerGroupsNoAccess: 'Vous n\'avez pas accès pour gérer les groupes de signataires',
		settingsSignerGroupName: 'Nom',
		settingsSignerGroupMember: 'Membre',
		settingsDocumentFolderManager: 'Les dossiers de documents',
		settingsDocumentFolders: 'Autorisations des dossiers',
		settingsDocumentFoldersNoAccess: 'Vous n\'avez pas accès à la gestion des dossiers de documents',
		settingsDocumentFolderName: 'Nom',
		settingsOutOfOfficeManager: 'Paramètres d\'absence du bureau d\'autres utilisateurs',
		settingsTemplateManager: 'Modèles de documents',
		settingsPermissionsUserCanView: 'L\'utilisateur peut visualiser:',
		settingsActionLogViewer: 'Journal des actions de l\'entreprise',
		settingsPermissionsUserCanInvite: 'L\'utilisateur peut inviter:',
		settingsInviteRegisteredUsers: 'Utilisateurs enregistrés',
		settingsInviteGuestUsers: 'Visiteurs',
		settingsInviteContacts: 'Contacts',
		settingsFinish: 'Tout est complété?',
		settingsFinishDescription: 'Appuyer sur enregister pour conserver les changements',
		settingsSave: 'Enregister',

		settingsTab_PROFILE: 'Profil',
		settingsTab_SECURITY: 'Sécurité',
		myProfileHeader: 'Mon utilisateur',
		profileHeader: 'Utilisateur',
		profileSaveWarning: 'Faites attention! Si vous avez fait des modifications sur cette page, il faut les enregistrer pour ne pas les perdre.',
		profileGuestSaveWarning: 'Faites attention, les modifications apportées à la signature visuelle et aux initiales seront enregistrées immédiatement.',
		profilePersonalHeader: ' Données personnelles',
		profilePersonalFirstName: 'Prénom',
		profilePersonalLastName: 'Nom de famille',
		profilePersonalMemo: 'Mémo',
		profilePersonalEmail: 'E-mail',
		profilePersonalEidCardNumber: 'Numéro de la carte eID belge',
		profilePersonalDefaultRemarkVisualSignature: 'Remarque standard pour signature visuelle',
		profileCapacities: 'Qualités',
		profileCapacityTitleHeader: 'Titre de la qualité',
		profileCapacityTitle: 'Titre',
		profileCapacityRemarkHeader: 'Remarque par défaut',
		profileCapacityRemark: 'Remarque',
		profileCapacityActions: 'Actions',
		profileCapacityDelete: 'Supprimer la qualité',
		profileCapacityAdd: 'Ajouter',
		profileCapacityUpdate: 'Ajuster la qualité',
		profileCapacityCreate: 'Créer une qualité',
		profileVisualData: 'Signature visuelle/paraphe',
		profileItsmeInfo: 'Votre identité itsme\u00AE a été mémorisé lors d\'une session de signature. Cela vous permet d\'éviter l\'étape d\'identification lors de la signature avec itsme\u00AE. Vous pouvez effacer cette identité de votre compte Quill ici.',
		profileItsmeForget: 'Oublier mon identité itsme\u00AE',
		profileItsmeForgetSuccess: 'L\'identité itsme\u00AE a été supprimée de votre compte Quill avec succès.',
		profileGeneralHeader: 'Paramètres généraux',
		profileGeneralDefaultRowsPerPage: 'Nombre de lignes par défaut dans les tableaux',
		profileGeneralLanguage: 'Langue',
		profileOutOfOfficeHeader: 'Absence du bureau',
		profileOutOfOfficeNotActive: 'Désactivé',
		profileOutOfOfficeAlwaysActive: 'Toujours actif',
		profileOutOfOfficeConfiguredActive: 'Actif',
		profileOutOfOfficeConfiguredActiveFrom: 'à partir de',
		profileOutOfOfficeConfiguredActiveUntil: 'jusqu\'à',
		profileCommunicationHeader: 'Paramètres de communication',
		profileCommunicationSubHeader: 'Recevoir des e-mails:',
		profileCommunicationEmailInvite: 'Les demandes d\'approbation ou de signature',
		profileCommunicationEmailReminder: 'Rappels d\'approbation ou de signature',
		profileCommunicationEmailFullySigned: 'Lorsque les documents sont entièrement signés',
		profileCommunicationEmailSignaturePlaced: 'Lorsqu\'une signature est apposée sur un document que vous avez envoyé',
		profileCommunicationEmailApproval: 'Lorsqu\'un document est approuvé ou refusé',
		profileCommunicationEmailClosed: 'Lorsqu\'un document est fermé',
		profileCommunicationEmailExpired: 'Lorsqu\'un document est expiré',
		profileUpdatePasswordHeader: 'Changer le mot de passe',
		profileApiAccess: 'Accès API',
		profileApiAccessNotEnabled: 'L\'accès à l\'API n\'est pas activé pour ce compte, cliquez ci-dessous pour l\'activer. Attention, votre clé API ne sera visible qu\'une seule fois !',
		profileApiAccessEnable: 'Activer l\'accès à l\'API',
		profileApiAccessEnabled: 'L\'accès à l\'API est activé pour ce compte, cliquez ci-dessous pour le désactiver.',
		profileApiAccessSecret: 'Secret',
		profileApiAccessSecretCopy: 'Copier',
		profileApiAccessDisable: 'Désactiver l\'accès à l\'API',
		password: 'Mot de passe',
		currentPassword: 'Mot de passe actuel',
		newPassword: 'Nouveau mot de passe',
		newPasswordConfirmation: 'Confirmer le nouveau mot de passe',
		passwordConfirmation: 'Confirmation mot de passe',
		passwordRequirements: 'Votre mot de passe doit comporter au moins 8 caractères',

		notification_ON: 'Marche',
		notification_OFF: 'Arrêt',
		notification_DAILY_DIGEST: 'Bulletin quotidien',

		importNoHeader: 'Sans en-tête',
		importPasteOrDropFile: 'Collez les données de l\'utilisateur ice ou glissez-déposez un fichier CSV (UTF-8)',
		importDownloadTemplate: 'Télécharger un fichier d\'exemple',
		importInviteUsers: 'Inviter de nouveaux utilisateurs',
		importLookupCreatedUsersResult: 'Nombre d\'utilisateurs créés avec succès',
		importLookupFoundUsersResult: 'Nombre d\'utilisateurs trouvés avec succès',
		importLookupUsersNotFound: 'Utilisateurs non trouvés:',
	},

	userGroup: {
		header: 'Groupes d\'utilisateur',
		name: 'Nom',
		description: 'Description',
		actions: 'Actions',
		create: 'Créer groupe d\'utilisateur',
		delete: 'Supprimer',
		deleteConfirm: 'Voulez-vous vraiment supprimer ce groupe?',
		deleted: 'Groupe supprimé',

		createName: 'Le nom du groupe d\'utilisateur',
		createDescription: 'La description du groupe d\'utilisateur',
		createFinish: 'Tout est complété?',
		createFinishDescription: 'Après la création du groupe d\'utilisateur des membres peuvent être ajoutés et configurés.',

		settings: 'Paramètres',
		settingsNameDescription: 'Nom et description du groupe d\'utilisateurs',
		settingsFinish: 'Tout est complété?',
		settingsFinishDescription: 'Appuyer sur enregister pour conserver les changements',
		settingsSave: 'Enregister',

		import: 'Importer des utilisateurs',

		users: 'Membres',
		userName: 'Nom',
		userEmail: 'E-mail',
		userAdmin: 'Admin du groupe',
		addUser: 'Ajouter membre',
		userRemove: 'Supprimer membre',
		userRemoveConfirm: 'Voulez-vous vraiment supprimer ce membre?',
		userState: 'Statut',

		userSelect: 'Sélectionnez le(s) utilisateur(s) à ajouter',
		usersDescription: 'Ajoutez, supprimez et configurez les membres de ce groupe. Notez que ces modifications sont appliquées immédiatement.',
	},

	folder: {
		header: 'Dossiers',
		name: 'Nom',
		description: 'Description',
		actions: 'Actions',
		settings: 'Paramètres',
		permissions: 'Permissions',
		delegates: 'Délégués',
		delete: 'Supprimer',
		deleteConfirm: 'Voulez-vous vraiment supprimer ce dossier?',
		deleted: 'Dossier supprimé',

		create: 'Créer dossier',
		createName: 'Le nom du dossier',
		createDescription: 'La description du dossier',
		createFinish: 'Tout est complété?',
		createFinishDescription: 'Après la création du dossier, d\'autres paramètres et permissions peuvent être ajoutés. Les paramètres et les permissions affectent tous les documents du dossier.',

		settingsNameDescription: 'Nom et description du dossier',
		settingsSignatureTypes: 'Types de signature',
		settingsSignatureType_ELECTRONIC_WITH_WITNESS_SEAL: 'Sceau électronique',
		settingsSignatureType_BELGIAN_EID: 'eID belge',
		settingsSignatureType_ITSME: 'itsme\u00AE qualifiée',
		settingsSignatureType_ITSME_ADVANCED: 'itsme\u00AE avancée',
		settingsSignatureType_SMS_OTP: 'Code par SMS',
		settingsSignatureType_EMAIL_OTP: 'Code par e-mail',
		settingsSignatureType_HANDWRITTEN: 'Manuscrite',
		settingsUseCompanyItsmePartnerCode: 'Utiliser le code partenaire itsme\u00AE spécifique à l\'entreprise (signature fréquente)',
		settingsBlocked: 'paramètre déterminé au niveau de la société',
		settingsReasonAndRemark: 'Motivation et remarque',
		settingsReason: 'Signataire peut ajouter une raison de motivation',
		settingsReasonLegalNoticeMandatory: 'Faites-en une mention légale obligatoire que l\'utilisateur doit saisir. Cette mention apparaîtra également visuellement sur le document',
		settingsReasonLegalNoticeText: 'Valeur pré-remplie',
		settingsEmailDistributionList: 'Paramètres par défaut pour la liste de distribution des e-mails après signer',
		settingsEmailDistributionLabel: 'Liste des adresses e-mail séparée par \';\'',
		settingsEmailDocumentDistributionListDescription: 'Destinataires des documents',
		settingsEmailDocumentAndEvidenceReportDistributionListDescription: 'Destinataires des documents et le rapport de preuve',
		settingsEmailEvidenceReportIncludedInPostSignEmail: 'Inclure le rapport de preuve',
		settingsOverrideOnFolderLevelRemove: 'Cliquez ici pour utiliser les paramètres par défaut de l\'entreprise',
		settingsOverrideOnFolderLevelSet: 'Cliquez ici pour remplacer les paramètres par défaut de l\'entreprise',
		settingsRemark: 'Signataire peut ajouter une remarque',
		settingsMailbox: 'Boîte aux lettres',
		settingsMailboxEnabled: 'Activer une boîte aux lettres pour ce dossier',
		settingsMailboxAddress: 'Adresse e-mail pour cette boîte aux lettres',
		settingsFolderCleanup: 'Suppression automatique des documents',
		settingsFolderCleanupOverwrite: 'Remplacer les paramètres de l\'entreprise pour ce dossier',
		settingsCleanupDaysProcessed: 'jour(s) après la suppression d\'un document terminé',
		settingsCleanupDaysPending: 'jour(s) après la suppression d\'un document inactif inachevé',
		settingsFolderExpiration: 'Expiration automatique des documents',
		settingsExpirationOverride: 'Remplacer les paramètres de l\'entreprise pour ce dossier',
		settingsExpirationEnable: 'Permettre l\'expiration des demandes d\'approbation et de signature',
		settingsSignatureExpirationDays: 'jour(s) avant l\'expiration d\'une demande d\'approbation et de signature ouverte',
		settingsFolderForwarding: 'Transmission des demandes d\'approbation et de signature',
		settingsAllowForwarding: 'Autoriser la transmission',
		settingsForwardingEnabledByDefault: 'Activer la transmission par défaut',
		settingsReminders: 'Rappels',
		settingsRemindersOverwrite: 'Remplacer les paramètres de l\'entreprise pour ce dossier',
		settingsRemindersIntervalInDays: 'jour(s) entre les rappels. Utilisez 0 pour désactiver.',
		settingsMinimizeCollectionInvites: 'Limiter les invitations de collections',
		settingsMinimizeCollectionInvitesFolder: 'Remplacer les paramètres de l\'entreprise pour ce dossier',
		settingsMinimizeCollectionInvitesEnable: 'Attendez d\'autres approbations/signatures pour limiter le nombre d\'invitations de collections',
		settingsMinimizeCollectionInvitesGracePeriod: 'Temps d\'attente maximum en minutes',
		settingsMinimizeCollectionInvitesGracePeriodHelp: 'Laisser vide pour ne pas définir de maximum',
		settingsMinimizeCollectionFinishedNotifications: 'Limiter les notifications de documents terminés des collections',
		settingsMinimizeCollectionFinishedNotificationsFolder: 'Remplacer les paramètres de l\'entreprise pour ce dossier',
		settingsMinimizeCollectionFinishedNotificationsEnable: 'Envoyer des notifications de fin de document lorsque l\'ensemble de la collection est terminée',
		settingsSignerNeedsSignatureToDownload: 'Le téléchargement nécessite la signature du signataire',
		settingsSignerNeedsSignatureToDownloadFolder: 'Remplacer les paramètres de l\'entreprise pour ce dossier',
		settingsSignerNeedsSignatureToDownloadEnable: 'Une signature entièrement placée est nécessaire pour que le signataire puisse télécharger un document dans ce dossier',
		settingsDocumentCollection: 'Le dossier doit être marqué comme lu avant de signer',
		settingsDocumentCollectionMarkAsReadMandatory: 'Remplacer les paramètres de l\'entreprise pour ce dossier',
		settingsDocumentCollectionMarkAsReadMandatoryEnable: 'Exiger que tous les documents soient marqués comme lus avant que la signature ne soit activée',
		settingsPlaceholders: 'Rechercher des espaces réservés dans les documents',
		settingsPlaceholderValue: 'Mot clé à rechercher',
		settingsPlaceholderFieldType: 'Type de champ',
		settingsPlaceholderFieldType_SIGNATURE: 'Champ de signature',
		settingsPlaceholderFieldType_PARAPH: 'Champ des initiales',
		settingsPlaceholderFieldType_FORM: 'Champ de formulaire',
		settingsPlaceholderFormFieldType: 'Type de champ de formulaire',
		settingsPlaceholderFormFieldType_TEXT: 'Texte',
		settingsPlaceholderFormFieldType_CHECKBOX: 'Case à cocher',
		settingsPlaceholderFormFieldType_DATE: 'Date',
		settingsPlaceholderFormFieldType_NUMERIC: 'Numérique',
		settingsPlaceholderFormFieldType_ATTRIBUTE: 'Attribut de signataire',
		settingsPlaceholderAdd: 'Ajouter',
		settingsUserUsage: 'Utilisateurs autorisés à approuver/signer des documents',
		settingsUserUsageRegisteredUser: 'Utilisateurs enregistrés',
		settingsUserUsageGuest: 'Invités',
		settingsUserUsageContact: 'Contacts',
		settingsUserUsageContactHelp: 'Les contacts sont liés à un dossier spécifique',
		settingsUserInvite: 'Utilisateurs autorisés à être invités',
		settingsUserInviteRegisteredUsers: 'Utilisateurs enregistrés',
		settingsUserInviteGuests: 'Invités',
		settingsUserInviteContacts: 'Contacts',
		settingsMailRedirect: 'Activer la redirection de l\'URL lors de la revisite d\'un lien de signature par e-mail',
		settingsMailRedirectEnabled: 'Activer redirection',
		settingsSigningKioskMode: 'Signer en mode kiosque',
		settingsSigningKioskModeEnabled: 'Activer le mode kiosque',
		settingsCollectionPartialDocumentHandling: 'Traitement individuel des documents dans la collection',
		settingsAllowPartialCollectionDocumentHandling: 'Permettre la signature/le rejet d\'un ou de plusieurs documents sélectionnés dans la collection',

		settingsFinish: 'Tout est complété?',
		settingsFinishDescription: 'Appuyer sur enregister pour conserver les changements',
		settingsSave: 'Enregister',

		permissionsGroup: 'Permissions des groupes',
		permissionsGroupDescription: 'L\'octroi d\'une autorisation à un groupe la confère à tous ses utilisateurs. Cela signifie que si un utilisateur est membre d\'un groupe qui dispose d\'une certaine autorisation, l\'utilisateur dispose de cette autorisation, même si l\'utilisateur est également membre d\'un groupe qui ne dispose pas de cette autorisation.',
		permissionsGroupLabel: 'Groupe',
		permissionsGroupSelect: 'Sélectionnez le(s) groupe(s) à ajouter',
		permissionsEditFolderPermission: 'Modifier dossier',
		permissionsEditDocumentsPermission: 'Modifier documents',
		permissionsViewDocumentsPermission: 'Consulter documents',
		permissionsAddUserNoPermissions: 'Ajouter utilisateur(s) sans permissions',
		permissionsAddUserAllPermissions: 'Ajouter utilisateur(s) avec toutes les permissions',
		permissionsAddGroupNoPermissions: 'Ajouter groupe(s) sans permissions',
		permissionsAddGroupAllPermissions: 'Ajouter groupe(s) avec toutes les permissions',
		permissionsUser: 'Permissions des utilisateurs',
		permissionsUserDescription: 'De préférence, les permissions utilisateur sont accordées via les permissions de groupe ci-dessus. Si, pour une raison quelconque, un utilisateur ne peut pas être ajouté à un groupe existant ou doit avoir plus d\'permissions, ils peuvent être ajoutés ici.',
		permissionsUserSelect: 'Sélectionnez le(s) utilisateur(s) à ajouter',
		permissionsFinish: 'Tout est prêt?',
		permissionsFinishDescription: 'Appuyer sur enregister pour conserver les permissions',
		permissionsSave: 'Enregister',
	},

	template: {
		name: 'Nom',
		creatorName: 'Créateur',
		folderName: 'Dossier',
		actions: 'Actions',
		edit: 'Modifier le modèle',
		view: 'Afficher le modèle',
		delete: 'Supprimer le modèle',
		deleted: 'Modèle supprimé',
		select: 'Sélectionner un modèle',
		overviewHeader: 'Modèles de documents',
		deleteTemplateConfirm: 'Vous êtes sûr de vouloir supprimer le modèle?',
		createName: 'Nom du modèle',
		createFolder: 'Dossier du modèle',
		createType: 'Type du modèle',
		createFinish: 'Tout est rempli?',
		createFinishDescription: 'Appuyez sur enregistrer pour créer le modèle.',
		create: 'Créer un modèle',
		folder: 'Dossier',
		type: 'Type',
		typeSingleDocNoPdf: 'Document unique, sans PDF',
		typeSingleDocPdf: 'Document unique, avec PDF',
		typeCollection: 'Collection',
		uploadDoc: 'Télécharger un document',
		uploadErrorGeneralError: 'Erreur',
	},

	document: {
		name: 'Nom',
		createdAt: 'Créé à',
		changedAt: 'Changé à',
		requestedAt: 'Demandé à',
		description: 'Description',
		folderName: 'Dossier',
		status: 'Statut',
		status_FUTURE: 'En attendant',
		status_PREPARING: 'En préparation',
		status_WAITING_FOR_APPROVAL: 'En attente d\'approbation',
		status_APPROVED: 'Approuvé',
		status_NOT_APPROVED: 'Non approuvé',
		status_WAITING_FOR_ACTION: 'En attente d\'action',
		status_WAITING_FOR_ACTION_BUSY: 'En attente d\'action',
		status_WAITING_FOR_SIGNATURES: 'En attente de signatures',
		status_WAITING_FOR_CHILD_DOCUMENTS: 'En attente des sous-documents',
		status_SIGNED: 'Signé',
		status_SIGNED_WITH_DECLINATIONS: 'Signé (avec refus)',
		status_CLOSED: 'Cloturé',
		status_DELETED: 'Supprimé',
		status_DECLINED: 'Refusé',
		status_ATTACHMENT: 'Pièce jointe',
		status_ATTACHMENT_GENERIC: 'Pièce jointe générique',
		status_EXPIRED: 'Expiré',
		creator: 'Créateur',
		approvers: 'Approbateurs',
		signers: 'Signataires',
		actions: 'Actions',
		upload: 'Télécharger',
		createFromTemplate: 'Du modèle',
		download: 'Télécharger',
		downloadEvidenceReport: 'Télécharger rapport de preuve',
		downloadAttachment: 'Télécharger la pièce jointe',
		downloadDocuments: 'Documents',
		downloadEvidenceReports: 'Rapports de preuves',
		downloadAll: 'Documents et rapports de preuves',
		downloadNoResult: 'Aucun résultat de téléchargement trouvé',
		edit: 'Modifier',
		editAll: 'Modifier (en masse)',
		view: 'Visualiser',
		reminder: 'Envoyez un rappel',
		deleteDocuments: 'Supprimer document',
		deleteDocumentsConfirm: 'Voulez-vous vraiment supprimer ces documents? Les documents seront supprimés pour tous les utilisateurs.',
		signingOrderType_PARALLEL: 'Parallèle',
		signingOrderType_SERIAL: 'Sériel',
		signingOrderType_KIOSK: 'Kiosque',
		approvalOrderType_PARALLEL: 'Parallèle',
		approvalOrderType_SERIAL: 'Sériel',
		signOrderType: 'Ordre de signature',
		approvalOrderType: 'Ordre d\'approbation',
		sign: 'Signer',
		signDocuments: 'Commencer à signer (en masse)',
		approve: 'Aprouver',
		approveDocuments: 'Commencer à approuver (en masse)',
		allSelected: 'Toutes les {0} lignes sont sélectionnées. $link;Annuler la sélection$',
		selectAll: '{0} lignes sélectionnées. Pour sélectionner toutes les {1} lignes, $link;cliquez ici$.',
		selectionCountSingle: '1 ligne sélectionnée',
		selectionCountMultiple: '{0} lignes sélectionnées',

		downloadDisclaimer: 'Tous les documents et les rapports de preuves ne sont pas disponibles pour téléchargement. Cela peut être le cas pour les documents pour lesquels vous ne disposez pas de l\'autorisation de dossier appropriée, pour les documents confidentiels, les documents qui ne sont pas entièrement signés ou qui sont encore en préparation.',

		generalOverviewHeader: 'Documents',

		uploadHeader: 'Charger des documents',
		uploadFolder: 'Dossier',
		uploadTemplate: 'Modèle',
		uploadTemplateApply: 'Appliquer le modèle après le téléchargement',
		uploadFiles: 'Charger des fichiers',
		uploadSelectFiles: 'Sélectionner un ou plusieurs documents sur votre ordinateur',
		uploadDragFilesHere: '... ou faites glisser votre fichier ici!',
		uploadItemName: 'Nom',
		uploadItemSize: 'Taille',
		uploadItemState: 'Statut',
		uploadItemState_NEW: 'Nouveau',
		uploadItemState_UPLOADING: 'Téléchargement...',
		uploadItemState_UPLOADED: 'Téléchargé',
		uploadItemState_ERROR: 'Erreur',
		uploadItemState_SIZE_INVALID: 'Taille invalide, la taille maximale est {0}',
		uploadItemDetails: 'Détails',
		uploadItemDelete: 'Supprimer',
		uploadItemEdit: 'Commencez à modifier',
		uploadNoFilesYet: 'Il n\'y a pas encore de fichiers, appuyez sur le bouton ci-dessus ou faites glisser et déposez les fichiers ci-dessus pour commencer',
		uploadDocDetected: 'Attention: les fichiers doc(x) sont automatiquement convertis au format PDF. Il peut arriver que certaines polices/mises en page ne soient pas converties correctement. Par conséquent: vérifiez toujours attentivement le document après la conversion ou utilisez directement des fichiers au format PDF.',
		uploadNormal: 'Télécharger',
		uploadCollection: 'Télécharger collection',
		uploadAsCollection: 'Télécharger en tant que collection',
		uploadOptionalCollectionName: 'Nom de collection facultatif',
		uploadGoToCollection: 'Aller au dossier',

		signingOverviewHeader: 'Documents pour moi',
		signingOverviewTab_ALL: 'Tous les documents',
		signingOverviewTab_WAIT_FOR_SIGNATURE: 'En attente de signature',
		signingOverviewTab_DOWNLOAD: 'Télécharger des documents',

		approvalOverviewHeader: 'Documents pour moi',
		approvalOverviewTab_ALL: 'Tous les documents',
		approvalOverviewTab_WAIT_FOR_APPROVAL: 'En attente de approbation',
		approvalOverviewTab_DOWNLOAD: 'Télécharger des documents',

		createName: 'Nom du document',
		createFolder: 'Dossier de document',
		createTemplate: 'Modèle de bibliothèque',
		selectTemplate: 'Sélectionner un modèle',
		create: 'Ajouter',
		createFinish: 'Tout est rempli?',
		createFinishDescription: 'Le document peut être configuré après son ajout.'
	},

	visualSignatureOrParaph: {
		signatureDraw: 'Dessinez une nouvelle signature visuelle dans la zone ci-dessous',
		signatureHeader: 'Signature',
		signatureCurrent: 'Votre signature visuelle actuelle',
		paraphDraw: 'Dessinez de nouvelles initiales visuelles dans la zone ci-dessous',
		paraphHeader: 'Initiales',
		paraphCurrent: 'Vos initiales visuelles actuelles',
		draw: 'Dessiner',
		clear: 'Redémarrer',
		upload: 'Téléchargez le fichier (max 1 Mb)',
		delete: 'Supprimer',
	},

	approval: {
		headerSingle: 'Approuver le document',
		headerMultiple: 'Approuver les documents',
		instructionsSingle: '\'Approuver\' donne l\'approbation de transmettre le document sélectionné aux signataires. Un sceau électronique sera inclus dans le document. \'Refuser...\' refusera le document sélectionné Cela peut arrêter le flux de travail.',
		instructionsMultiple: '\'Approuver\' donne l\'approbation de transmettre les documents sélectionnés aux signataires. Un sceau électronique sera inclus dans les documents. \'Refuser...\' refusera les documents sélectionnés. Cela peut arrêter le flux de travail.',
		view: 'Afficher',
		download: 'Télécharger',
		downloadAll: 'Téléchargez tout',

		attachment: 'Pièce jointe',
		approvalRequestState_NEW: 'En préparation',
		approvalRequestState_WAITING: 'En attente',
		approvalRequestState_APPROVED: 'Approuvé',
		approvalRequestState_DECLINED: 'Refusé',
		approvalRequestState_CLOSED: 'Cloturé',
		approvalRequestState_EXPIRED: 'Expiré',
		approvalRequestState_OBSOLETE: 'Obsolète',

		approve: 'Approuver',
		declineDialog: 'Refuser...',
		decline: 'Refuser',
		declineHeader: 'Refus d\'approbation',
		declineWhyHeader: 'Refuser votre demande d\'approuver',
		declineWhyDetails: 'En confirmant votre refus sera enregistré. Selon les paramètres du document, cela peut mettre fin au flux de travail prématurément. Sinon, d\'autres approbateurs peuvent toujours approuver le document. Veuillez noter que cette action est irréversible.',
		declineReasonHeader: 'Donnez votre raison',
		declineReasonDetails: 'Décrivez brièvement la raison pour laquelle vous refusez l\'approbation du document. Ce champ est obligatoire.',
		declineReasonLabel: 'Raison',

		everythingApprovedDeclined: 'Tout a été approuvé/refusé',
		documentsDeclined: '{0} document(s) a(ont) été refusé(s)',
		documentsApproved: '{0} document(s) a(ont) été approuvé(s)',
		approvers: 'Approbateurs',
		approversDescription: 'Liste des approbateurs du document:',
		signers: 'Signataires',
		signersDescription: 'Liste des signataires du document:',
	},

	signing: {
		signHeaderSingle: 'Signez le document',
		signHeaderMultiple: 'Signez les documents',
		declineHeaderSingle: 'Refuser le document',
		declineHeaderMultiple: 'Refuser les documents',
		instructionsSingle: 'Vous trouverez ci-dessous le document à signer. Cliquez sur \'Signer\' et suivez les étapes.',
		instructionsMultiple: 'Vous trouverez ci-dessous les documents à signer. Cliquez sur \'signer\' et suivez les étapes. L\'œil indique quel document est affiché dans la fenêtre. Vous pouvez sélectionner le document à visualiser en cliquant sur \'Afficher\'. ',

		attachment: 'Pièce jointe',
		signRequestState_NEW: 'En préparation',
		signRequestState_WAITING_FOR_SIGNATURE: 'En attente',
		signRequestState_SIGNED: 'Signé',
		signRequestState_DECLINED: 'Refusé',
		signRequestState_CLOSED: 'Cloturé',
		signRequestState_EXPIRED: 'Expiré',
		signRequestState_OBSOLETE: 'Obsolète',

		view: 'Afficher',
		download: 'Télécharger',
		downloadAll: 'Téléchargez tout',
		location: 'Votre signature sera placée ici',
		locationParaph: 'Vos initiales seront placées ici',
		readAllDocuments: 'J\'ai lu tous les documents',

		signingMethodChoose: 'Choisissez une méthode de signature',
		signingMethod_ELECTRONIC_WITH_WITNESS_SEAL: 'Sceau électronique',
		signingMethod_BELGIAN_EID: 'Signature avec carte d\'identité belge',
		signingMethod_ITSME: 'itsme\u00AE qualifiée',
		signingMethod_ITSME_ADVANCED: 'itsme\u00AE avancée',
		signingMethod_SMS_OTP: 'Signature OTP via SMS',
		signingMethod_EMAIL_OTP: 'Signature OTP via e-mail',
		signingMethod_HANDWRITTEN: 'Signature manuscrite',
		signingMethodNoBulkSigning: 'Actuellement, cette méthode ne permet pas de signer plus de 70 documents à la fois. Seuls les 70 premiers documents seront signés.',
		signingMethodDisabledDevice: 'Cette méthode n\'est pas compatible avec votre appareil',
		signingMethodNoSameDocuments: 'Cette méthode ne permet pas de signer simultané du même document. Seule la première instance du document sera signée.',
		signingMethodDiffers: 'Certains documents ont des méthodes de signature différentes, ce qui peut nécessiter plusieurs sessions de signature.',

		acquiringLock: 'Verrouiller les documents',
		acquiringLockSingle: 'Verrouillage du document',
		acquiringLockMultiple: 'Verrouillage des documents',
		acquiringLockFailed: 'Tous les documents ne peuvent pas être verrouillés; quelqu\'un d\'autre est actuellement en train de signer.',
		acquiringLockQueued: 'Il y a trop de demandes de signature en ce moment, pour réduire la charge, vous êtes placé dans une file d\'attente.',
		acquiringLockQueuedPosition: 'Position dans la file d\'attente:',

		eidCardHeader: 'Placez votre eID dans le lecteur de carte',
		eidCardNotFound: 'Veuillez insérer votre eID',
		eidCardFound: 'eID détectée',
		eidEnterPin: 'Entrez votre code pin',
		eidMiddlewareNotRunning: 'Vous devez installer Dioss eID Middleware sur votre ordinateur avant de poursuivre l\'authentification de la carte eID.',
		eidMiddlewareDownloadHere: 'Téléchargez le Dioss eID middleware ici:',
		eidWrongPin: 'Code pin erroné',
		eidPinTimeoutOrCancel: 'Vous n\'avez pas saisi à temps le code PIN ou il a été annulé',
		eidSignError: 'Une erreur s\'est produite lors de la lecture de la carte eID. Nous vous suggérons de retirer la carte et de réessayer, ou d\'utiliser un autre lecteur.',
		eidExternalPinPad: 'Veuillez saisir le code PIN sur le lecteur de carte lorsque vous y êtes invité',
		eidExternalPinDialog: 'Veuillez saisir le code PIN dans la fenêtre d\'entrée externe du code PIN lorsque vous y êtes invité',

		smsOtpAuthenticate: 'Authentifiez-vous',
		smsOtpAuthenticateDescription: 'Veuillez saisir les 4 derniers chiffres de votre numéro de GSM afin que nous puissions confirmer votre identité et vous envoyer votre code d\'identification par SMS.',
		smsOtpAuthenticateLabel: 'Les 4 derniers chiffres de votre numéro de GSM',
		smsOtpAuthenticateSend: 'Envoyer le code d\'identification',
		smsOtpAuthenticateSendAgain: 'Envoyer à nouveau',
		smsOtpAuthenticateSuccess: 'Le code d\'identification a été envoyé avec succès',
		smsOtpChallenge: 'Entrez le code d\'identification que vous avez reçu par SMS',
		smsOtpChallengeLabel: 'Votre code',

		emailOtpChallenge: 'Cliquez sur \'Envoyer le code d\'identification\' pour recevoir par e-mail votre code d\'identification et introduisez-le ensuite.',
		emailOtpAuthenticateSend: 'Envoyer le code d\'identification',
		emailOtpAuthenticateSendAgain: 'Envoyer à nouveau',
		emailOtpChallengeLabel: 'Votre code d\'identification',

		handwrittenSignature: 'Veuillez dessiner ou télécharger une signature manuscrite',

		reasonHeader: 'Motivez votre raison',
		reasonLabel: 'Reason',
		reasonLegalNoticeLabel: 'Mention légale',
		reasonLegalNoticeMandatoryText: 'Pour ces documents vous devez copier la mention légale obligatoire ci-dessous, celle-ci est ajoutée à la signature électronique et est visiblement affichée sur le document.',
		reasonLegalNoticeText: 'Veuillez saisir le texte suivant',
		reasonLegalNoticeMandatory: 'Vous devez saisir une mention légale obligatoire, qui s\'ajoute à la signature électronique et est visible sur le document.',
		reasonOptional: 'Vous pouvez éventuellement saisir un motif, celui-ci sera ajouté à la signature électronique et ne sera pas visible sur le document.',
		reasonDisabledForSomeDocumentsContent: 'Pour les autres documents, l\'option d\'ajouter une raison de motivation est désactivée. Votre raison ne sera pas appliquée sur ces documents.',

		remarkHeader: 'Ecrivez une remarque sur le document',
		remarkOptional: 'La remarque est ajoutée visuellement à la signature (p. ex., lu et approuvé). La saisie d\'une remarque est facultative.',
		remarkLabel: 'Remarque',
		remarkDisabledForSomeDocumentsContent: 'Pour certains des documents sélectionnés, l\'option d\'ajouter une remarque est désactivée. Votre remarque ne sera pas appliquée sur ces documents.',

		declineInfoSingle: 'Vous avez demandé de refuser de signer votre document.',
		declineInfoMultiple: 'Vous avez demandé de refuser de signer vos documents.',
		declineInfoDetailsSingle: 'En refusant de signer ce document, vous ne pourrez plus revenir sur votre décision et vous ne pourrez plus le signer.',
		declineInfoDetailsMultiple: 'En refusant de signer ces documents, vous ne pourrez plus revenir sur votre décision et vous ne pourrez plus les signer.',
		declineReasonDetails: 'Veuillez fournir la raison justifiant votre refus de signer. Ce champ est obligatoire.',
		declineReasonLabel: 'Raison',
		declineAttachment: 'Joindre un fichier',
		declineReasonUploadError: 'Format de document non accepté. Veuillez joindre un fichier du type suivant : .pdf, .jpeg, .tiff, .doc, .docx .xls, .xlsx, .xlsb, .xlsb',

		itsmeSigningLabel: 'Signer avec itsme®',
		itsmeSigningDetails: 'En signant avec itsme®, la signature sera créée à l\'aide de votre certificat personnel itsme®. Vous aurez besoin d\'un compte itsme® et de l\'application itsme® sur votre téléphone. Si vous n\'avez pas encore configuré votre identité itsme®, veuillez d\'abord visiter ce lien pour le faire: $link;https://www.itsme.be/;www.itsme.be$',
		itsmeForgetUserCodeDetails: 'Votre identité itsme® a été mémorisé lors d\'une précédente session. Cela vous permet d\'éviter l\'étape d\'identification. Vous pouvez cliquer sur le bouton "oublier mon identité itsme®" pour l\'effacer. Itsme® vous demandera alors de vous identifier à nouveau. Vous avez la possibilité d\'enregistrer cette nouvelle identité.',
		itsmeForgetUserCode: 'Oublier mon identité itsme®',
		itsmeLinkUserCodeDetails: 'La première fois vous signerez avec itsme® dans Quill, vous devrez vous identifier. Vous pouvez choisir de mémoriser votre identité itsme® dans Quill pour éviter cette étape pour des signatures suivantes. Il est possible de supprimer ce lien ultérieurement. Pour les signatures via une adresse e-mail partagée par plusieurs signataires, il est conseillé de laisser l\'option désactivée.',
		itsmeLinkUserCode: 'Mémoriser mon identité itsme®',
		itsmeDirections: 'Vous serez redirigé pour effectuer la signature itsme®. Veuillez garder cette fenêtre ouverte jusqu\'à la fin de la signature. Une fois la signature validée, vous êtes redirigé vers Quill et la signature sera placée sur le document.',
		itsmeSessionBusyTitle: 'La signature avec itsme® est en cours',
		itsmeSessionBusyStarted: 'Vous serez rediregé pour effectuer la signature itsme®...',
		itsmeSessionBusySigning: 'Votre signature validée est récupérée d\'itsme® et apposée sur le document...',
		itsmeRedirectError: 'Un problème est survenu lors de la gestion de la session de signature, veuillez réessayer...',

		redirectTitle: 'Redirection..',
		redirectDetails: 'Vous serez redirigé dans les 5 secondes. Cliquez $link;ici$ si cela ne se produit pas automatiquement.',

		sign: 'Signer',
		decline: 'Refuser',
		forward: 'Transmettre',
		signingBusy: 'Signature en cours...',
		decliningBusy: 'Refus en cours...',

		everythingSignedDeclined: 'Vous avez traité tous les documents.',
		everythingSignedSingle: 'Tout est en ordre. Votre signature a bien été apposée sur le document',
		everythingSignedMultiple: 'Tout est en ordre. Votre signature a bien été apposée sur les documents',
		everythingDeclined: 'Tout a été refusé',
		documentsDeclinedSingle: 'Document a été rejeté',
		documentsDeclinedMultiple: '{0} documents ont été rejetés',
		documentsSignedSingle: 'Document a été signé',
		documentsSignedMultiple: '{0} documents ont été signés',
		approvers: 'Approbateurs',
		approversDescription: 'Liste des approbateurs du document:',
		documentDeleted: 'Le document est supprimé',

		capacities: 'Qualités',
		capacitiesInfo: 'Vous pouvez sélectionner (ou remplir) la qualité pour chaque champ de signature via le tableau ci-dessous. Vous pouvez également le laisser vide si vous ne souhaitez lier aucune qualité à la signature.',
		capacitiesDocumentName: 'Nom du document',
		capacitiesPage: 'Page',
		capacitiesSelectedTitle: 'Qualité sélectionnée',
		capacitiesSelectedRemark: 'Remarque sélectionnée',
		continue: 'Continuer',

		documents: 'Sélection des documents',
		documentsInfo: 'En dessous, les documents que vous souhaitez {0} peuvent être sélectionnés.',
		documentsInfoSign: 'signer',
		documentsInfoDecline: 'rejeter',
		documentName: 'Nom du document',

		forwardHeader: 'Transmettre des documents',
		forwardSelectSignRequests: 'Sélectionner les demandes à transmettre',
		forwardSelectTargetUser: 'Choisissez la personne à qui transmettre les demandes',
		forwardUseDelegate: 'Utiliser les règles de délégation par défaut',
		forwardUserDetails: 'Envoyer à une personne spécifique',
		forwardEmail: 'E-mail',
		forwardFirstName: 'Prénom',
		forwardLastName: 'Nom de famille',
		forwardRemarkHeader: 'Message au destinataire de la demande (facultatif)',
		forwardRemark: 'Remarque',
		forwardSuccess: 'Demandes transmises avec succès',

		formFields: 'Champs à remplir sur le document',
		formFieldsDocumentName: 'Nom',
		formFieldsFill: 'Remplir',
		formFieldsAttribute_FIRST_NAME: 'Prénom',
		formFieldsAttribute_LAST_NAME: 'Nom de famille',
		formFieldsAttribute_EMAIL: 'E-mail',
		formFieldsAttribute_MOBILE_NUMBER: 'Numéro de portable (SMS OTP)',
		formFieldsAttribute_MEMO: 'Mémo',
		formFieldsAttribute_META_FIELD: 'Attribut méta',
		formFieldsGoTo: 'Aller au champ sur la page',
		formFieldsRequired: 'Certains champs obligatoires du (des) document(s) doivent encore être remplis.',
		formFieldsValidValueRequired: 'Certains champs à remplir ont une valeur invalide.',
		formFieldsValueInvalid: 'Valeur invalide',

		required: 'Obligatoire*',

		visualSignatureSaveAsDefault: 'Enregistrer comme signature par défaut',
		visualParaphSaveAsDefault: 'Enregistrer comme initiales par défaut',

		signingKioskHeader: 'Signataires du kiosque',
		signingKioskInstructions: 'Les utilisateurs suivants font partie des documents du kiosque. Sélectionnez un utilisateur pour afficher et/ou lancer la signature du (des) document(s) pertinent(s).',
		signingKioskPendingChangesLabel: 'Vous êtes sur le point de quitter le mode kiosque. Êtes-vous sûr de vouloir continuer?',
		signingKioskPendingChangesSaveAndLeaveLabel: 'Continuer',

		guestAccessRenew: 'Renouveller',
		guestAccessExpired: 'Le lien pour signer le document a expiré. Veuillez cliquer sur le bouton "Renouveler" ci-dessous pour recevoir un nouveau lien par e-mail. Le nouveau lien sera valide pendant {{duration}}',
		guestAccessRenewedSuccessfully: 'Un nouveau lien pour accéder au document a été envoyé par e-mail'
	},

	editor: {
		editDocumentHeader: 'Modifier le document',
		editTemplateHeader: 'Modifier le modèle',
		editCollectionHeader: 'Modifier la collection',
		editVirtualCollectionHeader: 'Modifier plusieurs documents',

		collectionAllDocuments: 'Tous les documents en ',

		actionLogs: 'Actions sur le document',
		save: 'Enregistrer',
		send: 'Envoyer',
		sign: 'Signer',
		sendAndSign: 'Envoyer et signer',
		sent: 'Document a été envoyé',
		sendingFailed: 'Le document n\'a pas pu être envoyé',
		sentButNothingToSign: 'Document a été envoyé. Il n\'y a rien à signer.',
		close: 'Révoquer',
		delete: 'Supprimer',
		restart: 'Redémarrer',
		restarted: 'Document a été redémarré',
		closeConfirm: 'Êtes-vous sûr de vouloir révoquer les demandes d\'approbation/signature de ce document ? En conséquence, ce document sera fermé pour tous les approbateurs/signataires.',
		documentDeleteConfirm: 'Voulez-vous vraiment supprimer ce document? Le document sélectionné sera supprimé pour tous les utilisateurs.',
		documentDeleteCollectionConfirm: 'Voulez-vous vraiment supprimer cette collection dans son intégralité? Les documents sélectionnés seront supprimés pour tous les utilisateurs.',
		templateDeleteConfirm: 'Voulez-vous vraiment supprimer ce modèle?',
		tab_OVERVIEW: 'Aperçu',
		tab_GENERAL: 'Général',
		tab_APPROVERS: 'Approbateurs',
		tab_SIGNERS: 'Signataires',
		tab_MULTI_SIGN: 'Multi-Sign',
		tab_POST_ACTIONS: 'Actions après signer',
		signingOrder_SERIAL: 'Sériel',
		signingOrder_PARALLEL: 'Parallèle',
		signingOrder_KIOSK: 'Kiosque',
		approveOrder_SERIAL: 'Sériel',
		approveOrder_PARALLEL: 'Parallèle',
		orderUp: 'Vers le haut',
		orderDown: 'vers le bas',

		overviewApprovers: 'Approbateurs',
		overviewApproversDescription: 'Liste des approbateurs',
		overviewSigners: 'Signataires',
		overviewSignersDescription: 'Liste des signataires',
		overviewMultisigners: 'Multi-sign',
		overviewMultisignersDescription: 'Liste des signataires',
		overviewDocuments: 'Documents',
		overviewDocumentsDescription: 'Liste des sous-documents',

		generalAttachment: 'Le document est une pièce jointe',
		generalAttachmentHasIncompleteSignersWarning: 'Ce document n\'a pas été signé par tous les signataires. Si vous configurez ce document en pièce jointe, ces signataires seront perdus.',
		generalDescription: 'Description du document',
		generalDescriptionMandatory: 'La description du document est obligatoire',
		generalFolder: 'Dossier',
		generalConfidentiality: 'Confidentialité',
		generalConfidentialitySet: 'Ce document n\'est visible que pour le créateur, les signataires et les approbateurs.',
		generalConfidentialWarningWhenNotCreator: 'Veuillez noter que vous n\'êtes pas le propriétaire du document ; si vous rendez le document confidentiel, vous n\'aurez plus accès à ce document.',
		generalPdf: 'PDF',
		generalPdfRecreated: 'Il y avait quelques problèmes de compatibilit\é avec la structure de ce document PDF qui ont été corrigés automatiquement.',
		generalPdfADetected: 'Conformité PDF/A détectée',
		generalPdfADetectedNone: 'Aucune',
		generalPdfADetectedNoneItsme: 'Une ou plusieurs signatures peuvent être ou ont été placées avec itsme\u00AE mais le document nést pas conforme au format PDF/A. Notez que itsme\u00AE recommande la conformité PDF/A.',
		generalMotivation: 'Motivation',
		generalOverrideLegalNoticeSettings: 'Remplacer les paramètres de motivation par défaut',
		generalReasonAvailable: 'Signataire peut ajouter une raison de motivation',
		generalReasonLegalNoticeMandatory: 'Faites-en une mention légale obligatoire pour que l\'utilisateur puisse entrer. Cela apparaîtra également visuellement sur le document.',
		generalReasonLegalNoticeText: 'Mention légale',
		generalExpiration: 'Expiration',
		generalExpirationOverride: 'Remplacer les paramètres d\'expiration par défaut',
		generalExpirationEnable: 'Permettre l\'expiration des approbations et des signatures',
		generalExpirationDays: 'jour(s) avant l\'expiration des approbations et signatures incomplètes',
		generalForwarding: 'Transmission',
		generalAllowForwarding: 'Autoriser la transmissions des demandes',
		generalTemplatePages: 'Pages de modèle',
		generalTemplateOptions: 'Options du modèle',

		approvalOrder: 'Ordre d\'approbation',
		approvalList: 'Liste des approbateurs',
		approvalListEmpty: 'Aucun approbateur configuré',
		approvalDelete: 'Supprimer cet approbateur',
		approvalAddApprover: 'Ajouter un approbateur',
		approvalAddSignerGroup: 'Ajouter un groupe d\'approbateurs',
		approvalImportApprovers: 'Importer des approbateurs',
		approvalDeclineReason: 'Raison du déclin',
		approvalSeal: 'Approuver avec sceau électronique',
		approvalRequired: 'Approbation obligatoire',
		approvalNumberApproversToDecide: 'Nombre d\'approbateurs à décider (approuver ou rejeter)',
		approvalNumberApproversToDecideError: 'Le nombre doit être supérieur ou égal au nombre d\'approbateurs requis, mais inférieur ou égal au nombre total d\'approbateurs',
		approvalNumberApproversToApprove: 'Nombre d\'approbateurs qui doivent approuver le document',
		approvalNumberApproversToApproveError: 'Le nombre doit être supérieur ou égal au nombre d\'approbateurs requis, mais inférieur ou égal au nombre d\'approbateurs qui doivent décider',
		approvalNumberSerial: 'En ordre sériel, le nombre n\'est pas r\u00E9glable',

		signingList: 'Liste des signataires',
		signingListEmpty: 'Aucun signataire configuré',
		signingPage: 'Page',
		signingSignatureSettings: 'Configurer',
		signingDelete: 'Supprimer ce signataire',
		signingNotPlaced: 'Certains champs de signature n\'ont pas encore été placés sur le document, vous pouvez le faire en faisant glisser le champ du signataire vers la bonne page',
		signingAddSigner: 'Ajouter un signataire',
		signingAddSignerGroup: 'Ajouter un groupe de signataires',
		signingImportSigners: 'Importer des signataires',
		signingChangeSignatureTypes: 'Personnaliser les méthodes de signature',
		signingBulkSignatureTypes: 'Personnalisez les méthodes de signature pour tous les signataires',
		signingSignatureTypeError: 'Veuillez choisir une méthode de signature',
		signingSmsOtpNumberError: 'Choisir numéro pour le SMS OTP',
		signingSmsOtpKnownNumber: 'Utilisez le numéro connu de ce signataire, ce numéro se termine par $SUFFIX.',
		signingSmsOtpEnterNumber: 'Utilisez un nouveau numéro, ce numéro sera enregistré afin de pouvoir être réutilisé ultérieurement',
		signingMethod_ELECTRONIC_WITH_WITNESS_SEAL: 'Sceau électronique',
		signingMethod_BELGIAN_EID: 'Signature d\'identité belge',
		signingMethod_ITSME: 'Signature itsme\u00AE qualifiée',
		signingMethod_ITSME_ADVANCED: 'Signature itsme\u00AE avancée',
		signingMethod_SMS_OTP: 'Signature OTP via SMS',
		signingMethod_HANDWRITTEN: 'Signature manuscrite',
		signingSignatureFieldOptions: 'Options de champ de signature',
		signingSignatureFieldDelete: 'Supprimer',
		signingSignatureFieldDeleteAll: 'Supprimer tout',
		signingSignatureFieldSettings: 'Configuration',
		signingParaphField: 'Champ initial',
		signingParaphFieldDescription: 'Les champes d\'initiales peuvent être dupliquées sur d\'autres pages (sans champ de signature) en cliquant sur elles avec le bouton droit de la souris et en sélectionnant \'dupliquer sur d\'autres pages\'.',
		signingParaphFieldPlaceOnDocument: 'Faites glisser sur une page pour placer un champ d\'initials',
		signingParaphFieldDeleteAll: 'Supprimer tous les champs d\'initiales',
		signingParaphFieldDuplicate: 'Dupliquer sur d\'autres pages',
		signingExtraSignatureField: 'Champs de signature visuelle supplémentaires',
		signingExtraSignatureFieldPage: 'Page',
		signingExtraSignatureFieldPlaceOnDocument: 'Faites glisser ce champ sur une page pour placer un champ visuel de dessin manuel supplémentaire.',
		signingFormField: 'Champs à remplir',
		signingFormFieldPage: 'Page',
		signingFormFieldPlaceOnDocument: 'Faites glisser ce champ sur une page pour ajouter un nouveau champ à remplir au document.',
		signingDistributeSignerInCollection: 'Définir ce champ dans tous les documents de cette collection',
		signingCapacity: 'Qualité de signataire',
		signingPlaceholder: 'Signataire à attribuer',
		signingPlaceholderAdd: 'Ajouter un espace réservé pour le signataire',
		signingChooseSigner: 'Sélectionnez le signataire',
		signingOrderGroupSettings: 'Paramètres du groupe de signataires',
		signingOrderGroupDrag: 'Faites glisser pour modifier l\'ordre ou pour grouper des signataires',
		signingOrderGroupDisband: 'Dissocier',
		signingOrderGroupMinimalNumberOfSignersDescription: 'Nombre minimum de signataires. Ce nombre doit être supérieur ou égal au nombre de signataires obligatoires et doit également être au moins égal à 1.',
		signingOrderGroupMinimalNumberOfSigners: 'Nombre de signataires',
		signingOrderGroupRequiredSigners: 'Signataires obligatoires',
		signingFieldPlaceholder_SIGNATURE: 'Espace réservé à la signature',
		signingFieldPlaceholder_PARAPH: 'Espace réservé aux initiales',
		signingFieldPlaceholder_FORM: 'Espace réservé au champ',
		signingKioskMode: 'Mode kiosque',
		signingKioskModeConfirm: 'En mode kiosque, il ne peut y avoir qu\'un seul utilisateur enregistré ajouté en tant que signataire, le nombre d\'utilisateurs invités est illimité. Les approbateurs et les multi-signataires seront automatiquement rejetés. Êtes-vous sûr de vouloir continuer?',
		signingKioskModeRegisteredUserError: 'Plusieurs utilisateurs enregistrés sont ajoutés en tant que signataires du document kiosque. Il ne peut y avoir qu\'un seul utilisateur enregistré (et plusieurs utilisateurs invités) ajouté en mode kiosque.',
		signingExpiry: 'Validité du lien d\'invitation',
		signingExpiryDescription: 'Vous pouvez configurer ici la durée, en minutes, pendant laquelle le lien d\'invitation est valide après l\'envoi. Une fois expiré, l\'utilisateur invité devra en demander un nouveau.',
		signingExpiryError: 'La durée minimale est de 10 minutes et la durée maximale est de 1440 minutes.',
		signingExpiryReset: 'Pas d\'expiration',

		formFieldTypeDescription: 'Type de champ',
		formFieldType_TEXT: 'Texte',
		formFieldType_CHECKBOX: 'Checkbox',
		formFieldType_DATE: 'Date',
		formFieldType_NUMERIC: 'Numérique',
		formFieldType_ATTRIBUTE: 'Attribut de signataire',
		formFieldAttributeType_FIRST_NAME: 'Prénom',
		formFieldAttributeType_LAST_NAME: 'Nom de famille',
		formFieldAttributeType_EMAIL: 'E-mail',
		formFieldAttributeType_MOBILE_NUMBER: 'Numéro de mobile (OTP via SMS)',
		formFieldAttributeType_MEMO: 'Mémo',
		formFieldAttributeType_META_FIELD: 'Attribut méta',
		formFieldAttributeTypeUsage: 'La valeur de cet attribut est automatiquement appliquée au moment de la signature. Le signataire ne peut pas modifier la valeur',
		formFieldAttributeTypeDescription: 'Type de l\'attribut',
		formFieldNameDescription: 'Nom du champ',
		formFieldName: 'Nom',
		formFieldMetaAttributeUnknown: 'Attribut inconnu, l\'ajouter aux l\'attributs méta de l\'utilisateur avant d\'envoyer le document.',
		formFieldValueDescription: 'Valeur du champ',
		formFieldValue: 'Valeur',
		formFieldOptions: 'Options',
		formFieldEditable: 'Modifiable par le signataire',
		formFieldRequired: 'Obligatoire',
		formFieldStyle: 'Options de style',
		formFieldValidator: 'Validateur',
		formFieldValueInvalid: 'Valeur invalide pour le validateur',

		emailMessageHeaderSigners: 'Le message à envoyer par e-mail aux signataires',
		emailMessageHeaderApprovers: 'Le message à envoyer par e-mail aux approbateurs',
		emailMessageDescriptionSigners: 'Chaque signataire recevra un e-mail avec la demande de signature du document. Le message personnalisé ci-dessous dans la langue préférée du signataire sera inclus dans l\'e-mail. $signer agit comme un espace réservé et sera remplacé par le nom réel du signataire à qui ce courrier sera envoyé. $document sera remplacé par le nom du document. Veuillez modifier le message si vous préférez un autre message.',
		emailMessageDescriptionApprovers: 'Chaque approbateur recevra un e-mail avec la demande de signature du document. Le message personnalisé ci-dessous dans la langue préférée de l\'approbateur sera inclus dans l\'e-mail. $approver agit comme un espace réservé et sera remplacé par le nom réel de l\'approbateur à qui ce courrier sera envoyé. $document sera remplacé par le nom du document. Veuillez modifier le message si vous préférez un autre message.',
		emailMessageResetCurrentLang: 'Message par défaut ($LANGUAGE)',
		emailMessageResetAll: 'Message par défaut (chaque langue)',

		multiSignDescription: 'Une nouvelle copie du document est créée pour chaque signataire défini ci-dessous. Le signataire doit signer dans la zone de dessin.',
		multiSignDefaultSignatureTypes: 'Sélectionnez les méthodes de signature par défaut',
		multiSignPlaceSignatureField: 'Placez le champ Multi-Sign sur le document',
		multiSignDownload: 'Télécharger',

		postActionsDocumentEmailDistributionList: 'Document liste de distribution des e-mails après signer (sans rapport de preuve)',
		postActionsDocumentAndEvidenceReportEmailDistributionList: 'Document liste de distribution des e-mails après signer (avec rapport de preuve)',
		postActionsDistributionListAddExistingUser: 'Ajouter un e-mail d\'un utilisateur connu',
		postActionsDistributionListCustom: 'Ajouter un e-mail personnalisé',
		postActionsDistributionListAdd: 'Ajouter',
		postActionsDistributionListAddEvidenceReport: 'Inclure le rapport de preuve dans le mail',
		postActionsDistributionListRemoveDistributionList: 'Effacer la liste',

		actionLogPerformedAt: 'Horodatage',
		actionLogEvent: 'Événement',
		actionLog_CREATED: 'Document créé par {0}',
		actionLog_NAME_MODIFIED: 'Nom du document modifié de {0} à {1} par {2}',
		actionLog_SAVED: 'Document enregistré par {0}',
		actionLog_VIEWED: 'Document consulté par {0}',
		actionLog_DOWNLOADED: '{1} téléchargé par {0}',
		actionLog_DELEGATED_TO_APPROVER: 'Document renvoyé du {0} au {1} pour approbation',
		actionLog_DELEGATED_TO_APPROVER_POWER_OF_ATTORNEY: 'Document renvoyé du {0} au {1} pour approbation (soutenu par le procuration {2})',
		actionLog_INVITED_TO_APPROVE: 'Invitation à approuver envoyée à {0} par {1}',
		actionLog_APPROVED: 'Document approuvé par {0}',
		actionLog_NOT_APPROVED: 'Document rejeté par {0} avec raison {1}',
		actionLog_APPROVER_REMOVED: 'L\'approbateur {0} a été supprimé de ce document',
		actionLog_DELEGATED_TO_SIGNER: 'Document renvoyé du {0} au {1} pour signature',
		actionLog_DELEGATED_TO_SIGNER_POWER_OF_ATTORNEY: 'Document renvoyé du {0} au {1} pour signature (soutenu par le procuration {2})',
		actionLog_INVITED: 'Invitation pour signer envoyée à {0} par {1}',
		actionLog_INVITED_FOR_KIOSK: '{0} invité par {1} à signer au kiosque',
		actionLog_SIGNED: 'Document signé par {0} avec {1}',
		actionLog_SIGNING: 'Signature du document commencée par {0}',
		actionLog_SIGNING_FAILED_USER_MISMATCH: 'La signature du document a échoué: les données du signataire prévu pour {0} ne sont pas correctes',
		actionLog_STOPPED: 'Signature de document arrêtée par {0}',
		actionLog_RESTARTED: 'Document redémarré par {0}',
		actionLog_DELETED: 'Document supprimé par {0}',
		actionLog_DECLINED: 'Signature refusé par {0} avec raison {1}',
		actionLog_REMINDER_SENT: 'Rappel de signer envoyée à {0} par {1}',
		actionLog_EXPIRED: 'Document a expiré',
		actionLog_CONVERTED_TO_ATTACHMENT: 'Document converti en pièce jointe lors du redémarrage par {0}',
		actionLog_CHILD_DOCUMENT_CREATED: 'Document {0} créé sur la base de {1}',
		actionLog_CHILD_DOCUMENT_SIGNED: 'Sous-document {0} signé par {1} avec {2}',
		actionLog_CHILD_DOCUMENT_DECLINED: 'Sous-document {0} refusé par {1}',
		actionLog_CHILD_DOCUMENT_APPROVED: 'Sous-document {0} approuvé',
		actionLog_CHILD_DOCUMENT_NOT_APPROVED: 'Sous-document {0} non approuvé',
		actionLog_CHILD_DOCUMENT_CONVERTED_TO_ATTACHMENT: 'Sous-document {0} converti en pièce jointe',
		actionLog_TEMPLATE_CREATED: 'Modèle créé par {0}',
		actionLog_TEMPLATE_NAME_MODIFIED: 'Nom du modèle modifié de {0} à {1} par {2}',
		actionLog_TEMPLATE_SAVED: 'Modèle enregistré par {0}',
		actionLog_DOCUMENT_REPLACED: 'Document remplacé avec {0} par {1}',
		actionLog_CHILD_DOCUMENT_REPLACED: 'Sous-document {0} remplacé avec {1} par {2}',
		actionLog_CHILD_DOCUMENT_ADDED_TO_COLLECTION: 'Sous-document {0} ajouté par {1}',
		actionLog_CHILD_DOCUMENT_REMOVED_FROM_COLLECTION: 'Sous-document {0} supprimé par {1}',

		downloadArtifact_DOCUMENT: "Document",
		downloadArtifact_EVIDENCE_REPORT: "Rapport de preuve",

		templateApply: 'Appliquer un modèle au document',
		templateSave: 'Enregistrer le modèle du document',
		templateApplySignersApproversPlaced: 'Les paramètres du modèle on été appliqués au document. {0} signataire(s) et {1} approbateur(s) ajoutés',
		templateNameDescription: 'Donnez un nom au modèle',
		templateName: 'Nom',
		templateNameSaveDescription: 'Enregistrer le modèle',
		templateNameSaveLabel: 'L\'enregistrement d\'un modèle ne modifie pas votre document existant. N\'oubliez donc pas d\'enregistrer ou d\'envoyer également votre document actuel.',
		templateSaved: 'Le modèle a été enregistré',

		templateOptions: 'Options du modèle',
		templateOption: 'Option',
		templateOption_CONFIDENTIALITY: 'Confidentialité',
		templateOption_APPROVERS: 'Approbateurs',
		templateOption_APPROVERS_MAIL: 'Message e-mail des approbateurs',
		templateOption_SIGNERS: 'Signataires',
		templateOption_SIGNERS_MAIL: 'Message e-mail des signataires',
		templateOption_POSTSIGN_DISTRIBUTION_LIST_WITHOUT_EVIDENCE_REPORT: 'Liste de distribution des e-mails après signer (sans rapport de preuve)',
		templateOption_POSTSIGN_DISTRIBUTION_LIST_WITH_EVIDENCE_REPORT: 'Liste de distribution des e-mails après signer (avec rapport de preuve)',
		templateWarningApproversInCollectionChild: 'Tous les approbateurs existants dans les sous-documents sont supprimés lorsque les approbateurs sont appliqués à une collection à partir d\'un modèle',
		templateWarningSignersInCollectionChild: 'Tous les signataires existants dans les sous-documents seront supprimés lors de l\'application des signataires d\'un modèle sur une collection',

		changeDocumentTitle: 'Changement de document',
		changeDocumentUpload: 'Télécharger le document',
		changeDocumentSignaturesWarning: 'Le document actuel contient une ou plusieurs signatures. Celles-ci seront perdues lors du téléchargement d\'un nouveau document.',

		documentCollectionOverview: 'Documents de collection',
		documentChildAdd: 'Ajouter document',
		documentNameEdit: 'Modifier le nom',
		documentChange: 'Changement de document',
		documentChangePendingChanges: 'Il y a des modifications non enregistrées dans ce document. Êtes-vous sûr de vouloir modifier le document?',
		documentChangePendingChangesIgnoreChanges: 'Modifier le document, ignorer',
		documentChangePendingChangesSave: 'Modifier le document, enregistrer',
		documentRemove: 'Supprimer',

		fieldStyleTitle: 'Paramètres de style',
		fieldStyleFontSize: 'Taille du texte',
		fieldStyleBackgroundColor: 'Couleur d\'arrière-plan',
		fieldStyleBackgroundOpacity: 'Opacité de l\'arrière-plan',
		fieldStyleAlignment: 'Position du texte',
		fieldStyleTextAlignHorizontalLeft: 'Gauche (horizontal)',
		fieldStyleTextAlignHorizontalCenter: 'Centre (horizontal)',
		fieldStyleTextAlignHorizontalRight: 'Droite (horizontal)',
		fieldStyleTextAlignVerticalTop: 'Haut (vertical)',
		fieldStyleTextAlignVerticalCenter: 'Centre (vertical)',
		fieldStyleTextAlignVerticalBottom: 'Bas (vertical)',
	},

	signerGroup: {
		header: 'Groupe de signataires',
		create: 'Créer groupe de signataires',
		name: 'Nom',
		description: 'Description',
		actions: 'Actions',

		createName: 'Le nom du groupe de signataires',
		createDescription: 'La description du groupe de signataires',
		createFinish: 'Tout est complété?',
		createFinishDescription: 'Après la création du groupe de signataires des membres peuvent être ajoutés.',

		settings: 'Paramètres',
		settingsNameDescription: 'Nom et description du groupe de signataire',
		settingsFinish: 'Tout est complété?',
		settingsFinishDescription: 'Appuyer sur enregister pour conserver les changements',
		settingsSave: 'Enregister',

		users: 'Membres',
		userName: 'Nom',
		userEmail: 'E-mail',
		userAdd: 'Ajouter membre',
		userRemove: 'Supprimer membre',
		userRemoveConfirm: 'Voulez-vous vraiment supprimer ce membre?',
		userState: 'Statut',

		userSelect: 'Sélectionnez le(s) membre(s) à ajouter',
		usersDescription: 'Ajoutez ou supprimez les membres de ce groupe. Notez que ces modifications sont appliquées immédiatement.',

		delete: 'Supprimer',
		deleteConfirm: 'Voulez-vous vraiment supprimer ce groupe de signataires?',
		deleted: 'Groupe supprimé',
	},

	oidcInstance: {
		global: 'mondial',
		company: 'société',
		oidcInstancesFor: 'Configurations OIDC pour',

		generalConfiguration: 'Configurations générales OIDC',
		description: 'Ces configurations générales sont utilisées lorsqu\'aucune configuration OIDC spécifique à l\'entreprise n\'est applicable.',
		companyOverview: 'Configuration OIDC par société',
		companyOverviewDescription: 'Liste de toutes les configurations OIDC définies par les entreprises. La configuration se fait sur la page des paramètres de l\'entreprise.',
		settings: 'Paramètres',

		name: 'Nom',
		companyName: 'Nom de la société',
		serverUrl: 'Serveur URL',
		clientId: 'Client ID',
		clientSecret: 'Client secret',
		autoCreate: 'Création automatique',
		actions: 'Actions',

		create: 'Créer',
		edit: 'Modifier',
		delete: 'Supprimer',
		save: 'Enregistrer',

		createName: 'Nom de la configuration OIDC',
		serverConfiguration: 'Configuration du serveur',
		autoCreateDescription: 'Permettre la création automatique de nouveaux utilisateurs',
		logo: 'Logo',
		logoDescription: 'Les modifications du logo seront appliquées immédiatement. Représenté comme une image de 24 x 24 px.',
		logoUpload: 'Importer un nouveau logo (max 1MB)',
		logoDelete: 'Supprimé logo',

		createFinish: 'Tout est complété?',
		createFinishDescription: 'Après la création, des configurations supplémentaires peuvent être effectuées.',
		settingsFinishDescription: 'Appuyer sur enregister pour conserver les changements',

		deleteConfirm: 'Voulez-vous vraiment supprimer cette configuration OIDC?',
		deleted: 'configuration OIDC supprimée',
	},

	connector: {
		connectorsFor: 'Connecteurs pour',
		add: 'Ajouter un connecteur',
		edit: 'Modifier',
		delete: 'Supprimer',
		deleteConfirm: 'Voulez-vous vraiment supprimer ce connecteur?',
		connectorType_SFTP: 'SFTP',
		connectorType_SHAREPOINT: 'Sharepoint',

		sftpConfig: 'Configuration du SFTP',
		host: 'nom d\'hôte',
		port: 'Port',
		password: 'Mot de passe',

		sharepointConfig: 'Configuration du Sharepoint',
		tenant: 'Tenant',
		siteId: 'Site ID',
		clientId: 'Client ID',
		clientSecret: 'Client secret',
		uploadFileNameDocumentIdPrefix: 'Préfixe ID du document',
		uploadFileNameDocumentIdPrefixDescription: 'Préfixe nom de fichier du document téléchargé avec l\'ID du document',

		googleDriveConfig: 'Configuration de Google Drive',
		googleDriveServiceAccountJson: 'Fichier JSON du compte de service',
		googleDriveFolder_UPLOAD_COMPLETED_DOCUMENTS: 'L\'ID du dossier Google Drive dans lequel les fichiers terminés doivent être téléchargés',
		googleDriveFolder_DOWNLOAD_DOCUMENTS: 'L\'ID du dossier Google Drive à partir duquel les fichiers doivent être téléchargés',

		testConnection: 'Tester la connexion',
		test_AUTHENTICATION_FAILED: 'Authentification échoué',
		test_CONNECTION_REFUSED: 'Connexion refusée',
		test_CANNOT_CONNECT: 'La connexion n\'a pas pu être établie',
		test_CONNECTOR_NOT_FOUND: 'Connecteur pas trouvé',
		test_INVALID_CLIENT_ID: 'Client ID non valide',
		test_INVALID_CLIENT_SECRET: 'Client secret non valide',
		test_INVALID_CONFIG: 'Configuration non valide',
		test_INVALID_TENANT: 'Tenant ID non valide',
		test_SUCCESS: 'Connexion réussie',
		test_TOO_MANY_ATTEMPTS: 'Trop de tentatives de test, veuillez réessayer dans 1 minute',
		test_UNKNOWN_HOST: 'Hôte non trouvé',

		actionType: 'Type d\'action',
		folder: 'Dossier',
		user: 'Membre',
		actions: 'Actions',
		lastDiscoveryAt: 'Dernière découverte',
		lastSyncAt: 'Dernière sync',
		restart: 'Redémarrer',
		documentName: 'Nom du document',
		externalId: 'ID externe',
		state: 'Statut',
		syncAttempts: 'Tentatives de sync',
		lastUpdateAt: 'Dernière mise à jour',
		addActivity: 'Ajouter une activité',
		actionType_DOWNLOAD_DOCUMENTS: 'Télécharger des documents',
		actionType_UPLOAD_COMPLETED_DOCUMENTS: 'Téléverser les documents complétés',

		activitySettings: 'Paramètres d\'activité du connecteur',
		activityActionType: 'Type d\'activité',
		activityFolder: 'Dossier applicable',
		activityFolderName: 'Nom de dossier',
		activityFolderInfo: 'Spécifiez le dossier auquel cette activité doit s\'appliquer ou laissez le champ vide pour qu\'elle s\'applique à tous les dossiers.',
		activityUser: 'L\'utilisateur qui sera lié aux documents. Ci-dessous, vous pouvez sélectionner uniquement les dossiers dans lesquels l\'utilisateur peut modifier des documents.',
		activityUserEmail: 'Email du membre',
		activityUserInfo: 'S\'assurer que le membre a les bonnes permissions pour chaque dossier auquel cette activité s\'applique',
		activityAdditionalConfiguration: 'Configuration supplémentaire',
		activitySettingsFinish: 'Tout est rempli?',
		activitySettingsFinishDescription: 'Appuyer sur enregister pour conserver les changements',
		activityDelete: 'Supprimer l\'activité',
		activityDeleteConfirm: 'Êtes-vous sûr de vouloir supprimer cette activité?',
		activityGrid: 'Activités du connecteur',

		instanceAdd: 'Ajouter un connecteur',
		instanceAddInfo: 'Vous ne pouvez avoir qu\'un seul connecteur de chaque type à la fois.',

		directory: 'Répertoire',
		sftpDirectory_DOWNLOAD_DOCUMENTS: 'Répertoire SFTP à partir duquel les documents seront téléchargés dans Quill',
		sftpDirectory_UPLOAD_COMPLETED_DOCUMENTS: 'Répertoire SFTP dans lequel les documents complétés seront téléversés',
		sharepointSite_DOWNLOAD_DOCUMENTS: 'Site Sharepoint à partir duquel les documents seront téléchargés dans Quill',
		sharepointSite_UPLOAD_COMPLETED_DOCUMENTS: 'Site Sharepoint dans lequel les documents complétés seront téléversés',
		sharepointDrive: 'Drive',
		sharepointDriveDescription: 'Drive Sharepoint (vide pour le drive par défaut)',
		sharepointParentPath_DOWNLOAD_DOCUMENTS: 'Dossier Sharepoint à partir duquel les documents seront téléchargés dans Quill. Si ce champ est laissé vide, tous les fichiers situés sous le dossier racine seront téléchargés.',
		sharepointParentPath_UPLOAD_COMPLETED_DOCUMENTS: 'Dossier Sharepoint dans lequel les documents seront téléchargés. Si le dossier n\'existe pas encore, il sera créé.',

		inventoryGrid: 'Articles traités',
		inventoryState_AWAITING_SYNC: 'Awaiting syncEn attente de sync',
		inventoryState_SYNCING: 'Synchroniser',
		inventoryState_SYNCED: 'Synchronisé',
		inventoryState_ERROR: 'Erreur',
	},

	eid: {
		login: 'Connexion eID',
		userProfileCardNumber: 'Pour utiliser cette fonctionnalité, le numéro de la carte d\'eID doit être défini dans le profil de l\'utilisateur.',
		cardHeader: 'Placez votre eID dans le lecteur de carte',
		cardNotFound: 'Veuillez insérer votre eID',
		enterPin: 'Entrez votre code pin',
		middleware: 'Dioss eID Middleware',
		middlewareNotRunning: 'Vous devez installer Dioss eID Middleware sur votre ordinateur avant de poursuivre l\'authentification de la carte eID.',
		middlewareDownloadHere: 'Téléchargez le Dioss eID middleware ici:',
		middlewareAdditionalSupport: 'Questions? Consultez notre $link;https://smartsolutions.dioss.com/en/products/eid-middleware/dioss-eid-middelware-faq;FAQ$',
		wrongPin: 'Vous avez saisi un code PIN incorrect. Il reste {0} essais.',
		pinTimeoutOrCancel: 'Vous n\'avez pas saisi à temps le code PIN ou il a été annulé',
		externalPinPad: 'Veuillez saisir le code PIN sur le lecteur de carte lorsque vous y êtes invité.',
		externalPinDialog: 'Veuillez saisir le code PIN dans la fenêtre d\'entrée externe du code PIN lorsque vous y êtes invité.',
		pincode: 'Code pin'
	},

	reminder: {
		success: 'Les rappels ont été envoyés',

		nothingToRemind: 'Aucune demande à rappeler',
		notSentReason_TOO_SOON: 'Les rappels ne peuvent être envoyés qu\'une fois par heure.',
		notSentReason_CANNOT_REMIND_REQUESTER: 'Impossible de s\'envoyer des rappels à soi-même',
		notSentReason_USER_REMINDER_NOTIFICATIONS_DISABLED: 'Vous avez réussi à déclencher un rappel, mais veuillez noter qu\'au moins un des signataires/approbateurs a désactivé ces notifications.',
		notSentReason_OTHER: 'Un problème s\'est produit lors de l\'envoi des rappels',
	},

	mail: {
		success: 'Mail(s) envoyé(s)',

		preview: 'Exemple',
		test: 'Envoyer test',
		templateType: 'Type',
		fieldOverride: 'Remplacer',

		emailFieldsLinkEdit: 'Ajouter / modifier un lien',
		emailFieldsImageEdit: 'Ajouter / modifier une image',
		emailFieldsRows: 'Lignes',

		templateSubject: 'Sujet',
		templateHeaderTitle: 'Titre',
		emailFieldsPlaceholder: 'Placeholder',
		emailFieldsPlaceholder_APPROVER: 'Approbateur',
		emailFieldsPlaceholder_ATTACHMENT_MAX_SIZE: 'Taille maximale de la pièce jointe',
		emailFieldsPlaceholder_COMPANY: 'Entreprise',
		emailFieldsPlaceholder_COMPANY_LOGO: 'Logo de l\'entreprise',
		emailFieldsPlaceholder_CONTENT_TABLE: 'Tableau de contenu',
		emailFieldsPlaceholder_CREATOR: 'Créateur',
		emailFieldsPlaceholder_CUSTOM_MESSAGES: 'Messages personnalisés',
		emailFieldsPlaceholder_DAILY_DIGEST_TABLE: 'Tableau du bulletin quotidien',
		emailFieldsPlaceholder_DATE: 'Date',
		emailFieldsPlaceholder_DEADLINE: 'Date limite',
		emailFieldsPlaceholder_DECLINE_REASON: 'Raison du déclin',
		emailFieldsPlaceholder_DECLINED_TABLE: 'Tableau des rejets',
		emailFieldsPlaceholder_DIOSS_LOGO: 'Dioss logo',
		emailFieldsPlaceholder_DOCUMENT: 'Document',
		emailFieldsPlaceholder_DOCUMENT_COUNT: 'Nombre de documents',
		emailFieldsPlaceholder_DOCUMENT_URL: 'Document URL',
		emailFieldsPlaceholder_OTP: 'OTP (code d\'identification)',
		emailFieldsPlaceholder_RECEIVER: 'Récepteur',
		emailFieldsPlaceholder_REJECT_REASON: 'Raison du rejet',
		emailFieldsPlaceholder_SIGNER: 'Signataire',
		emailFieldsPlaceholder_TITLE: 'Titre',
		emailFieldsPlaceholder_QUILL_LOGO: 'Quill logo',
		emailFieldsPlaceholder_USER_INVITE_LINK_EXPIRY: 'Expiration du lien d\'invitation',
		emailFieldsPlaceholder_USER_URL: 'Utilisateur URL',
	},

	serverError: {
		GENERAL: 'Erreur générale',

		COMPANY_NOT_FOUND: 'Société non trouvée',
		COMPANY_SUBDOMAIN_ALREADY_EXISTS: 'Le sous-domaine de l\'entreprise existe déjà',
		COMPANY_UNABLE_TO_DELETE: 'L\'entreprise ne peut pas être supprimée: il existe des documents, des sous-entreprises ou une demande a été effectuée à partir du contexte de l\'entreprise',
		COMPANY_USER_GROUP_ALREADY_EXISTS: 'Le groupe existe déjà',
		COMPANY_USER_GROUP_NOT_FOUND: 'Groupe non trouvé',
		COMPANY_USER_NOT_FOUND: 'Utilisateur non trouvé',
		COMPANY_CHILD_EXISTS: 'Une ou plusieurs sous-entreprises existent déjà',
		COMPANY_IS_CHILD_COMPANY: 'La société est une sous-société',

		DOCUMENT_FOLDER_ALREADY_EXISTS: 'Le dossier existe déjà',
		DOCUMENT_FOLDER_NOT_FOUND: 'Dossier non trouvé',
		DOCUMENT_FORM_PROBLEM: 'Un problème avec le formulaire sur le document',
		DOCUMENT_INVALID_CONTENTS: 'Le document a un contenu contradictoire',
		DOCUMENT_INVALID_STATE: 'Le document est dans un état invalide',
		DOCUMENT_MISSING_DESCRIPTION: 'Le document a besoin d\'une description obligatoire',
		DOCUMENT_NOT_FOUND: 'Le document n\'a pas été trouvé. Peut-être parce que le document a été fermé ou supprimé entre-temps.',
		DOCUMENT_NO_ACTORS: 'Il n\'y a pas d\'acteurs sur le document',
		DOCUMENT_PAGE_INDEX_OUT_OF_BOUNDS: 'Index de page non valide',
		DOCUMENT_PASSWORD_PROTECTED: 'Le document est protégé par un mot de passe',
		DOCUMENT_READ_ONLY: 'Le document est en lecture seule',
		DOCUMENT_REQUIRED_FORM_FIELD_MISSING: 'Un champ obligatoire n\'a pas été rempli',
		DOCUMENT_SIGNING_IN_PROGRESS: 'Le document est verrouillé et en cours de signature',
		DOCUMENT_CONVERT_PROBLEM: 'Problème avec la conversion du document',

		DOCUMENT_TEMPLATE_NOT_FOUND: 'Le modèle de document est introuvable',
		DOCUMENT_TEMPLATE_ALREADY_EXISTS: 'Le modèle de document existe déjà',

		EMAIL_ALREADY_IN_USE: 'L\'adresse email est déjà utilisée',
		EMAIL_INVALID: 'L\'adresse e-mail est non valide',
		EMAIL_CHALLENGE_INVALID: 'Ce lien e-mail pour changer votre mot de passe n\'est plus valide.',

		EMAIL_DOMAIN_AUTHENTICATION_ERROR: 'Erreur dans le traitement du domaine de courrier électronique authentifié',

		NAME_INVALID: 'Le nom n\'est pas valide',

		FOLDER_APPROVER_NOT_ALLOWED: 'Cet utilisateur n\'est pas autorisé à être un approbateur dans ce dossier',
		FOLDER_SIGNER_NOT_ALLOWED: 'Cet utilisateur n\'est pas autorisé à être signataire dans ce dossier',

		SIGNER_GROUP_NOT_FOUND: 'Le groupe de signatures est introuvable',

		APPROVAL_CONSTRAINT_VIOLATION: 'Les paramètres d\'approbation sont erronés',
		APPROVAL_REASON_MISSING: 'La raison manque',
		SIGNING_ITSME_PROBLEM: 'Il y a un problème avec itsme',
		SIGNING_ITSME_SIGNING_TIMEOUT: 'Un dépassement de délai s\'est produit dans le flux itsme®',
		SIGNING_ITSME_SESSION_NOT_FOUND: 'La session itsme® est introuvable',
		SIGNING_ITSME_SERVICE_ERROR: 'Il y a eu un problème lors du placement de la signature itsme®',
		SIGNING_ITSME_RESPONSE_ERROR: 'Il y a eu un problème avec les données provenant d\'itsme®',
		SIGNING_ITSME_SIGNING_USER_TIMEOUT: 'Signer le(s) document(s) avec itsme® a pris trop de temps, veuillez réessayer',
		SIGNING_ITSME_SIGNING_USER_REJECTED: 'Veuillez rejeter le(s) document(s) dans Quill, s\'il s\'agissait d\'une erreur, vous pouvez simplement réessayer signer avec itsme®',
		SIGNING_ITSME_EXPIRED: 'La session itsme® a expiré, veuillez réessayer',
		SIGNING_ITSME_BUSY: 'Il y a déjà une session itsme® en cours, elle est automatiquement effacée au bout de 5 minutes...',

		SIGNING_OTP_CHALLENGE_FAILED: 'Code d\'identification invalide',
		SIGNING_OTP_NUMBER_CHECK_FAILED: 'Numéro de SMS invalide',
		SIGNING_SIGN_REQUEST_CLOSED: 'Il n\'est plus possible de signer ce document',
		SIGNATURE_PLACEHOLDER_NOT_FOUND_ERROR: 'Impossible de trouver l\'espace réservé dans le document',
		SIGNING_SIGN_INVALID_REQUEST: 'Demande de signature non valide, l\'emplacement de la signature ou le(s) espace(s) réservé(s) doivent être spécifiés',
		SIGNING_CERTIFICATE_MISSING: 'Vous ne pouvez pas signer avec cette carte car elle n\'a pas de certificat de signature (eID pour les enfants, par exemple).',
		SIGNING_CERTIFICATE_PROBLEM: 'Certificat de signature non valide',
		SIGNING_CERTIFICATE_REVOKED: 'Le certificat de signature a été révoqué',
		SIGNING_CERTIFICATE_RETRIEVAL_PROBLEM: 'Le certificat de signature n\'a pas pu être obtenu',
		SIGNING_GENERAL_PROBLEM: 'Un problème est survenu lors de la signature',
		SIGNER_MISMATCH_ERROR: 'Non concordance des données du signataire',
		SIGNATURE_PLACEHOLDER_PARSING_ERROR: 'Pas réussi à analyser l\'espace(s) réservé(s) à la signature',
		SIGNATURE_PLACEHOLDER_NOT_UNIQUE: 'Il y a des espaces réservés aux signatures en double dans le document.',

		USER_ALREADY_ADDED: 'Cet utilisateur a déjà été ajouté',
		USER_EXISTS_AS_GUEST: 'Cet utilisateur existe déjà en tant qu\'invité',
		USER_NOT_FOUND: 'Utilisateur non trouvé',
		USER_NOT_FOUND_BY_EID: 'Utilisateur non trouvé. Assurez-vous que le numéro de la carte d\'eID actuel correspond au numéro de la carte dans le profil de l\'utilisateur.',
		USER_UPDATE_PROBLEM: 'Problème de mise à jour de l\'utilisateur',
		USER_USED_BY_CONNECTOR_ACTIVITY: 'L\'utilisateur est encore lié à une ou plusieurs activités du connecteur.',
		USER_INVALID_DATA: 'Les données fournies par l\'utilisateur sont invalides ou incomplètes',
		USER_INVALID_DATA_FORMAT: 'Les données de l\'utilisateur fournies sont dans un format incorrect',
		USER_DUPLICATE_META_FIELD: 'Un ou plusieurs attributs méta sont déjà définis pour l\'utilisateur',
		USER_INVALID_META_FIELD: 'Un ou plusieurs attributs méta ne sont pas disponibles pour le signataire',
		USER_INVALID_NOTIFICATION: 'Un ou plusieurs types de notification n\'existent pas',

		PASSWORD_INVALID: 'Mot de passe non valable',
		PASSWORD_MISMATCH: 'Les mots de passe ne correspondent pas',
		PASSWORD_DOES_NOT_MEET_REQUIREMENTS: 'Le mot de passe ne répond pas aux exigences',
		PASSWORD_SHOULD_RESET: 'Réinitialisation du mot de passe requise. Une invitation à réinitialiser le mot de passe a été envoyée dans votre boîte aux lettres.',
		PASSWORD_RESET_EXPIRED: 'Le lien de réinitialisation du mot de passe a expiré',

		LOGIN_FAILED: 'Connexion échoué',
		OIDC_ERROR: 'Connexion échoué',

		INVALID_URL: 'URL invalide',
		NOT_ALLOWED: 'Vous n\'êtes pas autorisé à effectuer cette action',
		OTP_INVALID_NUMBER: 'Numéro de SMS invalide',
		SMS_SEND: 'Il y a un problème lors de l\'envoi du SMS',
		EID_NUMBER_ALREADY_IN_USE: 'Ce numéro de la carte eID belge est déjà utilisé',
		SIGNATURE_TYPE_NOT_ALLOWED: 'Ce type de signature n\'est pas autorisé',
		TIME_STAMP_EXCEPTION: 'Probléme de réglage de l\'horodatage',
		WEBHOOK_PROCESSING: 'Un problème est survenu lors du traitement du webhook',

		IMAGE_INVALID_SIZE: 'La taille des images doit \u00EAtre inférieure à 1 MB.',
		IMAGE_INVALID_MIME_TYPE: 'Le format de ce fichier n\'est pas reconnu. Seuls les formats PNG et JPEG sont supportés par la plateforme.',

		FORWARD_TARGET_NOT_FOUND: 'Impossible de trouver un utilisateur à qui transmettre',
		FORWARD_NOT_POSSIBLE: 'Le transfert n\'est pas possible',
		ITSME_AUTHENTICATION_ERROR: 'Un problème avec l\'authentification itsme®',

		DELEGATE_FILE_ERROR: 'Un problème est survenu lors du traitement du fichier',

		REPORT_UNAVAILABLE: 'Rapport(s) non disponible(s) actuellement. Veuillez réessayer dans quelques instants.',

		WEBHOOK_CONFIG_NOT_FOUND: 'La configuration du webhook n\'a pas été trouvée',
		WEBHOOK_CONFIG_PKCS12_INVALID_PASSWORD: 'Mot de passe non valide détecté lors de l\'ouverture de PKCS12',
		WEBHOOK_CONFIG_PKCS12_UNABLE_TO_PARSE: 'Impossible d\'analyser le fichier PKCS12, assurez-vous qu\'il s\'agit d\'un fichier binaire. Celui-ci peut être créé/exporté par OpenSSL via le module pkcs12 -export'
	},

	upload: {
		selectFile: 'Sélectionner le fichier',
		fileName: 'Nom de fichier',
		dragFileHere: '..ou faites glisser votre fichier ici',
		invalidSize: 'Taille invalide, la taille maximale est {0}',
		unknownError: 'Une erreur inconnue s\'est produite',
	},

};
